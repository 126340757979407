import React, { useMemo } from 'react';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { getAudioLanguages, getSourceName } from '@airtel-tv/utils/GlobalUtil';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';

import {
    CDP_EVENT_CONST, ELEMENT_ID, MODAL_POPUP, PROGRAM_TYPES,
} from '@airtel-tv/constants/GlobalConst';
import { EVENT_SOURCE_NAME } from '@airtel-tv/constants/EventsConst';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { showModalComponentAction } from '../../../../../web/src/modules/modal-popup/ModalPopupActions';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import ContentShareComponent from '../../../content-details/components/ContentShareComponent';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { getSocialShareInfo } from '@airtel-tv/utils/ContentMetaUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './MobileBannerComponent.scss';
import EventType from '@airtel-tv/analytics/EventType';



const MobileBannerBottomComponent = (props) => {
        const {watchListToggleButtonClick, contentDetails, deviceUtil, railCtaText} = props;

    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const {
        CDP_TRAILER = 'Trailer', IMDB = 'IMDb', SEASONS = 'Seasons', AUDIO_AVAILABLE_IN = 'Audio Available in:', CDP_MWEB_TRAILERS = 'Trailers', CDP_MWEB_SHARE = 'Share', CDP_MWEB_MORE = 'more', WATCHLIST = 'Watchlist',
    } = LANGUAGE;

    const {
        shouldAddInWL = true, contentAutoplay = false, matchStartTime = null, programType = '', releaseYear = '',
        appInAppRedirection = false, languages, description, title, shortUrl, shareShortUrl = '', pageId, id=''
    } = contentDetails;

    const trailers = lodashGet(contentDetails, 'trailers', []);
    const trailerRefId = Array.isArray(trailers) && trailers[0]?.refId;

    const isAddedToWatchList = (contentId) => {
        const { watchList } = props;
        return watchList[contentId] && Object.keys(watchList[contentId]).length > 0;
    };

    const shareAnalyticsMeta = {
        action: ANALYTICS_ACTIONS.SHARE_CLICK,
        source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        asset_name: ANALYTICS_ASSETS.SHARE,
    };

    const openPopUp = () => {
        const { showModalComponentActionDispatch } = props;
        showModalComponentActionDispatch({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.MORE_INFO_POPUP,
            overrideCrossButtonValue: true,
            payload: {
                ...props,
            },

        });
    };


   


    const onTrailerClick = () => {
        const { showModalComponentActionDispatch, contentDetails,location,  history } = props;
        const {
            trailers, programType, id, cpId, tvShowName, title,
        } = contentDetails;
        if (trailers.length === 1) {
            const sourceName = getSourceName(location.pathname);
            const meta = {
                action: CDP_EVENT_CONST.TRAILER_VIEW,
                source_name: sourceName,
                content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
                content_id: id,
                cp_name: cpId,
            };

            if (programType === PROGRAM_TYPES.EPISODE) {
                meta.episode_name = title;
            }

            contentDetailsEvent(meta, EventType.TRAILER_VIEW);

            const params = {
                showPlaybackTrailer: true,
                trailerId: trailers[0].refId,
            };
            history.push({
                pathname: `${location.pathname}`,
                search: LocationUtil.objectToQueryParams(params),
            });
        }
        else {
            const sourceName = getSourceName(location.pathname);
            const meta = {
                action: CDP_EVENT_CONST.TRAILER_CLICK,
                source_name: sourceName,
                content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
                content_id: id,
                cp_name: cpId,
            };

            if (programType === PROGRAM_TYPES.EPISODE) {
                meta.episode_name = title;
            }

            contentDetailsEvent(meta, EventType.TRAILER_CLICK);
            showModalComponentActionDispatch({
                showModalValue: true,
                componentNameValue: MODAL_POPUP.TRAILER_POPUP,
                overrideCrossButtonValue: true,
                payload: {
                    contentDetails,
                },

            });
        }
    };


    const socialShareInfo = getSocialShareInfo({
        programType: PROGRAM_TYPES.EVENT,
        showName: title,
        currentUrl: RoutingUtil.getEventsPageUrl({
            eventName: title,
            eventId: pageId,
        }),
        baseUrl: deviceUtil.getBaseUrl(),
        shareShortUrl,
    });

    const showShareButton = useMemo(() => {
        const advanceIconRail = props.layoutPackages.find(item => item?.format?.ty === 'ADVANCE_ICON_RAIL');
        const iconParams = advanceIconRail?.format?.iconParams;

        if (!(iconParams || []).includes('share')) {
            return null;
        }
        return true;
    }, [
        props.layoutPackages,
    ]);

    const addToWatchlistAnalyticsMeta = {
        cp_name: contentDetails?.cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.ADD_TO_WATCHLIST_CLICK,
        asset_name: ANALYTICS_ASSETS.WATCHLIST,
        source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    };

    return <div className="m-banner-bottom-wrapper">
            <div className="action-area">
                {shouldAddInWL && (
                    <div className="options">
                        <AnalyticsButtonComponent
                            className="option-trigger"
                            onClick={(e) => {
                                e.preventDefault();
                                watchListToggleButtonClick(contentDetails, addToWatchlistAnalyticsMeta);
                            }}
                            id={ELEMENT_ID.MOBILE_ADD_TO_WATCHLIST}
                            aria-label="Watchlist Button"
                        >
                            { isAddedToWatchList(contentDetails.id) ? (
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.125 9.75L10.625 15L7.875 12.375"
                                        stroke="#E8EAED"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                        stroke="#E8EAED"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                        stroke="#E8EAED"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8.25 12H15.75"
                                        stroke="#E8EAED"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12 8.25V15.75"
                                        stroke="#E8EAED"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            ) }

                        </AnalyticsButtonComponent>
                        { WATCHLIST }
                    </div>
                )}
                { trailers.length > 0 && (
                    <div className="options">
                        <AnalyticsButtonComponent
                            className="option-trigger"
                            onClick={onTrailerClick}
                            id={ELEMENT_ID.MOBILE_WATCH_TRAILER_BUTTON}
                            aria-label="Trailers"
                        >
                            <ThumborImage
                                src="/static/cdp-assets/FilmStrip.svg"
                            />

                        </AnalyticsButtonComponent>
                        { CDP_MWEB_TRAILERS }
                    </div>
                ) }
                {showShareButton && (
                    <div className="options">
                        <AnalyticsButtonComponent
                            className="option-trigger"
                                id={ELEMENT_ID.MOBILE_CONTENT_SHARE_BUTTON}
                                aria-label="Share button"
                                meta={shareAnalyticsMeta}
                            >
                                <ContentShareComponent
                                    title={title}
                                    shortUrl={shortUrl}
                                    shareShortUrl={shareShortUrl}
                                    socialShareInfo={socialShareInfo}
                                    hideWhatsapp
                                    isMobile
                                    fromContentDetail
                                    railCtaText={railCtaText}
                                />
                            </AnalyticsButtonComponent>
                            { CDP_MWEB_SHARE }
                    </div>
                    )}
            </div>
        </div>
}

function mapStateToProps(state, props) {
    return {}
}

export default withDeviceUtil(connect(mapStateToProps, {
    showModalComponentActionDispatch: showModalComponentAction,
})(withRouter(withStyles(styles)(MobileBannerBottomComponent))));