import lodashGet from 'lodash/get';
import React, { Fragment } from 'react';
import { TILES_LIMIT } from '@airtel-tv/constants';
import { isObjEmpty } from '@airtel-tv/utils/GlobalUtil';
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder';
// import { buildRail } from '@airtel-tv/layout/builders/RailBuilder';
// import getRail from '../../rail/factories/RailFactory';

export default class ContentLayoutUtil {
    static getRailElement({
        paginatedData,
        themeConfig,
        channels,
        contentForLayoutPackages,
        subscriptionDataById,
        cpDetailsById,
        numberOfRails,
        pageId,
        contentId,
        getRail,
        ...rest
    }) {
        if (isObjEmpty(paginatedData)) {
            return null;
        }

        const rails = paginatedData.map((layout, railIndex) => {
            const layoutContents = lodashGet(layout, [
                'contents',
            ], []);

            const layoutContent = layoutContents.length > 0 ? layoutContents[0] : {};
            const { packageId } = layoutContent;
            const packageDetails = packageId && contentForLayoutPackages ? contentForLayoutPackages[packageId] : lodashGet(layoutContents[0], [
                'details',
            ], []);
            let slicedContent = [];
            if (packageDetails && Object.keys(packageDetails).length > 0) {
                slicedContent = lodashGet(packageDetails, 'content', []).slice(0, TILES_LIMIT);
            }
            else {
                slicedContent = packageDetails;
            }
            const totalContentCount = (packageDetails && packageDetails.totalContentCount) || (slicedContent && slicedContent.length) || 0;


            const railProps = buildRail({
                layoutDetails: layout,
                items: slicedContent,
                railIndex,
                totalContentCount,
                channels,
                themeConfig,
                numberOfRails,
                cpDetailsById,
                subscriptionDataById,
                pageId,
                contentId,
                ...rest,
            });

            const railElement = getRail({
                railProps,
            });

            return <Fragment key={layout.id}>{railElement}</Fragment>;
        });

        const filteredRails = rails.filter(arr => arr !== null);
        return filteredRails.length < 1 ? null : filteredRails;
    }
}
