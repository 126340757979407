/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    LOCAL_STORE_KEYS, PROGRAM_TYPES,
} from '@airtel-tv/constants/GlobalConst';
import { ResizeHOC } from '@airtel-tv/lib/hoc/ResizeHOC';
import { withWatchlistDataHOC } from '@airtel-tv/lib/hoc/withWatchlistDataHOC';

import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';

import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import EventType from '@airtel-tv/analytics/EventType';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { EVENT_SOURCE_NAME } from '@airtel-tv/constants/EventsConst';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { PLAYER_STATE } from '@airtel-tv/constants';
import TrailerContainer from '../../container/TrailerContainer';
import BasicControlBarComponent from '../../../../../web/src/modules/playbackComponents/components/control-bars/BasicControlBarComponent';
import createDeepLink from '../../../content-details/utils/DeepLinkUtil';
import styles from './MobileBannerComponent.scss';
import { contentDetailsFetchAction } from '../../../content-details/actions/ContentDetailsAction';
import { showPlayback } from '../../../../../web/src/modules/notify/NotifyActions';
import { triggerLogin } from '../../../../../web/src/modules/auth/AuthAction';
import { showModalComponentAction } from '../../../../../web/src/modules/modal-popup/ModalPopupActions';
import PlayerContainer from '../../container/PlayerContainer';

function MobileBannerComponent(props) {
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const {
        CDP_TRAILER = 'Trailer', IMDB = 'IMDb', SEASONS = 'Seasons', AUDIO_AVAILABLE_IN = 'Audio Available in:', CDP_MWEB_TRAILERS = 'Trailers', CDP_MWEB_SHARE = 'Share', CDP_MWEB_MORE = 'more', WATCHLIST = 'Watchlist',
    } = LANGUAGE;
    const {
        imageSettings, title, deviceUtil, pageId, contentDetails, railSecondaryText, contentDetails: {
            images, genres = [], ageRating = '', shortUrl = '', languages, description = '', shareShortUrl = '', playableId = '', id = '',
        }, history, watchListToggleButtonClick, continueWatchingData, contentCollection: { seasons }, playbackDetails,
        railCtaClicked, showCtaText = true, showTimer, pageRefresh,
        airTime, matchStartTimeOffset, matchStartTimer, showTeaserTag, contentType, showLiveTag,
        upcomingDate,
        railCtaText: railCtaTextFromProps,
        railCtaType,
        location,
        primaryLanguage = '',
        matchRoute,
        isSubscribed,
        loginTriggered,
        modalConfig,
        playContent,
        isPlayable,
        updatePlayTrailerState,
    } = props;
    const [
        railCtaText,
        setCtaText,
    ] = useState(railCtaTextFromProps);

    const [
        playTrailer,
        setPlayTrailer,
    ] = useState(true);
    const [
        prevPlayState,
        setPrevPlayState,
    ] = useState('');

    const isMobileViewport = DeviceUtil.getDeviceUtil()?.isMobileViewport();

    const trailers = lodashGet(contentDetails, 'trailers', []);
    const trailerRefId = Array.isArray(trailers) && trailers[0]?.refId;

    const playTrailerFn = () => {
        const ele = document.getElementsByClassName('mweb-trailer')[0];
        if (ele) {
            ele.classList.remove('transparent');
            ele.classList.add('opaque');
            updatePlayTrailerState(true);
        }
    };

    useEffect(() => {
        if ((modalConfig.showModal || loginTriggered) && playTrailer && !prevPlayState) {
            setPlayTrailer(false);
            setPrevPlayState(PLAYER_STATE.LOGIN_PAUSE);
        }
        else if (!modalConfig.showModal && !loginTriggered && !playTrailer && prevPlayState === PLAYER_STATE.LOGIN_PAUSE) {
            setPlayTrailer(true);
            setPrevPlayState('');
        }
    }, [
        playTrailer,
        loginTriggered,
        modalConfig,
    ]);

    useEffect(() => {
        let {
            railCtaText, continueWatchingData, contentDetails, match: { path },
        } = props;
        const contentId = (
            contentDetails.programType === PROGRAM_TYPES.TVSHOW
            || contentDetails.programType === PROGRAM_TYPES.MOVIE
            || contentDetails.programType === PROGRAM_TYPES.VIDEO
                ? contentDetails.id
                : contentDetails.seriesId
        );
        if (isSubscribed && continueWatchingData && continueWatchingData[contentId] && Object.keys(continueWatchingData[contentId]).length > 0) {
            const { contentResponse: { episodeSeasonNum, episodeNum, playableId } } = continueWatchingData[contentId];
            const isEpisodeRoute = matchRoute(ROUTE_PATHS.TV_EPISODE_PLAYBACK, location?.pathname) !== null;
            const isPlaybackRoute = matchRoute(ROUTE_PATHS.PLAYBACK, location?.pathname) != null;
            if (isEpisodeRoute || isPlaybackRoute) {
                railCtaText = episodeSeasonNum && episodeNum ? `Resume: S${episodeSeasonNum} E${episodeNum}` : 'Resume';
            }
        }
        setCtaText(railCtaText);
    }, [
        isSubscribed,
        continueWatchingData,
        railCtaTextFromProps,
    ]);
    const onScroll = () => {
        if(trailerRefId){
            return;
        }
        const containerEle = document.getElementsByClassName('scroll-top-name-wrapper')[0];
        const bannerTitleTag = document.getElementById('header-web');
        const backButtonElm = document.querySelectorAll('.m-banner-back.non-header')[0];
        if (window.scrollY > bannerTitleTag?.offsetHeight || (deviceUtil.isScopedWebview() && !bannerTitleTag && window.scrollY > 5)) {
            containerEle?.classList?.add('scroller-wrapper');
            // if (deviceUtil.isScopedWebview()) {
                backButtonElm?.classList?.add('hide-header');
            // }
        }
        else {
            containerEle?.classList?.remove('scroller-wrapper');
            // if (deviceUtil.isScopedWebview()) {
                backButtonElm?.classList?.remove('hide-header');
            // }
        }
    };
    useEffect(() => {
        if (checkWindowExist()) {
            window && window.addEventListener('scroll', onScroll);
        }
        const {
            sharableContent: { INSTALLED_APP_BASE_LINK = 'wynkpremiere://' }, contentDetails, location, isAuthenticated,
        } = props;
        if (checkWindowExist() && deviceUtil.getBrowserName() !== 'safari') {
            const isIOS = deviceUtil.isIOS();
            const deeplink = createDeepLink(INSTALLED_APP_BASE_LINK, contentDetails, isIOS);
            const meta = {
                action: EventType.PAGE_LOAD.val,
                asset_name: ANALYTICS_ASSETS.OPEN_APP_DEEP_LINK,
                action: deeplink,
                source_name: location.pathname,
                contentid: contentDetails.id,
            };
            contentDetailsEvent(meta, EventType.PAGE_LOAD);
            if (!sessionStore.get(LOCAL_STORE_KEYS.HAS_USER_NAVIGATED_TO_APP) && !deviceUtil.isScopedWebview()) {
                window.location = deeplink;
                sessionStore.set(LOCAL_STORE_KEYS.HAS_USER_NAVIGATED_TO_APP, true);
            }
        }
        if (contentDetails?.contentAutoplay) {
            setTimeout(() => playContent(), 0);
        }
        return () => {
            if (checkWindowExist()) {
                window.removeEventListener('scroll', onScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (contentDetails?.contentAutoplay) {
            setTimeout(() => playContent(), 1000);
        }
    }, [
        railCtaText,
    ]);


    // const playContent = () => {
    //     const cdpBanner = getElementById('mobile-top-name-wrapper');
    //     if (cdpBanner) {
    //         const playerContainer = document.getElementsByClassName('mobile-player-container')[0];
    //         if (playerContainer) {
    //             playerContainer?.classList?.add('opaque');
    //         }
    //     }
    // };


    const getImage = images => images.SQUARE || images.SQUARE_HD || images.PORTRAIT || images.PORTRAIT_HD || images.LANDSCAPE_169 || '';


    const showCtaButton = () => {
        const { contentAutoplay = false, programType } = contentDetails;
        const { isSubscribed } = props;
        if ((!contentAutoplay || (contentAutoplay && !isSubscribed))) {
            return true;
        }
        return false;
    };

    const {
        shouldAddInWL = true, contentAutoplay = false, matchStartTime = null, programType = '', releaseYear = '',
        appInAppRedirection = false,
    } = contentDetails;
    return (
        <div className={`mobile-banner-wrapper ${(isPlayable && trailerRefId) || contentAutoplay ? 'mweb-trailer-autoplay' : ''}`}>
            <div className="m-banner-top-wrapper">
                {/* <div className="top-gredient">
                    <AnalyticsButtonComponent
                        className="m-banner-back"
                        onClick={() => history.go(-1)}
                        aria-label="Go to previous Page"
                    >
                        <svg
                            width="9"
                            height="16"
                            viewBox="0 0 9 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.75 14.875L0.875 8L7.75 1.125"
                                stroke="#E8EAED"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </AnalyticsButtonComponent>
                </div> */}
                {/* {TODO: UNCOMMENT IN TRAILER STORY} */}
                { playableId && (contentAutoplay && !appInAppRedirection) && railCtaText
                    && (
                        <div className={`mobile-player-container transparent ${contentAutoplay && !appInAppRedirection ? ' mweb-autoplay' : ''}`}>
                            { programType === PROGRAM_TYPES.TRAILER && <div className="mweb-trailer-tag">{ CDP_TRAILER }</div> }
                            <PlayerContainer
                                contentId={id}
                                contentDetails={contentDetails}
                                playableId={playableId}
                                trailerRefId={trailerRefId}
                            />
                        </div>
                    )}
                {/* {showTeaserTag && <div className="mweb-trailer-tag">{ contentType }</div> } */}
                {trailerRefId && !deviceUtil.isIOS() && railCtaText && (
                    <div className="mweb-trailer transparent fit-video-container">
                        <div className="mweb-trailer-tag">{ CDP_TRAILER }</div>
                        <div className="current-vdo__player">
                            <div className="vdo-player">

                                <TrailerContainer
                                    controlBar={BasicControlBarComponent}
                                    playbackData={playbackDetails}
                                    contentDetails={contentDetails}
                                    playableId={trailerRefId}
                                    canPlayTrailer={playTrailerFn}
                                    controlBarProps={{
                                        isTrailer: true,
                                        prevPlayState,
                                    }}
                                    playTrailer={playTrailer}
                                    trailerRefId={trailerRefId}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {/* <p
                        className="plan-info-detail"
                        id={ELEMENT_ID.MOBILE_PLAN_DESCRIPTION}
                        dangerouslySetInnerHTML={{ __html: railSecondaryText }}
                    /> */}
            </div>
        </div>
    );
}

MobileBannerComponent.propTypes = {
    showModalComponentActionDispatch: PropTypes.func.isRequired,
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isAuthenticated: PropTypes.bool.isRequired,
    triggerLoginDispatch: PropTypes.func.isRequired,
    railCtaType: PropTypes.string.isRequired,
    railCtaText: PropTypes.string.isRequired,
    showPlaybackDispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    deviceUtil: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    subscriptionDataById: PropTypes.object.isRequired,
    cpDetailsById: PropTypes.object.isRequired,
    preferredPartnerPlans: PropTypes.object.isRequired,
    primaryLanguage: PropTypes.string,
    isSubscribed: PropTypes.bool.isRequired,
    matchRoute: PropTypes.func.isRequired,
    loginTriggered: PropTypes.bool,
    modalConfig: PropTypes.object,
};

MobileBannerComponent.defaultProps = {
    primaryLanguage: '',
    loginTriggered: false,
    modalConfig: {},
};

const mapStateToProps = (state, props) => {
    const {
        appConfig, userConfig, layoutDetails, themeConfig: { configs }, packageContents,
        authConfig: { isAuthenticated, loginTriggered },
        syncContent: {
            watchList,
            continueWatchingData,
        },
        modalConfig,
    } = state;

    const { cpDetailsById, sharableContent } = appConfig;
    const { subscriptionDataById } = userConfig;
    // const pageId = lodashGet(props, 'match.params.pageId', '');
    const { pageId } = props;
    const layoutPackages = lodashGet(layoutDetails[pageId], 'layoutPackages', []);
    const canFetchMore = lodashGet(layoutDetails, `[${pageId}].fetchMore`, false);
    const totalRailsFetched = lodashGet(layoutDetails, `[${pageId}].totalFetched`, 0);
    const layoutError = lodashGet(layoutDetails[pageId], 'error', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', null);
    const { matchStartTimeOffset = 86400000 } = appConfig;

    return {
        languageMap: appConfig.language_map,
        userInfo: userConfig.userInfo,
        pageId,
        layoutPackages,
        layoutError,
        cpDetailsById,
        themeConfig: configs,
        packageContents,
        subscriptionDataById,
        isAuthenticated,
        canFetchMore,
        totalRailsFetched,
        watchList,
        continueWatchingData,
        preferredPartnerPlans,
        sharableContent,
        matchStartTimeOffset,
        loginTriggered,
        modalConfig,
    };
};

export default withDeviceUtil(connect(
    mapStateToProps,
    {
        showModalComponentActionDispatch: showModalComponentAction,
        triggerLoginDispatch: triggerLogin,
        showPlaybackDispatch: showPlayback,
        contentDetailsFetchDispatch: contentDetailsFetchAction,
    },
)(withWatchlistDataHOC(ResizeHOC(withRouter(withStyles(styles)(MobileBannerComponent))))));
