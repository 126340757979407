import {RoutingUtil} from '@airtel-tv/utils/RoutingUtil';

const CatchupShowNextEpBuilder = ({ contentDetails, episodesDetails, channel }) => {
    const tvShowDetails = { ...contentDetails };
    let nextEpisode = null;
    if (episodesDetails) {
        const curEpIndex = tvShowDetails.episodeRefs.findIndex(ep => ep.refId === episodesDetails.id);
        if (curEpIndex && curEpIndex !== -1) {
            nextEpisode = tvShowDetails.episodeRefs[curEpIndex - 1];
        }
    }
    let nextEpisodeUrl;
    let nextEpisodeDetails = null;
    let nextEpTitle;
    let nextEpLinkTitle;
    if (nextEpisode && channel) {
        const tvShowTitle = tvShowDetails.title;

        nextEpTitle = tvShowTitle;
        nextEpLinkTitle = tvShowTitle;

        nextEpisodeUrl = RoutingUtil.getLiveEpisodeShowUrl({
            channelName: channel.title,
            showName: tvShowTitle,
            episodeReleaseDT: nextEpisode.airDate,
            contentId: nextEpisode.refId,
            tvShowId: tvShowDetails.id,
        });
        nextEpisodeDetails = {
            details: {
                ...nextEpisode,
            },
            url: nextEpisodeUrl,
            nextEpLinkTitle,
            nextEpTitle,
        };
    }


    return nextEpisodeDetails;
};

export default CatchupShowNextEpBuilder;
