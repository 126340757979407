import React from 'react';
import lodashGet from 'lodash/get';
import { LanguageProviderUtil} from '@airtel-tv/utils/LanguageProviderUtil';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { DateTime } from '@airtel-tv/utils/DateTime';
import { DIMENSION_PAGE_MAP, DIMENSIONS_SUPPORTED_MAP } from '@airtel-tv/constants/GlobalConst';
import { LinkComponent } from '@airtel-tv/ui-lib/atoms/link/LinkComponent';
import { getGenreTupple } from '../../../../web/src/modules/dimension-pages/helper/DimensionPageHelper';
import { firstLetterUpper } from '../../../../web/src/utilities/CommonUtil';
import { AppEnv } from '@airtel-tv/utils/GetEnv';
// import env from '../../../config/index';
// import { getStore } from '../../../redux/Store';
import { StoreUtil } from '@airtel-tv/utils/StoreUtil';
// import { getStore } from '../../../../web/src/redux/Store';
// import LanguageCodesProvider from '../../../../web/src/providers/LanguageCodesProvider';

const env = AppEnv.getEnv();
const getListOfLinks = ({ list }) => {
    if (!list || list.length < 1) {
        return null;
    }

    return list.map(listItem => (
        <LinkComponent
            to={lodashGet(listItem, 'link', '')}
            key={lodashGet(listItem, 'genre', '')}
            title={lodashGet(listItem, 'title', '')}
            className="link-theme-secondary"
        >
            {` ${firstLetterUpper(lodashGet(listItem, 'genre', ''))}`}
        </LinkComponent>
    ));
};
export const getInfo = (contentDetails, avoidDimensionPageLink = false) => {
    const {
        releaseYear,
        year,
        airDate,
        channelId,
        genres,
        programType,
        languages,
        ageRating,
    } = contentDetails;

    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const desContent = [];

    const store = StoreUtil.getStore();
    const ageRatingVisibilityBuildVersion = lodashGet(store.getState(), 'appConfig.componentVisibility.ageRatingVisibility', 0);

    if (ageRating && ageRatingVisibilityBuildVersion && Number(env.buildNumber) > ageRatingVisibilityBuildVersion) {
        const contentAgeRating = (
            <div className="age-rating-tag-holder">
                {LANGUAGE.CONTENT_RATED}
                {' '}
                <p className="age-rating-tag">{ageRating}</p>

            </div>
        );
        desContent.push(contentAgeRating);
    }

    if (channelId && airDate) {
        const formattedDate = new DateTime(airDate).format('DD MMMM YYYY');
        desContent.push(formattedDate);
    }
    if (releaseYear) {
        desContent.push(releaseYear);
    }
    if (year) {
        desContent.push(year);
    }
    if (languages && languages.length > 0) {
        const LANGUAGES_CODES = LanguageProviderUtil.getLanguageCodes();
        const languageLink = RoutingUtil.getDimensionPageUrlV2({
            lang: LANGUAGES_CODES[languages[0]],
            pageTitle: DIMENSION_PAGE_MAP[programType],
            queryParams: `?pageTitle=${DIMENSIONS_SUPPORTED_MAP[programType]}&lang=${languages[0]}`,
        });

        const lang = (!avoidDimensionPageLink
            ? (
                <LinkComponent
                    to={languageLink}
                    key={languages[0]}
                    className="link-theme-secondary"
                >
                    {LANGUAGES_CODES[languages[0]]}
                </LinkComponent>
            )
            : LANGUAGES_CODES[languages[0]]
        );
        desContent.push(lang);
    }
    if (genres) {
        const genreTupple = getGenreTupple({
            genres,
            programType,
            lang: languages && languages.length > 0 ? languages[0] : null,
        });

        // eslint-disable-next-line no-nested-ternary
        const genreList = genreTupple && (Array.isArray(genreTupple))
            ? (!avoidDimensionPageLink ? getListOfLinks({
                list: genreTupple,
                separator: ' • ',
            })
                // eslint-disable-next-line no-mixed-operators
                : genreTupple.map(genre => genre && genre.genre || '')
            ) : genreTupple;
        desContent.push(...genreList);
    }

    return desContent.length > 0 ? desContent.reduce((prev, curr) => [
        prev,
        <div className="separator-dots"> • </div>,
        curr,
    ]) : [];
};

export default {
    getInfo,
};
