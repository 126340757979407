import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PlaybackContainer from '@airtel-feature/playback/PlaybackContainer';
import DescriptionComponent from '../components/DescriptionComponent';
import {
    PROGRAM_TYPES,
    INTERSECTION_OBSERVER_THRESHOLD,
    RESIZE_OPTION, RAIL_TYPES,
    LOCAL_TILE_TYPES, CONTENT_PROVIDERS,
    STREAM_TYPES,
    PLAYER_EVENTS_LIST,
} from '@airtel-tv/constants';
import ChannelRelatedContentContainer from '../../../../web/src/modules/rail/containers/ChannelRelatedContentContainer';
import { RoutingUtil, LanguageProviderUtil, getPosterUrlFromContentDetails, ContentImageProvider, BreadCrumbsUtil, withDeviceUtil } from '@airtel-tv/utils';
import { IntersectionObservableHOC } from '@airtel-tv/lib/hoc/IntersectionObservableHOC';
import { SmallLoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/SmallLoaderComponent';
import {
    getCurrentProgram,
    getTimeStamp,
} from '@airtel-tv/utils/GlobalUtil';
import {  
    getSocialShareInfo,
    getPageSeoMeta 
} from '@airtel-tv/utils/ContentMetaUtil'
import { changeMetaData } from '../../../../web/src/modules/notify/NotifyActions';
import { contentDetailsFetchAction } from '../actions/ContentDetailsAction';
import { getRail } from '../../../../web/src/modules/rail/factories/RailFactory'
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
import MarkupFactory from '../../../../web/src/modules/watch-actions/factories/MarkupFactory';
import { updatePageBreadCrumbs } from '@airtel-tv/ui-lib/molecules/breadcrumbs/actions/BreadCrumbsActions';
import ChannelScheduleContainer from '../../../../web/src/modules/rail/containers/ChannelScheduleContainer';
import { getInfo } from '../builders/InfoBuilder';
import { fetchAllChannels } from '@airtel-feature/layout/actions/ChannelAction';
import { fetchEpgChannelDetails } from '@airtel-feature/layout/actions/EpgAction';

class LiveDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            contentId: '',
            contentDetails: null,
            episoderefs: null,
            currentProgramDetails: {},
            channel: [],
            liveTime: null,
            fetchingEpg: false,
            isRelatedTvShowElementVisible: this.deviceUtil.isBot(),
        };
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            contentDetails,
            contentId,
            channel,
            currentChannelEpg,
            fetchingEpg,
            allContentDetails,
        } = nextProps;
        let changes = {};

        // check if new content
        if (contentId !== prevState.contentId) {
            changes = {
                ...prevState,
                ...changes,
                contentId,
            };
        }
        if (contentDetails !== prevState.contentDetails) {
            changes = {
                ...prevState,
                ...changes,
                contentDetails,
            };
        }

        if (currentChannelEpg !== prevState.currentChannelEpg) {
            changes = {
                ...prevState,
                ...changes,
                currentChannelEpg,
            };
        }

        if (channel !== prevState.channel) {
            changes = {
                ...prevState,
                ...changes,
                channel,
            };
        }

        if (fetchingEpg !== prevState.fetchingEpg) {
            changes = {
                ...prevState,
                ...changes,
                fetchingEpg,
            };
        }

        if (allContentDetails !== prevState.allContentDetails) {
            changes = {
                ...prevState,
                ...changes,
                allContentDetails,
            };
        }

        // if (content !== prevState.content) {
        //     const episoderefs = content[contentDetails.episodeTvShowId];
        //     changes = { ...prevState, ...changes, episoderefs };
        // }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    componentDidMount() {
        this.initEpgData();
        this.updateSeoMeta();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState !== this.state;
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentProgramDetails, channel } = this.state;
        const { allContentDetails, contentDetails } = this.props;
        this.initEpgData(prevState);
        if (
            !lodashIsEqual(prevState.currentProgramDetails, currentProgramDetails)
        ) {
            const { contentId } = currentProgramDetails;
            if (contentId && !allContentDetails[contentId]) {
                this.fetchCatchUp(currentProgramDetails.contentId);
            }
        }

        if (channel !== prevState.channel) {
            this.updateSeoMeta();
        }
        if (contentDetails.title !== prevState.contentDetails.title) {
            this.updateSeoMeta();
        }
    }

    fetchCatchUp = (currentProgramContentId) => {
        const { contentDetailsFetchDispatch } = this.props;
        contentDetailsFetchDispatch(currentProgramContentId);
    }

    initEpgData = (prevState = {}) => {
        const { fetchingEpg, currentProgramDetails } = this.state;
        const { channelNotFoundId, contentId } = this.props;

        const fallbackContentDetails = this.epgFallback();

        if (!fetchingEpg) {
            if (currentProgramDetails) {
                if (channelNotFoundId === contentId && fallbackContentDetails.id !== lodashGet(currentProgramDetails, 'id')) { // to avaoid infinite loop
                    this.setState({
                        currentProgramDetails: fallbackContentDetails,
                    });

                    return;
                }
            }
            const newContent = this.getCurrentProgramDetails();
            if (!newContent) {
                if (channelNotFoundId !== contentId) {
                    this.getEpgData();
                }
            }
            else if (!lodashIsEqual(newContent, prevState.currentProgramDetails)) {
                /* eslint-disable */
                this.setState({ currentProgramDetails: newContent || fallbackContentDetails }); //
                /* eslint-enable */
            }
        }
    }

    getEpgData = () => {
        const { fetchEpg, fetchingEpg, contentId } = this.props;
        if (!fetchingEpg) {
            const { liveTime } = this.state;

            const programTime = {
                dtStamp: ((liveTime && new Date(liveTime)) || undefined),
                channelId: contentId,
            };
            fetchEpg(programTime);
        }
    }

    epgFallback = () => {
        const { contentDetails } = this.props;

        let newContentDetail = {};

        newContentDetail = { ...contentDetails };

        const logoUrl = ContentImageProvider({
            imageMap: (contentDetails && contentDetails.images),
        });

        newContentDetail.languages = contentDetails.languages;
        newContentDetail.genres = contentDetails.genres;
        newContentDetail.logoUrl = logoUrl;
        if (contentDetails.channelId) {
            newContentDetail.id = contentDetails.channelId;
        }

        return newContentDetail;
    }

    getCurrentProgramDetails = (dtstamp) => {
        const {
            contentDetails, currentChannelEpg, channel, fetchAllChannelsDispatch,
        } = this.props;
        const { liveTime } = this.state;

        const programTime = dtstamp || liveTime || getTimeStamp();
        // if program guide has data and if it is live tv channel
        if (contentDetails.programType === PROGRAM_TYPES.LIVETVCHANNEL) {
            if (currentChannelEpg.length > 0 && contentDetails) {
                // get program data by id
                // program exists for the id
                // get current programs by time stamp

                const currentHourPrograms = getCurrentProgram(currentChannelEpg, programTime);

                const logoUrl = ContentImageProvider({
                    imageMap: (contentDetails && contentDetails.images),
                    imgType: CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                });

                const commonContentDetail = {
                    languages: contentDetails.languages,
                    genres: contentDetails.genres,
                    logoUrl,
                };
                // return currentHourPrograms && !currentHourPrograms.mocked ? { ...currentHourPrograms, id: currentHourPrograms.channelId, ...commonContentDetail } : { ...contentDetails, ...commonContentDetail };

                if (currentHourPrograms && !currentHourPrograms.mocked) {
                    return {
                        ...currentHourPrograms,
                        id: currentHourPrograms.channelId,
                        ...commonContentDetail,
                    };
                }
                if (currentHourPrograms && currentHourPrograms.mocked) {
                    return {
                        ...contentDetails,
                        ...commonContentDetail,
                    };
                }
                return null;
            }
        }
        // its live tv show
        else {
            const newContentDetail = { ...contentDetails };
            if (contentDetails.cpId === CONTENT_PROVIDERS.MWTV && channel.length > 2) {
                // normal vod flow
                const currentChannel = channel.find(item => item.id === contentDetails.channelId);

                const logoUrl = ContentImageProvider({
                    imageMap: currentChannel && currentChannel.imgs,
                    imgType: [
                        CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                        CONTENT_IMAGE_TYPES.LOGO,
                    ],
                });
                if (currentChannel) {
                    newContentDetail.logoUrl = logoUrl;
                    newContentDetail.duration = contentDetails.durSec / 1000;
                }
                else {
                    fetchAllChannelsDispatch();
                }
            }

            return newContentDetail;
        }

        return null;
    }

    playbackEventsInitialized = (playerEvents, playbackInfo) => {
        const { streamType } = playbackInfo;

        // this check is need as same container is used for live tv shows which are not live

        if (streamType === STREAM_TYPES.LIVE) {
            const updateProgramInfo = throttle((playbackLiveDateTime) => {
                if (!playbackLiveDateTime) {
                    return;
                }

                const { currentProgramDetails } = this.state;
                if(!playbackLiveDateTime.getTime)
                    return;
                // check if live time is under the current program (EPG) if not then update live time
                const liveTime = playbackLiveDateTime.getTime();
                const currentPrograme = this.getCurrentProgramDetails(liveTime);
                if (!currentPrograme || !lodashIsEqual(currentProgramDetails, currentPrograme)) {
                    this.setState({
                        liveTime,
                    });
                }
            }, 2000, {
                leading: true,
                trailing: false,
            });

            playerEvents.emitter.on(PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE, updateProgramInfo);
        }
    }

    updateSeoMeta = () => {
        const {
            contentDetails,
            changeMetaDataDispatch,
            channel,
            updatePageBreadCrumbsDispatch,
            pages,
        } = this.props;

        const {
            description, programType, languages, id,
        } = contentDetails;

        const currentChannel = channel.find(item => item.id === id);
        const currentChannelTitle = lodashGet(currentChannel, 'title', '');
        const metaLanguage = languages && languages[0] ? languages[0] : '';

        const headMetaData = getPageSeoMeta({
            programType,
            contentTitle: currentChannelTitle,
            contentDescription: description,
            contentLanguage: metaLanguage,
        });
        const curPageLink = RoutingUtil.getContentPlaybackUrlWeb({
            category: programType,
            contentTitle: currentChannelTitle,
            contentId: id,
        });
        const breadCrumbsList = BreadCrumbsUtil.liveChannelPageBreadCrumbs({
            contentDetails,
            curPageLink,
            pages,
        });
        const seoMarkupsChannel = MarkupFactory(
            {
                contentDetails: {
                    ...contentDetails,
                    description: headMetaData.description || '',
                    title: currentChannelTitle,
                },
                baseUrl: this.deviceUtil.getBaseUrl(),
                breadCrumbsList,
            },
        );
        changeMetaDataDispatch({
            headMetaData: {
                ...headMetaData,
                image: getPosterUrlFromContentDetails(contentDetails),
                link: curPageLink,
                watchActionCatalogues: seoMarkupsChannel,
            },
        });
        updatePageBreadCrumbsDispatch({ breadCrumbsList });
    }

    showRelatedTvShowElement = () => {
        setTimeout(() => {
            this.setState({
                isRelatedTvShowElementVisible: true,
            });
        }, 1000);
    }

    render() {
        const {
            currentProgramDetails,
            contentDetails,
            channel,
            isRelatedTvShowElementVisible,
        } = this.state;
        const { currentUrl, currentProgramDetailsFromServer, allPlans } = this.props;
        const currentChannel = channel.find(item => item.id === contentDetails.id);
        const channelName = currentChannel && currentChannel.title;
        const info = getInfo(currentProgramDetails, false);

        const scheduleChannelsElement = (
            <ChannelScheduleContainer
                channelId={contentDetails.id}
                currentProgramDetails={currentProgramDetails}
                railIndex={1}
            />
        );
        const relatedChannelsElement = (
            <ChannelRelatedContentContainer
                targetId={contentDetails.id}
                programType={PROGRAM_TYPES.LIVETVCHANNEL}
                railType={RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE}
                displayTitle={this.LANGUAGE.SIMILAR_CHANNELS}
                railIndex={3}
            />
        );

        const loaderElem = <SmallLoaderComponent style={{ marginTop: '20px' }} />;

        const relatedTvShowElement = (
            isRelatedTvShowElementVisible ? (
                <ChannelRelatedContentContainer
                    targetId={contentDetails.id}
                    programType={PROGRAM_TYPES.LIVETVSHOW}
                    railType={RAIL_TYPES.TVSHOW_LOGO_43_RAIL}
                    displayTitle={this.LANGUAGE.RECORDED_SHOWS_LABEL}
                />
            ) : (
                <IntersectionObservableHOC
                    fetchMore={this.showRelatedTvShowElement}
                    hasMore={!isRelatedTvShowElementVisible}
                    loaderElem={loaderElem}
                    startPageNumber={1}
                    threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
                />
            )
        );

        const socialShareInfo = getSocialShareInfo({
            programType: contentDetails.programType,
            channelName: contentDetails.title,
            currentUrl,
            baseUrl: this.deviceUtil.getBaseUrl(),
        });
        let catchUpRailElement = null;
        if (currentProgramDetails && currentProgramDetails.id && currentProgramDetails.logoUrl) {
            const { contentId } = currentProgramDetails;
            const { allContentDetails } = this.state;
            const tvShowDetails = allContentDetails[contentId];
            const channelId = lodashGet(contentDetails, 'id', '');
            if (contentId && tvShowDetails) {
                const { episodeRefs, id: tvShowId } = tvShowDetails;
                if (episodeRefs && episodeRefs.length > 0) {
                    const tileItems = episodeRefs.map(item => ({
                        ...item,
                        id: item.refId,
                        channelId: contentDetails.id,
                        programType: PROGRAM_TYPES.CATCHUP_EPISODE,
                        tvShowId,
                    }));

                    const railProps = buildRail({
                        items: tileItems,
                        explicitTileType: LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE,
                        explicitRailTitle: 'Previous Episodes',
                        more: false,
                        channels: channel,
                        channelId,
                    });
                    catchUpRailElement = getRail({
                        railType: railProps.railType,
                        railProps,
                    });
                }
            }

            const videoMeta = {
                videoTitle: channelName,
                subTitle: currentProgramDetails.title,
            };
            return (
                <>
                    <section className="current-vdo pt-2 pt-md-4 pt-sm-4">
                        <div className="container-fluid-live extended-rail-margin">
                            <div className="row mx-0">
                                {/* player */}
                                <PlaybackContainer
                                    playbackEventsInitialized={this.playbackEventsInitialized}
                                    contentDetails={currentProgramDetails}
                                    videoMeta={videoMeta}
                                    playableId={currentProgramDetails.id}
                                />
                                {/* side description */}
                                <DescriptionComponent
                                    allPlans={allPlans}
                                    logo={currentProgramDetails.logoUrl}
                                    title={currentProgramDetails.title}
                                    info={info}
                                    description={currentProgramDetails.description}
                                    shortUrl={currentProgramDetails.shortUrl}
                                    channelName={channelName}
                                    isLive
                                    socialShareInfo={socialShareInfo}
                                    resizeOption={RESIZE_OPTION.TAB_LARGE}
                                />
                            </div>
                        </div>
                    </section>

                    {scheduleChannelsElement}

                    {catchUpRailElement}

                    {relatedChannelsElement}

                    {relatedTvShowElement}
                </>
            );
        }
        const { allContentDetails } = this.props;
        // add catchup data
        if (currentProgramDetailsFromServer && Object.keys(currentProgramDetailsFromServer).length > 0) {
            const { contentId } = currentProgramDetailsFromServer;
            const tvShowDetails = allContentDetails[contentId];
            if (contentId && tvShowDetails) {
                const {
                    episodeRefs,
                    id: tvShowId,
                } = tvShowDetails;

                if (episodeRefs && episodeRefs.length > 0) {
                    const tileItems = episodeRefs.map(item => ({
                        ...item,
                        id: item.refId,
                        channelId: contentDetails.id,
                        programType: PROGRAM_TYPES.CATCHUP_EPISODE,
                        tvShowId,
                    }));

                    const railProps = buildRail({
                        items: tileItems,
                        explicitTileType: LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE,
                        explicitRailTitle: 'Previous Episodes',
                        more: false,
                        channels: channel,
                    });
                    catchUpRailElement = getRail({
                        railType: railProps.railType,
                        railProps,
                    });
                }
            }
        }
        const videoMeta = {
            videoTitle: channelName,
            subTitle: contentDetails.title,
        };

        return (
            <>
                <section className="current-vdo pt-2 pt-md-4 pt-sm-4">
                    <div className="container-fluid-live extended-rail-margin">
                        <div className="row">
                            {/* player */}
                            <PlaybackContainer
                                playbackEventsInitialized={this.playbackEventsInitialized}
                                contentDetails={contentDetails}
                                videoMeta={videoMeta}
                                playableId={contentDetails.id}
                            />
                            {/* side description */}
                            <DescriptionComponent
                                allPlans={allPlans}
                                logo={
                                    contentDetails.images.LOGOS_TNPT || contentDetails.images.LOGO
                                }
                                title={contentDetails.title}
                                description={contentDetails.description}
                                shortUrl={contentDetails.shortUrl}
                                channelName={channelName}
                                isLive
                                socialShareInfo={socialShareInfo}
                                isBot={this.deviceUtil.isBot()}
                                resizeOption={RESIZE_OPTION.TAB_LARGE}
                            />
                        </div>
                    </div>
                </section>

                {scheduleChannelsElement}

                {catchUpRailElement}

                {relatedChannelsElement}

                {relatedTvShowElement}
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        epg, contentDetails, channel, appConfig: { pages = {} } = {}
    } = state;
    const { contentId, match, allPlans } = props;
    const { url: currentUrl } = match;
    const currentChannelEpg = (epg.programGuide ? epg.programGuide[contentId] : []) || [];
    const fetchingEpg = epg.fetching;
    const { channelNotFoundId } = epg;
    const currentProgramDetailsFromServer = getCurrentProgram(currentChannelEpg);

    return {
        contentDetails: contentDetails[contentId] || {},
        channel: channel.channels,
        currentChannelEpg,
        fetchingEpg,
        channelNotFoundId,
        allContentDetails: contentDetails,
        currentProgramDetailsFromServer,
        allPlans,
        currentUrl,
        pages,
    };
};

LiveDetailContainer.defaultProps = {
    // contentDetails: {},
    // content: {},
    // programDetails: [],
    // channels: [],
    fetchingEpg: false,
    contentDetails: {},
    currentChannelEpg: [],
    channel: [],
    channelNotFoundId: '',
    allContentDetails: {},
    currentProgramDetailsFromServer: {},
    currentUrl: '',
};

LiveDetailContainer.propTypes = {
    fetchingEpg: PropTypes.bool,
    fetchEpg: PropTypes.func.isRequired,
    contentDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
    allContentDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
    currentProgramDetailsFromServer: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
    contentId: PropTypes.string.isRequired,
    currentChannelEpg: PropTypes.array, // eslint-disable-line react/forbid-prop-types,
    channel: PropTypes.array, // eslint-disable-line react/forbid-prop-types,
    changeMetaDataDispatch: PropTypes.func.isRequired,
    fetchAllChannelsDispatch: PropTypes.func.isRequired,
    contentDetailsFetchDispatch: PropTypes.func.isRequired,
    channelNotFoundId: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    pages: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    currentUrl: PropTypes.string,
    updatePageBreadCrumbsDispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(
    mapStateToProps,
    {
        fetchEpg: fetchEpgChannelDetails,
        fetchAllChannelsDispatch: fetchAllChannels,
        changeMetaDataDispatch: changeMetaData,
        contentDetailsFetchDispatch: contentDetailsFetchAction,
        updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
    },
)(withDeviceUtil(LiveDetailContainer)));
