import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { contentDetailsFetchAction, seriesDetailsFetchAction } from './actions/ContentDetailsAction';
import ErrorHandler from '../../../web/src/modules/error-handler/ErrorHandler';
import { LoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/LoaderComponent';
import getDetailPage from './factories/DetailPageFactory';
import { PROGRAM_TYPE_TITLE_MAPPING } from '@airtel-tv/constants/GlobalConst';
import { ErrorAnalyticsHelper } from '../../../web/src/modules/error-handler/ErrorAnalyticsHelper';
import { getContentPageFooterAction } from '../../../web/src/modules/footer/actions/FooterAction';
import  CrashCaptureHOC from '@airtel-tv/lib/hoc/CrashCaptureHOC';


// if (typeof window !== 'undefined') {
//     require('bootstrap/dist/js/bootstrap');
// }
class ContentDetailsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentId: '',
            contentDetails: null,
            match: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { match, contentDetails, contentIdFromParam } = nextProps;

        let changes = {};

        // check if new content
        if (contentIdFromParam !== prevState.contentId) {
            changes = {
                ...prevState,
                ...changes,
                contentId: contentIdFromParam,
            };
        }
        if (contentDetails !== prevState.contentDetails) {
            changes = {
                ...prevState,
                ...changes,
                contentDetails,
            };
        }
        if (match !== prevState.match) {
            changes = {
                ...prevState,
                ...changes,
                match,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        const { contentDetails: { title, description } } = this.props;

        if (prevProps.contentDetails.title !== title || prevProps.contentDetails.description !== description) {
            this.init();
        }

        // if(this.props.contentDetails.error && prevProps.showModal !== this.props.showModal && !this.props.showModal) {
        //     redirectToHomepgaw
        // }  
    }

    init = (reload) => {
        const {
            contentDetailsFetchDispatch,
            contentIdFromParam,
            contentDetails,
            seriesDetailsFetchActionDispatch,
            getContentPageFooterActionDispatch,
            isSeriesPage,

        } = this.props;
        // If user comes back to the same page
        const lang = lodashGet(contentDetails, 'languages', '');
        if (lang) {
            getContentPageFooterActionDispatch({
                lang,
            });
        }

        if (contentDetails && contentDetails.fetching) {
            return;
        }

        if (!reload && contentDetails && contentDetails.error) {
            return;
        }

        if (!isSeriesPage && (!contentIdFromParam || contentIdFromParam === contentDetails.id)) {
            return;
        }
        if (isSeriesPage && (!contentIdFromParam || (contentIdFromParam === contentDetails.id && contentDetails.hasSelfSufficientContent))) {
            return;
        }

        // FOR TV SHOWS SERIES WILL BE USED
        if (isSeriesPage) {
            seriesDetailsFetchActionDispatch(contentIdFromParam);
        }
        else {
            contentDetailsFetchDispatch(contentIdFromParam);
        }
    };


    render() {
        const { contentDetails } = this.state;
        const {
            contentIdFromParam,
            isSeriesPage,
            programTypeTitle,
        } = this.props;

        // handle error
        if (contentDetails.error) {
            const errorCode = lodashGet(contentDetails, 'error.errorcode', '') || lodashGet(contentDetails, 'error.errorCode', '');
            const error = lodashGet(contentDetails, 'error', {});
            ErrorHandler({
                error,
                code: errorCode,
            });
            ErrorAnalyticsHelper({
                errorCode,
                contentId: contentIdFromParam,
                programTypeTitle,
            });
            return null;
        }

        // contentId :: is taken form address bar to make parallel calls otherwise will have to wait for the content details call to finish
        const detailProps = {
            contentId: contentIdFromParam,
            contentDetails,
        };

        // you got the content so render player
        // must check id as it can be empty object
        if (contentDetails.id && (!isSeriesPage || contentDetails.hasSelfSufficientContent)) {
            const getDetailElement = getDetailPage({ detailProps });
            return (
                <>
                    {getDetailElement}
                </>
            );
        }

        return <LoaderComponent pageType={'live-tv'} />;
    }
}

const mapStateToProps = (state, props) => {
    const { contentDetails } = state;
    const { match } = props;
    const {
        params: {
            contentId,
            category,
        },
    } = match;

    // if (contentId) {
    //     contentId = decodeContentId(contentId);
    // }

    const isSeriesPage = [
        PROGRAM_TYPE_TITLE_MAPPING.TVSHOW,
        PROGRAM_TYPE_TITLE_MAPPING.SEASON,
        PROGRAM_TYPE_TITLE_MAPPING.EPISODE,
    ]
        .indexOf(category) > -1;

    const prop = {
        contentDetails: contentDetails[contentId] || {},
        contentIdFromParam: contentId,
        isSeriesPage,
        programTypeTitle: category,
    };

    return prop;
};

ContentDetailsContainer.defaultProps = {
    contentIdFromParam: null,
    programTypeTitle: null,
};

ContentDetailsContainer.propTypes = {
    contentDetails: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    contentDetailsFetchDispatch: PropTypes.func.isRequired,
    seriesDetailsFetchActionDispatch: PropTypes.func.isRequired,
    contentIdFromParam: PropTypes.string,
    programTypeTitle: PropTypes.string,
    isSeriesPage: PropTypes.bool.isRequired,
    getContentPageFooterActionDispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, {
    contentDetailsFetchDispatch: contentDetailsFetchAction,
    seriesDetailsFetchActionDispatch: seriesDetailsFetchAction,
    getContentPageFooterActionDispatch: getContentPageFooterAction,
})(CrashCaptureHOC(ContentDetailsContainer)));
