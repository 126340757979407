import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { LinkComponent } from '@airtel-tv/ui-lib/atoms/link/LinkComponent';
import { IMAGE_PATHS, MODAL_POPUP, LOCAL_RAIL_TYPES, LOCAL_TILE_TYPES, BLOCK_CODES, LOGIN_SOURCE, SUBSCRIPTION_SOURCE, ANALYTICS_ASSETS } from '@airtel-tv/constants';
import { LanguageProviderUtil, SubscriptionUtil, DateTime, RoutingUtil, getCurrentUrl, getCurrentWindowSourceName, getWindowLocation } from '@airtel-tv/utils';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
import ImageFactory from '../../../../../web/src/modules/layout/factory/ImageFactory';
import styles from './SubscriptionContainer.scss';
import { subscriptionInit } from '../../../../../web/src/modules/subscription/SubscriptionAction';
import { triggerLogin } from '../../../../../web/src/modules/auth/AuthAction';
import { showModalComponentAction } from '../../../../../web/src/modules/modal-popup/ModalPopupActions';
import { subscriptionClickEvent, watchForFreeClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
// import { subscriptionClickEvent, watchForFreeClickEvent } from '../../../analytics/FunctionalEvents';
import { getPrefferedPartnerList } from '@airtel-tv/utils/GlobalUtil';

const windowLocation = getWindowLocation();

class SubscriptionContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            cpDetailsById,
            subscriptionDataById,
            contentDetails,
            isAuthenticated,
            isMaxTabWidth,
        } = nextProps;
        const cpId = lodashGet(contentDetails, 'cpId', '');
        const channelId = SubscriptionUtil.getChannelId({
            cpDetailsById,
            cpId,
        });

        const cpDetails = cpDetailsById[cpId];
        const subscriptionDetails = lodashGet(subscriptionDataById, `[${channelId}]`, null);
        const channelDetails = lodashGet(cpDetailsById, `[${channelId}]`, null);
        const targetHierarchy = lodashGet(channelDetails, 'targetHierarchy', 0);
        const isSubscribed = SubscriptionUtil.isSubscribed(subscriptionDetails, targetHierarchy);

        if (cpDetails !== prevState.cpDetails
            || subscriptionDetails !== prevState.subscriptionDetails
            || isAuthenticated !== prevState.isAuthenticated
            || isSubscribed !== prevState.isSubscribed
            || channelDetails !== prevState.channelDetails
            || isMaxTabWidth !== prevState.isMaxTabWidth) {
            return {
                cpDetails,
                subscriptionDetails,
                isAuthenticated,
                isSubscribed,
                channelDetails,
                isMaxTabWidth,
            };
        }
        return null;
    }

    getRightArrowIcon = () => (
        <div className="align-centre right-chev-brand">
            {/* <ThumborImage
                src={IMAGE_PATHS.RAIL_TITLE_ARROW}
                alt=""
            /> */}
        </div>
    )

    getLogoURL = () => {
        const { channelDetails } = this.state;

        const images = {
            [CONTENT_IMAGE_TYPES.LOGOS_TNPT]: channelDetails.cpIconURL,
            [CONTENT_IMAGE_TYPES.LOGO]: channelDetails.logoUrl,
            [CONTENT_IMAGE_TYPES.CIRCULAR]: channelDetails.roundLogoUrl,
            [CONTENT_IMAGE_TYPES.FEATURE_BANNER]: channelDetails.bannerImageUrl,
        };

        return ImageFactory({
            images,
            tileType: LOCAL_TILE_TYPES.CHANNEL_LOGO,
            railType: LOCAL_RAIL_TYPES.NO_RAIL,
        });
    }

    showXmpClaimModal = () => {
        const { showModalComponentActionDispatch, contentDetails } = this.props;
        const contentUrl = windowLocation.pathname;
        showModalComponentActionDispatch({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.XMP_CLAIM_MODAL_POPUP,
            overrideCrossButtonValue: true,
            payload: {
                contentDetails,
                contentUrl,
                origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE_XMP_MODAL,
            },

        });
    };

    onSubscribeButtonClick = () => {
        const {
            isAuthenticated, triggerLoginDispatch, history, contentDetails, preferredPartnerPlans, circle, sid,
        } = this.props;
        const contentUrl = windowLocation.pathname;
        const PREFFERED_PARTNER_LIST = getPrefferedPartnerList(preferredPartnerPlans);
        if (!isAuthenticated) {
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.SUBSCRIBE_BUTTON,
            });
        }
        else if (preferredPartnerPlans && preferredPartnerPlans.length && PREFFERED_PARTNER_LIST.includes(contentDetails.cpId)) {
            // this.showXmpClaimModal()
            // history.push('/xmp-claim');
            // this.props.showRedeemXMPAction();
            // this.showXmpClaimModal();
            if (preferredPartnerPlans.length === 1) {
                const pageLoadAnalyticsMeta = {
                    pageUrl: getCurrentUrl(),
                    appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
                    circle,
                    sid,
                    active_tab: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    source: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    pageName: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    service: ANALYTICS_ASSETS.AIRTELTV,
                };
                watchForFreeClickEvent(pageLoadAnalyticsMeta, true);
                this.showXmpClaimModal();
            }
            else {
                const pageLoadAnalyticsMeta = {
                    pageUrl: getCurrentUrl(),
                    appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
                    circle,
                    sid,
                    active_tab: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    source: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    pageName: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    service: ANALYTICS_ASSETS.AIRTELTV,
                };
                watchForFreeClickEvent(pageLoadAnalyticsMeta, true);
                history.push({
                    pathname: RoutingUtil.getXmpClaim(),
                    state: {
                        contentDetails,
                        contentUrl,
                        origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                        sid,
                        ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    },
                });
            }
        }
        else {
            const cpId = lodashGet(contentDetails, 'cpId', '');
            subscriptionClickEvent({
                asset_name: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                source_name: getCurrentWindowSourceName(),
                channel_name: cpId,
                cp_name: cpId,
                sid,
                ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
            });
            history.push({
                pathname: RoutingUtil.getSubscriptionPage(),
                state: {
                    ...contentDetails,
                    contentUrl,
                    origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                },
            });
        }
    };

    getSubscribeBtn = (isRenewBtn = false, expiryDaysRem = 3) => {
        const {
            channelDetails: {
                planStartText,
            },
        } = this.state;
        const {
            preferredPartnerPlans, contentDetails, watchForFreeBtnSubtext,
        } = this.props;
        const PREFFERED_PARTNER_LIST = getPrefferedPartnerList(preferredPartnerPlans);
        let btnText = this.LANGUAGE.SUBSCRIBE;
        let subText = planStartText;
        let btnClassName = 'lg-btn';

        if (preferredPartnerPlans && preferredPartnerPlans.length && PREFFERED_PARTNER_LIST.includes(contentDetails.cpId)) {
            btnText = 'Watch for free';
            subText = watchForFreeBtnSubtext;
        }

        if (isRenewBtn) {
            btnText = this.LANGUAGE.RENEW;
            subText = this.LANGUAGE.SUBSCRIPTION_EXPIRY_TEXT.replace(/{days}/g, expiryDaysRem);
            btnClassName = 'mid-btn';
        }
        return (
            <div className={`subs-container ${btnClassName}`}>
                {/* get border style through theme */}
                <button
                    type="button"
                    className="theme-btn border-only-primary-cta xp-subscribe"
                    // onClick={() => {
                    //     subscriptionInitDispatch({
                    //         id: cpId,
                    //         successRedirection: SUBSCRIPTION_SUCCESSFUL_PAGE_REDIRECTION.CURRENT,
                    //         source: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                    //     });
                    // }}
                    onClick={this.onSubscribeButtonClick}
                >
                    {preferredPartnerPlans && preferredPartnerPlans.length && PREFFERED_PARTNER_LIST.includes(contentDetails.cpId) ? (
                        <ThumborImage
                            className="subscribe-play-icon"
                            src="/static/subscribe-play-icoon.svg"
                        />
                    ) : ''}
                    {btnText}
                </button>
                <span className="sub-text">{subText}</span>
            </div>
        );
    }

    /**
     * GET RIGHT Section of Channel Detail
     * if not authenticated: show nothing since we don't know user's state
     * else if is subscribed and he has hierarchy greater than max hierarchy:
     *      if the expiry date is close, if yes then show renew btn
     *      else show subscribed cta
     * else : show subscribe btn
     *
     * @returns {JSX.Element} html to be shown in the right section
    */
    buildRightSection = () => {
        const {
            isAuthenticated, isMaxTabWidth, byPassCode,
        } = this.props;
        const { isSubscribed, subscriptionDetails } = this.state;
        if (!isAuthenticated || byPassCode) {
            if (isMaxTabWidth) {
                return this.getRightArrowIcon();
            }
            return null;
        }
        if (isSubscribed) {
            // UNCOMMENT WHEN RENEW BUTTON FUNCTIONALITY IS NEEDED
            const { expiry, free } = subscriptionDetails;
            // const expiryDaysRem = Math.floor((expiry - Date.now()) / MS_IN_A_DAY);
            // if (showRenewCode && expiry && expiryDaysRem <= 3) {
            //     return this.getSubscribeBtn(true, expiryDaysRem);
            // }

            if (!isMaxTabWidth) {
                const expiryDate = new DateTime(expiry).format('DD MMMM');
                const subscriptionReference = free
                    ? this.LANGUAGE.SUBSCRIBED_FOR_FREE
                    : this.LANGUAGE.SUBSCRIPTION_DAYS_TEXT.replace(/{date}/g, expiryDate);
                return (<span className="sub-text">{subscriptionReference}</span>);
            }

            return this.getRightArrowIcon();
        }
        return this.getSubscribeBtn();
    }

    render() {
        const {
            isSubscribed, subscriptionDetails, channelDetails, cpDetails,
        } = this.state;
        const { byPassCode } = this.props;
        if (!channelDetails) {
            return null;
        }
        let { title, shortDesc } = channelDetails;
        const pageLink = lodashGet(channelDetails, 'pageLink', '');
        if (byPassCode) {
            ({ title, shortDesc } = cpDetails);
        }

        const logoUrl = this.getLogoURL();

        let channelSubtext = shortDesc;

        if (isSubscribed && !byPassCode) {
            const { expiry, free } = subscriptionDetails;
            if (free) {
                channelSubtext = this.LANGUAGE.SUBSCRIBED_FOR_FREE;
            }
            else {
                const expiryDate = new DateTime(expiry).format('DD MMMM');
                channelSubtext = this.LANGUAGE.SUBSCRIPTION_DAYS_TEXT.replace(/{date}/g, expiryDate);
            }
        }

        const rightSection = this.buildRightSection();

        const leftSection = (
            <>
                <div className="logo-wrapper">
                    <div className="logo-card">
                        <ThumborImage
                            src={logoUrl}
                            alt={title}
                        />
                    </div>
                    {
                        isSubscribed ? (
                            <div className="subscribed-icon">
                                <ThumborImage
                                    src={IMAGE_PATHS.SUBSCRIBED_TICK}
                                />
                            </div>
                        ) : null
                    }
                </div>
                <div className="current-vdo-channel-text">
                    <h3 className="title">
                        {title}
                    </h3>
                    {
                        channelSubtext && <span className="channel-desc">{channelSubtext}</span>
                    }
                </div>
            </>
        );
        return byPassCode ? null : (
            <div className="footer-section">
                <div className="current-vdo-channel-detail">
                    <div className="left-section">
                        {
                            pageLink ? (
                                <LinkComponent
                                    to={RoutingUtil.getChannelPartnerPageUrl({
                                        channelPartnerName: title,
                                        pageLink,
                                    })}
                                    className="partner-page-link"
                                >
                                    {leftSection}
                                </LinkComponent>
                            ) : (
                                leftSection
                            )
                        }
                    </div>
                    {rightSection}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        userConfig,
        appConfig,
        authConfig,
        planConfig,
    } = state;
    const byPassCode = lodashGet(appConfig.blockFeature, BLOCK_CODES.PARTNER_CHANNEL, false);
    // preferredPartnerPlans
    // const showRenewCode = lodashGet(appConfig.blockFeature, BLOCK_CODES.RENEW_BTN, false);
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', null);
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const watchForFreeBtnSubtext = lodashGet(appConfig, 'watchForFreeBtnSubtext', '');

    return {
        cpDetailsById: appConfig.cpDetailsById,
        subscriptionDataById: userConfig.subscriptionDataById,
        isAuthenticated: authConfig.isAuthenticated,
        byPassCode,
        preferredPartnerPlans,
        sid,
        circle,
        watchForFreeBtnSubtext,
        // showRenewCode,
    };
}

SubscriptionContainer.propTypes = {
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
    subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired,
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types,
    isAuthenticated: PropTypes.bool.isRequired,
    isMaxTabWidth: PropTypes.bool.isRequired,
    byPassCode: PropTypes.bool.isRequired,
    subscriptionInitDispatch: PropTypes.func.isRequired,
    triggerLoginDispatch: PropTypes.func.isRequired,
    // showRenewCode: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
    subscriptionInitDispatch: subscriptionInit,
    showModalComponentActionDispatch: showModalComponentAction,
    triggerLoginDispatch: triggerLogin,
})(withRouter(withStyles(styles)(SubscriptionContainer)));
