import { PropTypes } from 'prop-types';
import lodashGet from 'lodash/get';
import React from 'react';
import { Navigate } from 'react-router';
import ChannelPartnerLayoutContainer from '../../../../web/src/modules/channel-partner/container/ChannelPartnerLayoutContainer';
import EventDetailContainer from '../containers/EventDetailContainer';
import {RoutingUtil} from '@airtel-tv/utils';
import { PAGE_TITLE_LIST } from '@airtel-tv/constants/RouteConsts';


const getContentLayoutPage = ({ props }) => {
    const pageTitle = lodashGet(props, 'match.params.pageTitle', '');
    switch (pageTitle) {
        case PAGE_TITLE_LIST.CHANNEL:
            return <ChannelPartnerLayoutContainer {...props} />;

        case PAGE_TITLE_LIST.PROMOTION:
            return <EventDetailContainer {...props} />;
        default: return (
            <Navigate
                from={RoutingUtil.getHomePage()}
                to={RoutingUtil.get404Route()}
            />
        );
    }
};

getContentLayoutPage.propTypes = {
    props: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    pageTitle: PropTypes.string.isRequired,
};

export default getContentLayoutPage;
