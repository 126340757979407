import { ContentDetailsActions } from '../actions/ContentDetailsAction';

const initialState = JSON.parse('{}');

const ContentDetailsReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case ContentDetailsActions.CONTENT_DETAIlS_FETCH:
            changes = {
                [action.contentId]: {
                    fetching: true,
                },
            };
            break;
        case ContentDetailsActions.CONTENT_DETAIlS_FETCH_ERROR:
            changes = {
                ...action.payload,
            };
            break;

        case ContentDetailsActions.CONTENT_DETAIlS_FETCH_COMPLETED:
            changes = {
                ...action.payload,
            };
            break;
        case ContentDetailsActions.SET_PREV_PAGE_TITLE:
            changes = {
                ...action.payload,
            };
            break;
        case ContentDetailsActions.EMPTY_DETAIL_ACTION:
            return {};
        case ContentDetailsActions.CLEAN_NEXT_TAB: 
            delete state.nextTab;
            return {...state};
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default ContentDetailsReducer;
