import 'intersection-observer';
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router';
import lodashIsEqual from 'lodash/isEqual';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { getCurrentUrl, checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { IntersectionObservableHOC } from '@airtel-tv/lib/hoc/IntersectionObservableHOC';
import { INTERSECTION_OBSERVER_THRESHOLD, IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import { RAIL_LIMIT } from '@airtel-tv/constants/LayoutConstants';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import { SmallLoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/SmallLoaderComponent';
import { readStitchId, setAnalyticsMeta } from '@airtel-tv/utils/GlobalUtil';
import { getPackageContentByLayout, getPackIdsFromLayout } from '@airtel-feature/layout/helpers/LayoutHelper';
import { pageLoadEvent, screenVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { isWeb } from '@airtel-feature/playback/util/PlaybackUtility';
import ContentDetailsContainer from '../ContentDetailsContainer';
import ContentLayoutUtil from '../utils/ContentLayoutUtil';

const getPageIdFromProps = (match, appConfig) => {
    const {
        params: { pageTitle, contentId },
    } = match;
    const { pageIdTitleMap } = appConfig;

    const web_pageId = {
        CONTENT_DETAIL: 'contentDetail',
        MORE_LIKE_THIS: 'moreLikeThis',
        CAST_N_MORE: 'castNMore',
        ARTIST_DETAIL: 'artistDetail',
        LIVE_TV: 'livetv-channels',
        BLOGS_PAGE: 'blog',
        SPORTS_LAYOUT: 'XstreamSports',
    };

    let pageId = null;

    if (contentId) {
        pageId = web_pageId?.CONTENT_DETAIL;
    }
    else if (pageTitle) {
        pageId = pageIdTitleMap[pageTitle];
    }
    else {
        pageId = appConfig.pages ? appConfig.pages[0].id : null; // first page is default page
    }

    return {
        contentId,
        pageId,
    };
};

class ContentDetailLayoutContainer extends Component {
    constructor(props) {
        super(props);

        this.deviceUtil = props.deviceUtil;

        const { layoutPackages = [] } = props;


        const pageData = layoutPackages && layoutPackages.length ? layoutPackages : [];
        this.state = {
            paginatedData: [
                ...pageData,
            ],
            contentId: '',
            railCount: 0,
            showErrorComponent: false,
        };

        this.infiniteScrollerRef = createRef();

        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.numberOfRails = 0;
        this.railsRef = createRef([]);
        this.railsRef.current = this.getRailElement();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { pageId } = nextProps;
        const {
            layoutPackages,
            packageContents,
            userConfig,
            layoutError,
            isAuthenticated,
            contentId,
            authConfig,
        } = nextProps;

        let paginatedData = [];
        const contentForLayoutPackages = getPackageContentByLayout(layoutPackages, packageContents);
        const newContentAvailable = !lodashIsEqual(contentForLayoutPackages, prevState.contentForLayoutPackages);

        if (layoutPackages && layoutPackages.length > 0 && !lodashIsEqual(prevState.layoutPackages, layoutPackages)) {
            const previousLayoutCount = 0;
            const pageData = layoutPackages.slice(previousLayoutCount, previousLayoutCount + RAIL_LIMIT.USER);
            paginatedData = [
                ...pageData,
            ];
        }

        if (pageId !== prevState.pageId
            || layoutPackages !== prevState.layoutPackages
            || newContentAvailable
            || layoutError !== prevState.layoutError
            || isAuthenticated !== prevState.isAuthenticated
            || contentId !== prevState.contentId
            // || (!authConfig.loginTriggered && !lodashIsEqual(userConfig.userContentProperties, prevState.userConfig.userContentProperties))
        ) {
            return {
                pageId,
                layoutPackages,
                contentForLayoutPackages,
                userConfig,
                layoutError,
                isAuthenticated,
                contentId,
                paginatedData,
            };
        }

        return null;
    }

    componentDidMount() {
        const {
            fetchEpgChannelDetailsDispatch,
            hasEpgData,
            location,
            contentDetails,
            history,
            contentId = '',
        } = this.props;
        const { currentTab } = LocationUtil.getQueryParams(this.props);
        if (currentTab && currentTab !== '') {
            history.replace({
                pathname: location.pathname,
            });
        }
        this.stitchId = readStitchId();

        // if (!hasEpgData) {
        //     fetchEpgChannelDetailsDispatch();
        // }
        this.init();
        this.ErrorHandle();

        this.userSessionId = this.getUserSessionId();
        pageLoadEvent({
            source_name: ANALYTICS_ASSETS.CONTENT_DETAIL_PAGE,
            pageUrl: getCurrentUrl(),
            user_session_id: this.userSessionId,
            content_id: contentId,
            playback_stitch_key: this.stitchId,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState !== this.state;
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            packageResetDispatch,
            appConfig: { web_pageId },
            contentId,
            layoutError,
            layoutPackages,
            layoutReset,
        } = this.props;
        const { paginatedData } = this.state;
        const { paginatedData: prevPaginatedData } = prevState;
        const {
            contentId: prevContentId,
            layoutError: prevLayoutError,
            layoutReset: prevLayoutReset,
        } = prevProps;
        if (prevContentId !== contentId) {
            packageResetDispatch({ pageId: web_pageId?.CONTENT_DETAIL });
        }
        if ((layoutReset && prevLayoutReset !== layoutReset) || prevContentId !== contentId) {
            this.railsRef.current = [];
            this.setState({
                paginatedData: [],
                railCount: 0,
            });
            this.init(prevProps);
        }
        if (!lodashIsEqual(prevLayoutError, layoutError)) {
            this.ErrorHandle();
        }

        if (prevContentId !== contentId) {
            this.getUserSessionId();
        }
        if (layoutPackages && !lodashIsEqual(prevPaginatedData, paginatedData)) {
            this.railsRef.current = this.getRailElement();
            if (this.railsRef.current && Object.keys(this.railsRef.current).length) {
                this.setState({
                    railCount: layoutPackages.length,
                });
            }
        }
    }

    componentWillUnmount() {
        const {
            packageResetDispatch, emptyDetailDispatch, setPreviousPageTitleActionDispatch, deviceUtil: { isMobile }, appConfig: { web_pageId },
        } = this.props;
        packageResetDispatch({ pageId: web_pageId?.CONTENT_DETAIL });
        emptyDetailDispatch();
        setPreviousPageTitleActionDispatch({ prevPageTitle: '' });
        const headerEle = document.getElementsByTagName('header')[0];
        if (isMobile() && headerEle) {
            headerEle.style.display = 'block';
        }
    }

    getRailElement = () => {
        const {
            contentForLayoutPackages,
            paginatedData,
        } = this.state;
        const {
            channels, themeConfig, contentId, pageId,
        } = this.props;
        const {
            appConfig: { cpDetailsById },
            userConfig: { subscriptionDataById },
            railFactory,
        } = this.props;

        return ContentLayoutUtil.getRailElement({
            channels,
            themeConfig,
            cpDetailsById,
            contentForLayoutPackages,
            paginatedData,
            subscriptionDataById,
            numberOfRails: this.numberOfRails,
            contentId,
            pageId,
            getRail: railFactory,
        });
    };


    // eslint-disable-next-line consistent-return
    ErrorHandle = () => {
        const {
            contentForLayoutPackages,
        } = this.state;
        const {
            packageContents,
            layoutError,
            layoutPackages,
            contentId,
            pageId,
            resetTileInfoActionDispatch = () => {},
            isErrorPageShownDispatch,
        } = this.props;
        let hasError = false;
        if (layoutError && Object.keys(contentForLayoutPackages).length < 1) {
            hasError = true;
        }
        const packageIds = getPackIdsFromLayout(layoutPackages);
        // no data found
        if (layoutPackages && (Object.keys(layoutPackages).length < 1)) {
            // no content  found
            hasError = true;
        }
        if (packageContents.error && Object.keys(contentForLayoutPackages).length < 1) {
            // no content  found
            hasError = true;
        }
        if (hasError) {
            screenVisibleEvent('black_hole', false, {
                contentId,
                page_id: pageId,
            });
            this.setState({ showErrorComponent: true }, () => {
                if (resetTileInfoActionDispatch && typeof resetTileInfoActionDispatch === 'function') {
                    resetTileInfoActionDispatch();
                }
            });
            if (isErrorPageShownDispatch) {
                isErrorPageShownDispatch({ isErrorPageShown: true });
            }
        }
        else {
            this.setState({ showErrorComponent: false });
            if (isErrorPageShownDispatch) {
                isErrorPageShownDispatch({ isErrorPageShown: false });
            }
        }
    };

    init(prevProps = {}) {
        const {
            pageId,
            layoutPackages,
        } = this.state;

        const previousContentId = prevProps.contentId;

        // fetch layout
        const {
            userConfig,
            layoutFetchActionDispatch,
            contentId,
            appConfig: { web_pageId },
            layoutDetails,
        } = this.props;
        if (userConfig.userContentProperties
            && (!layoutPackages || (Object.keys(layoutPackages).length < 1))
            || (contentId && contentId !== previousContentId)
        ) {
            this.setState({ showErrorComponent: false });
            layoutFetchActionDispatch({
                pageId,
                count: RAIL_LIMIT.USER,
                contentId,
            });
        }
        if(!layoutDetails?.[web_pageId?.SDK_AD_CONFIG]) {
            this.fetchAdConfig();
        }
    }

    fetchAdConfig = () => {
        const { layoutFetchActionDispatch, appConfig: { web_pageId } } = this.props;
        layoutFetchActionDispatch({
            pageId: web_pageId?.SDK_AD_CONFIG || 'adsConfig',
        });
    };

    getUserSessionId = () => {
        const { userSessionId } = setAnalyticsMeta(this.props.contentId);
        return userSessionId;
    };


    render() {
        const {
            pageId: pageIdFromState,
            showErrorComponent,
        } = this.state;
        const {
            layoutPackages,
            match,
            appConfig,
            layoutError,
            showPlayback,
            location,
            deviceUtil: { isMobile },
            LoaderComponent,
            ErrorPageComponent,
            directPlayback = false,
        } = this.props;
        const { params: { category } } = match;

        let pageId = pageIdFromState;

        // if (pageId === appConfig.liveTvId) {
        //     return <LiveChannelBox />;
        // }

        if ((appConfig.web_pageId && Object.keys(appConfig.web_pageId).length > 0 && category === appConfig.web_pageId?.LIVE_TV)) {
            return <ContentDetailsContainer />;
        }

        if (!pageIdFromState) {
            const pageIdFromProps = getPageIdFromProps(match, appConfig).pageId;
            if (!pageIdFromProps) {
                return (
                    <Navigate
                        from={RoutingUtil.getHomePage()}
                        to={RoutingUtil.get404Route()}
                    />
                );
            }
            pageId = pageIdFromProps;
        }

        const loader = (
            <LoaderComponent
                loaderType="isCdp"
                directPlayback={directPlayback}
            />
        );

        this.numberOfRails = layoutPackages && layoutPackages.length > 0 ? layoutPackages.length : 0;

        // // in case of SSR
        // if (!checkWindowExist()) {
        //     // console.error('I am logged hurray ===>>>>>>>>>>>>>>>>>>>>======>>>>>>>>>>>>>>>>>>>>');
        //     this.railsRef.current = this.getRailElement();
        // }

        const railElements = this.railsRef.current;

        const smallLoader = <SmallLoaderComponent key={pageId} />;
        const layoutApiFailed = !!layoutError;

        // if(this.deviceUtil.isBot()) {
        //     return (
        //         <CDPSeoLoader {...this.props } />
        //     )

        // }
        const lazyLoadRailElement = railElements ? (
            <IntersectionObservableHOC
                loaderElem={smallLoader}
                startPageNumber={1}
                id={pageId}
                threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
            >
                {railElements}
            </IntersectionObservableHOC>
        ) : layoutApiFailed ? null : loader;
        return (
            showErrorComponent ? (
                <ErrorPageComponent
                    message="Aw snap, something went wrong!"
                    imageUrl={IMAGE_PATHS.BLACK_HOLE}
                    buttonText="Retry"
                    action={() => this.init()}
                    buttonId={`${pageId}-error-cta`}
                    focusOnMount
                    showOtherRail
                    focusUpElement=".tab-button-pill.active"
                    focusUpTargetByClass
                />
            )
                : (
                    <section
                        className={`layoutSectionElement ${pageId === appConfig.web_pageId.CONTENT_DETAIL ? 'bg-black' : ''}`}
                        id="contentlayoutSectionElement"
                    >
                        {lazyLoadRailElement}
                    </section>
                )

        );
    }
}

ContentDetailLayoutContainer.propTypes = {
    userConfig: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    appConfig: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    layoutFetchActionDispatch: PropTypes.func.isRequired,
    fetchAllChannelsDispatch: PropTypes.func.isRequired,
    layoutPackages: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    channels: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    layoutError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    packageContents: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    isAuthenticated: PropTypes.bool.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    pageId: PropTypes.string.isRequired,
    fetchEpgChannelDetailsDispatch: PropTypes.func.isRequired,
    hasEpgData: PropTypes.bool.isRequired,
    themeConfig: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    contentId: PropTypes.string,
    packageResetDispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
};

ContentDetailLayoutContainer.defaultProps = {
    layoutError: null,
    layoutPackages: null,
    packageContents: {},
    channels: [],
    contentId: '',
};


export default ContentDetailLayoutContainer;
