/* eslint-disable react/prop-types */
import lodashGet from 'lodash/get';
import { RoutingUtil, LanguageProviderUtil } from '@airtel-tv/utils';

const TVShowNextEpBuilder = ({
    contentDetails, contentCollection, episodesDetails, contentId,
}) => {
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    let selectedSeasonId;
    let seasonDetails;

    let nextEpisode = null;
    let nextEpisodeUrl;
    let nextEpisodeDetails;
    let nextSeason = null;
    const tvShowDetails = { ...contentDetails };
    const { currentSeasonId } = contentCollection;
    const seriesId = contentCollection[currentSeasonId]?.seriesId;
    let seriesTvSeasons = lodashGet(contentCollection[seriesId], 'seriesTvSeasons', []);
    episodesDetails = [
        ...(episodesDetails?.map(episode => ({ ...episode }))?.sort((episodeRef1, episodeRef2) => episodeRef1.episodeNumber - episodeRef2.episodeNumber) || []),
    ];
    const curEpIndex = episodesDetails && episodesDetails.findIndex(episode => episode.refId === contentId);
    if (curEpIndex === -1 || isNaN(curEpIndex)) {
        return null;
    }
    if (seriesTvSeasons?.length > 0) {
        seriesTvSeasons = [
            ...seriesTvSeasons?.map(season => ({ ...season })).sort((season1, season2) => season1.seasonNumber - season2.seasonNumber),
        ];
        const currentSeasonIndex = seriesTvSeasons.map(season => ({ ...season })).findIndex(ele => ele.seasonId === currentSeasonId);
        const lastSeason = currentSeasonIndex === seriesTvSeasons.length - 1;
        if (!lastSeason && curEpIndex === (episodesDetails?.length - 1)) {
            nextSeason = seriesTvSeasons[currentSeasonIndex + 1];
            nextEpisode = nextSeason.firstEpsRef;
        }
        else {
            // nextEpisode = !!contentCollection.episodeOrder ? episodesDetails[curEpIndex + contentCollection.episodeOrder]: episodesDetails[curEpIndex + 1];
            nextEpisode = episodesDetails[curEpIndex + 1];
        }
        const [
            selectedSeason,
        ] = seriesTvSeasons;
        selectedSeasonId = selectedSeason.seasonId;
        seasonDetails = contentCollection[selectedSeasonId];
    }

    let nextEpTitle;
    let nextEpLinkTitle;
    const { title, programType } = contentDetails;
    if (nextEpisode) {
        nextEpisodeUrl = RoutingUtil.getTvEpisodeUrlWeb({
            programType,
            title,
            // seasonName: seasonDetails.title,
            episodeName: nextEpisode.name,
            contentId: nextEpisode.refId,
            episodeNumber: nextEpisode.episodeNumber,
        });
        nextEpisodeDetails = {
            details: {
                ...nextEpisode,
            },
            url: nextEpisodeUrl,
            nextEpLinkTitle,
            nextEpTitle,
        };
        if (nextSeason?.seasonId) {
            nextEpisodeDetails = {
                ...nextEpisodeDetails,
                nextSeasonId: nextSeason.seasonId,
            };
        }
    }
    return nextEpisodeDetails;
};

export default TVShowNextEpBuilder;
