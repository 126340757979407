import React from 'react';
import PropTypes from 'prop-types';
import { LinkComponent } from '@airtel-tv/ui-lib/atoms/link/LinkComponent';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { ContentImageProvider } from '@airtel-tv/utils/ContentImageProvider';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
// import { clickedOnCastGaEvent } from '../../../utilities/GaEvents';

const CastTileComponent = (props) => {
    const { cast } = props;
    const imageUrl = ContentImageProvider({ imageMap: cast && cast.images });
    /* eslint-disable */
    return (
        <div className="cast-item" key={cast.id}>
            <LinkComponent
                to={RoutingUtil.getArtistUrlWeb({ name: cast.displayTitle, id: cast.id })}
                title={cast.displayTitle}
                //onClick={() => clickedOnCastGaEvent(cast.id)}
            >
                <div className="cast-carousel__img">
                    <ThumborImage src={imageUrl} alt={cast.displayTitle} />
                </div>
                <div className="figure-caption">
                    <p>{cast.displayTitle}</p>
                </div>
            </LinkComponent>
        </div>
    );
    /* eslint-enable */
};

CastTileComponent.defaultProps = {
    cast: '',
};

CastTileComponent.propTypes = {
    cast: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default CastTileComponent;
