import { PROGRAM_TYPES } from "@airtel-tv/constants";

const createDeepLink = (base, contentDetails, isIOS) => {
    const { cpId, id, programType } = contentDetails;

    if (programType) {
        return isIOS ? `${base}${cpId}/${programType}/${id}` : `${base}${cpId}/${programType}/${id}/q`;
    }
    return null;
    // return `${base}Landing/home_container`;
};

export default createDeepLink;
