import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { findLanguageFromCode } from '@airtel-tv/utils/GlobalUtil';
import { getTileCtaUrl } from '@airtel-feature/layout/helpers/ClickToActionHelper';
// import { getTileCtaUrl } from '../../layout/helpers/ClickToActionHelper';
import { LanguageProviderUtil } from '@airtel-tv/utils';

const MultiAudioLangComponent = (props) => {
    const {
        contentDetails,
        languageCodesMap,
        title,
        audioLanguages,
        parentLang,
        langCode,
    } = props;

    const {
        programType, languages: contentLang,
    } = contentDetails;

    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const language = findLanguageFromCode(langCode, languageCodesMap);
    const curLang = contentLang ? contentLang[0] : null;
    const link = getTileCtaUrl({
        category: programType,
        contentId: audioLanguages[langCode],
        title,
    });
    return (
        <li>
            <Link to={link}>
                <div className={`audio-lang-pill ${curLang === langCode ? 'current-audio' : ''}`}>
                    {language}
                </div>
            </Link>
            {
                parentLang === langCode
                    ? <div className="original-audio-lang">{LANGUAGE.ORIGINAL}</div>
                    : null
            }
        </li>
    );
};

MultiAudioLangComponent.defaultProps = {
    languageCodesMap: null,
};
MultiAudioLangComponent.propTypes = {
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    audioLanguages: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    parentLang: PropTypes.string.isRequired,
    langCode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    languageCodesMap: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
};
export default MultiAudioLangComponent;
