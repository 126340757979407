import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import {
    showToastr,
    copyToClipboard,
    getCurrentUrl,
    isNativeShareAvailable,
    openPopupWindow,
    sendMail,
    sharedClick,
    LanguageProviderUtil,
} from '@airtel-tv/utils';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
// import { clickedOnShareGaEvent } from '../../../utilities/GaEvents';
// import { openContentInApp } from '../../../../service/end-points/DeeplinkApiService';

// TODO currently commented.Will open later
// const onNavigateApp = (shortUrl) => {
//     // window.open(SHARABLE_CONTENT.DEEP_LINK);
//     openContentInApp({ contentIdShortLink: shortUrl });
// };

// TODO: IMPLEMENT DEEPLINK FOR SHARING
const copyLinkToClipboard = (url) => {
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    copyToClipboard(url);
    showToastr(LANGUAGE.COPY_TO_LINK_MESSAGE, LANGUAGE.BUTTON_CLOSE);
};

const socialShare = (url) => {
    // clickedOnShareGaEvent();
    openPopupWindow(url, '_blank');
};

export default function ContentShareComponent({
    title, socialShareInfo, buttonClassName, isCustomDropDown, buttonBorderCss, hideShareText, isMobile, fromContentDetail, shareShortUrl = '',
    railCtaText = '',
}) {
    const {
        emailShareBody,
        facebookUrl,
        twitterUrl,
        windowsShareText,
    } = socialShareInfo;
    const [
        showShareOptions,
        handleShareOptions,
    ] = useState(false);

    function onMouseClick(evt) {
        const flyoutEl = document.getElementById('social-dropdown');
        const dropdownEl = document.getElementById('dropdownMenuLink');
        let targetEl = evt.target; // clicked element
        do {
            if ((targetEl?.id === flyoutEl?.id) || (targetEl?.id === dropdownEl?.id)) {
            // This is a click inside, does nothing, just return.
                return;
            }
            // Go up the DOM
            targetEl = targetEl.parentNode;
        } while (targetEl);
        // clicked outside share box
        handleShareOptions(false);
    }

    useEffect(() => {
        document.addEventListener('click', onMouseClick);
        return () => {
            document.removeEventListener('click', onMouseClick);
        };
    }, []);

    if (lodashIsEmpty(socialShareInfo)) {
        return null;
    }

    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const cdpImage = isMobile
        ? (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.0625 7.68647L12 3.75L15.9375 7.68647"
                    stroke="#CFD3DC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 14.2502V3.75293"
                    stroke="#CFD3DC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.25 14.25V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V14.25"
                    stroke="#CFD3DC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ) : (
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_b_0_322)">
                    <circle
                        cx="20"
                        cy="20"
                        r="20"
                        fill="#262B33"
                    />
                </g>
                <path
                    d="M14 23C15.6569 23 17 21.6569 17 20C17 18.3431 15.6569 17 14 17C12.3431 17 11 18.3431 11 20C11 21.6569 12.3431 23 14 23Z"
                    stroke="#CFD3DC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M24.5 29.75C26.1569 29.75 27.5 28.4069 27.5 26.75C27.5 25.0931 26.1569 23.75 24.5 23.75C22.8431 23.75 21.5 25.0931 21.5 26.75C21.5 28.4069 22.8431 29.75 24.5 29.75Z"
                    stroke="#CFD3DC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M24.5 16.25C26.1569 16.25 27.5 14.9069 27.5 13.25C27.5 11.5931 26.1569 10.25 24.5 10.25C22.8431 10.25 21.5 11.5931 21.5 13.25C21.5 14.9069 22.8431 16.25 24.5 16.25Z"
                    stroke="#CFD3DC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.977 14.8721L16.5234 18.3779"
                    stroke="#CFD3DC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.5234 21.6221L21.977 25.1279"
                    stroke="#CFD3DC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <defs>
                    <filter
                        id="filter0_b_0_322"
                        x="-4"
                        y="-4"
                        width="48"
                        height="48"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                        />
                        <feGaussianBlur
                            in="BackgroundImageFix"
                            stdDeviation="2"
                        />
                        <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur_0_322"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur_0_322"
                            result="shape"
                        />
                    </filter>
                </defs>
            </svg>
        );
    return (
        <div className="social-actions">
            {(fromContentDetail ? railCtaText : true) ? (
                <div className={`current-vdo__user-option share-option ${isCustomDropDown ? 'custom-btn' : ''}`}>
                    <AnalyticsButtonComponent
                        onMouseDown={(e) => {
                            handleShareOptions(prevState => !prevState);
                            sharedClick(e, title, windowsShareText, getCurrentUrl());
                        }}
                        onKeyUp={(e) => {
                            handleShareOptions(prevState => !prevState);
                            sharedClick(e, title, windowsShareText, getCurrentUrl());
                        }}
                        type="button"
                        style={buttonBorderCss}
                        className={buttonClassName || 'button button-link link link-theme-secondary d-flex justify-content-start align-items-center'}
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-label="Content Share Dropdown"
                    >
                        { fromContentDetail
                            ? cdpImage
                            : (
                                <>
                                    <ThumborImage
                                        src={IMAGE_PATHS.SHARE_ICON}
                                    />
                                    {!hideShareText ? (<span>{LANGUAGE.SHARE_TEXT}</span>) : null}
                                </>
                            )
                        }
                    </AnalyticsButtonComponent>
                    {!isNativeShareAvailable() ? (
                        <div
                            className={`dropdown-menu dropdown-custom dropdown-custom-primary scroll-share-dialogue ${showShareOptions ? 'd-block' : 'd-none'}`}
                            style={{ border: fromContentDetail ? 'none' : '1px' }}
                            aria-labelledby="dropdownMenuLink"
                            id="social-dropdown"
                        >
                            <div className="cdp-share-dropdown common-drop-down">
                                <a
                                    className="common-share-dropdown-button btn-atag"
                                    href={sendMail(windowsShareText, emailShareBody)}
                                >
                                    <ThumborImage
                                        src="/static/cdp-assets/cdp-email.svg"
                                    />
                                    Email
                                </a>
                                <AnalyticsButtonComponent
                                    className="common-share-dropdown-button"
                                    onClick={() => socialShare(facebookUrl)}
                                >
                                    <ThumborImage
                                        src="/static/cdp-assets/cdp-fb.svg"
                                    />
                                    Facebook

                                </AnalyticsButtonComponent>
                                <AnalyticsButtonComponent
                                    className="common-share-dropdown-button"
                                    onClick={() => socialShare(twitterUrl)}
                                >
                                    <ThumborImage
                                        src="/static/cdp-assets/cdp-twiter.svg"
                                    />
                                    Twitter

                                </AnalyticsButtonComponent>
                                <AnalyticsButtonComponent
                                    className="common-share-dropdown-button"
                                    onClick={() => copyLinkToClipboard(shareShortUrl || getCurrentUrl())}
                                >
                                    <ThumborImage
                                        src="/static/cdp-assets/cdp-link.svg"
                                    />
                                    Copy link

                                </AnalyticsButtonComponent>

                            </div>
                            {/* ) } */}
                        </div>
                    ) : null}
                </div>
            ) : cdpImage}
        </div>
    );
}

ContentShareComponent.propTypes = {
    title: PropTypes.string,
    socialShareInfo: PropTypes.shape({
        emailShareBody: PropTypes.string,
        facebookUrl: PropTypes.string,
        twitterUrl: PropTypes.string,
        whatsAppUrl: PropTypes.string,
        windowsShareText: PropTypes.string,
    }),
    //  shortUrl: PropTypes.string,
    buttonClassName: PropTypes.string,
    isCustomDropDown: PropTypes.bool,
    buttonBorderCss: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    hideShareText: PropTypes.bool,
    isMobile: PropTypes.bool,
    fromContentDetail: PropTypes.bool,
    railCtaText: PropTypes.string,
};

ContentShareComponent.defaultProps = {
    title: '',
    socialShareInfo: {
        emailShareBody: '',
        facebookUrl: '',
        twitterUrl: '',
        whatsAppUrl: '',
        windowsShareText: '',
    },
    // shortUrl: '',
    buttonClassName: '',
    isCustomDropDown: false,
    railCtaText: '',
    buttonBorderCss: null,
    hideShareText: false,
    isMobile: false,
    fromContentDetail: false,
};
