import lodashGet from 'lodash/get';
import { RoutingUtil, LanguageProviderUtil } from '@airtel-tv/utils';
import { toTitleCase } from '@airtel-tv/utils/GlobalUtil';

const TVSeasonNextEpBuilder = ({ contentDetails, contentCollection, episodesDetails, contentId }) => {
    const LANGUAGE = LanguageProviderUtil.getLanguage();

    let nextEpisode = null;
    let nextEpisodeUrl;
    let nextEpisodeDetails;

    const epDetails = episodesDetails || contentDetails.episodeRefs
    const curEpIndex = (epDetails && epDetails.findIndex(episode => episode.refId === contentId));

    if (curEpIndex === -1 || isNaN(curEpIndex) || curEpIndex >= epDetails.length) {
        return null;
    }

    nextEpisode = epDetails[curEpIndex + 1];

    const tvShowTitle = contentDetails.tvShowName;
    let nextEpTitle;
    let nextEpLinkTitle;
    if (nextEpisode) {
        nextEpTitle = `${toTitleCase(contentDetails.title)} ${LANGUAGE.EPISODE} ${nextEpisode.episodeNumber}`;
        nextEpLinkTitle = `${tvShowTitle} - ${nextEpTitle}`;
        nextEpisodeUrl = RoutingUtil.getTvEpisodeUrlWeb({
            // programType: tvShowDetails.programType,
            title: tvShowTitle,
            seasonName: contentDetails.title,
            episodeName: nextEpisode.name,
            contentId: nextEpisode.refId,
            episodeNumber: nextEpisode.episodeNumber,
        });

        nextEpisodeDetails = {
            details: {
                ...nextEpisode,
            },
            url: nextEpisodeUrl,
            nextEpTitle,
            nextEpLinkTitle,
        };
    }
    return nextEpisodeDetails;
};
export default TVSeasonNextEpBuilder;
