import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PlaybackContainer from '@airtel-feature/playback/PlaybackContainer';
// import PlaybackContainer from '../../playback/PlaybackContainer';
import DescriptionComponent from '../components/DescriptionComponent';
import CastComponent from '../components/CastComponent';
import { RAIL_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { PROGRAM_TYPES, ROLE_TYPES, RESIZE_OPTION } from '@airtel-tv/constants/GlobalConst';
import { changeMetaData } from '../../../../web/src/modules/notify/NotifyActions';
// import { changeMetaData } from '../../notify/NotifyActions';
import {
    getPageSeoMeta,
    getSocialShareInfo,
} from '@airtel-tv/utils/ContentMetaUtil';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { contentDetailsFetchAction } from '../actions/ContentDetailsAction';
import { LoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/LoaderComponent';
import { fetchAllChannels } from '../../../../web/src/modules/rail/actions/ChannelAction';
import { LanguageProviderUtil, getPosterUrlFromContentDetails, ContentImageProvider, BreadCrumbsUtil } from '@airtel-tv/utils';
import ChannelRelatedContentContainer from '../../../../web/src/modules/rail/containers/ChannelRelatedContentContainer';
import ErrorHandler from '../../../../web/src/modules/error-handler/ErrorHandler';
import { ERROR_CODES } from '@airtel-tv/constants';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
import { updatePageBreadCrumbs } from '@airtel-tv/ui-lib/molecules/breadcrumbs/actions/BreadCrumbsActions';
import MarkupFactory from '../../../../web/src/modules/watch-actions/factories/MarkupFactory';
import { getInfo } from '../builders/InfoBuilder';

class CatchupMovieDetailContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {};

        this.LANGUAGE = LanguageProviderUtil.getLanguage();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            contentIdFromParam,
            contentDetails,
        } = nextProps;

        if (
            contentIdFromParam !== prevState.contentIdFromParam
            || contentDetails !== prevState.contentDetails
        ) {
            return {
                contentIdFromParam,
                contentDetails,
            };
        }
        return null;
    }

    componentDidMount() {
        this.init();

        this.updateSeoMeta();
    }

    updateSeoMeta = () => {
        const {
            contentDetails,
            changeMetaDataDispatch,
            currentChannel,
            updatePageBreadCrumbsDispatch,
        } = this.props;

        if (contentDetails && contentDetails.id) {
            const {
                title, description, programType, languages, id,
            } = contentDetails;

            const metaLanguage = languages && languages[0] ? languages[0] : '';

            const headMetaData = getPageSeoMeta({
                programType,
                contentTitle: title,
                contentDescription: description,
                contentLanguage: metaLanguage,
            });
            const curPageLink = RoutingUtil.getLiveTvShowUrl({
                channelName: currentChannel && currentChannel.title,
                showName: title,
                contentId: id,
            });
            const breadCrumbsList = BreadCrumbsUtil.catchupMovieBreadCrumbs({
                contentDetails,
                curPageLink,
                currentChannel,
            });
            const watchActionCatalogues = MarkupFactory({
                contentDetails,
                baseUrl: this.deviceUtil.getBaseUrl(),
                breadCrumbsList,
            });
            changeMetaDataDispatch({
                headMetaData: {
                    ...headMetaData,
                    image: getPosterUrlFromContentDetails(contentDetails),
                    link: curPageLink,
                    watchActionCatalogues,
                },
            });
            updatePageBreadCrumbsDispatch({ breadCrumbsList });
        }
    }

    init = (prevProps = {}) => {
        const {
            contentIdFromParam,
            channels,
            fetchAllChannelsDispatch,
            contentDetails,
        } = this.props;

        if (contentDetails
            && contentDetails.programType === PROGRAM_TYPES.LIVETVSHOW
            && contentDetails.episodeRefs
            && contentDetails.episodeRefs.length < 1) {
            ErrorHandler({ code: ERROR_CODES.ATV014 });
            return;
        }

        if (prevProps.contentIdFromParam !== contentIdFromParam) {
            if (!channels || channels.length < 1) {
                fetchAllChannelsDispatch();
            }
        }
    }


    render() {
        const {
            contentDetails,
            currentChannel,
            currentUrl,
            allPlans,
        } = this.props;

        if (!contentDetails || !contentDetails.id) {
            return <LoaderComponent />;
        }

        const logoUrl = ContentImageProvider({
            imageMap: currentChannel && currentChannel.imgs,
            imgType: [
                CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                CONTENT_IMAGE_TYPES.LOGO,
            ],
        });

        const info = getInfo(contentDetails);

        let castList = [];
        if (
            contentDetails.credits
            && contentDetails.credits.length > 0
        ) {
            castList = contentDetails.credits.filter(element => element.roleType === ROLE_TYPES.ACTOR);
        }

        const socialShareInfo = getSocialShareInfo({
            programType: contentDetails.programType,
            showName: contentDetails.title,
            currentUrl,
            baseUrl: this.deviceUtil.getBaseUrl(),
        });

        return (
            <>
                <section className="current-vdo pt-2 pt-md-4 pt-sm-4">
                    <div className="container-fluid extended-rail-margin">
                        <div className="row ">
                            <PlaybackContainer 
                                contentDetails={contentDetails} 
                                playableId={contentDetails.id}
                            />
                            <DescriptionComponent
                                logo={logoUrl}
                                allPlans={allPlans}
                                title={contentDetails.title}
                                info={info}
                                description={contentDetails.description}
                                shortUrl={contentDetails.shortUrl}
                                channelName={currentChannel && currentChannel.title}
                                socialShareInfo={socialShareInfo}
                                contentDetails={contentDetails}
                                id={contentDetails.id}
                                isBot={this.deviceUtil.isBot()}
                                resizeOption={RESIZE_OPTION.TAB_LARGE}
                            />
                        </div>
                    </div>
                </section>

                {castList.length > 0 ? (
                    <div className="container-fluid extended-rail-margin">
                        <CastComponent casts={castList} />
                    </div>
                ) : null}

                <ChannelRelatedContentContainer
                    targetId={contentDetails.channelId}
                    programType={PROGRAM_TYPES.LIVETVSHOW}
                    railType={RAIL_TYPES.TVSHOW_LOGO_43_RAIL}
                    displayTitle={this.LANGUAGE.RECORDED_SHOWS_LABEL}
                />

                <ChannelRelatedContentContainer
                    targetId={contentDetails.channelId}
                    programType={PROGRAM_TYPES.LIVETVCHANNEL}
                    railType={RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE}
                    displayTitle={this.LANGUAGE.SIMILAR_CHANNELS}
                />

                <ChannelRelatedContentContainer
                    targetId={contentDetails.channelId}
                    programType={PROGRAM_TYPES.LIVETVMOVIE}
                    railType={RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL}
                    displayTitle={this.LANGUAGE.YOU_MAY_ALSO_LIKE}
                />

            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        match,
        contentDetails,
        allPlans,
    } = props;
    const { params: { contentId }, url: currentUrl } = match;

    const {
        channel,
    } = state;

    let currentChannel = null;

    const channels = channel && channel.channels;

    if (contentDetails && contentDetails.id) {
        currentChannel = channels.find(item => item.id === contentDetails.channelId);
    }

    const prop = {
        contentIdFromParam: contentId,
        contentDetails,
        channels,
        currentChannel,
        currentUrl,
        allPlans,
    };


    return prop;
};

CatchupMovieDetailContainer.defaultProps = {
    channels: null,
    currentChannel: null,
    currentUrl: '',
};

CatchupMovieDetailContainer.propTypes = {
    fetchAllChannelsDispatch: PropTypes.func.isRequired,
    changeMetaDataDispatch: PropTypes.func.isRequired,
    contentIdFromParam: PropTypes.string.isRequired,
    contentDetails: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    channels: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    currentChannel: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    currentUrl: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    updatePageBreadCrumbsDispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, {
    changeMetaDataDispatch: changeMetaData,
    contentDetailsFetchDispatch: contentDetailsFetchAction,
    fetchAllChannelsDispatch: fetchAllChannels,
    updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
})(withDeviceUtil(CatchupMovieDetailContainer)));
