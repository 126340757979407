/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { getLatestChromeBrowser, getPlaceholderFromLocal, onInstallClick } from '@airtel-tv/utils/GlobalUtil';
import { DOWNLOAD_APP_ACTIONS } from '@airtel-tv/constants/GlobalConst';
import withStyles from 'isomorphic-style-loader/withStyles';
import { hideModalComponentAction } from '../../../../../web/src/modules/modal-popup/ModalPopupActions';
import styles from './CannotPlayVideoPopUp.scss';

const CannotPlayVideoPopUp = (props) => {
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const {
        CDP_OK_GOT_IT, CDP_CAN_NOT_PLAY_TITLE, CDP_CAN_NOT_PLAY_MESSAGE, CDP_CHROME_UPDATE_MSG, CDP_CHROME_ERROR_MESSAGE, BROWSER_NOT_SUPPORTED, BUTTON_OPEN_IN_APP,
    } = LANGUAGE;

    const closePopUp = () => {
        const {
            hideModalComponentActionDispatch,
        } = props;
        hideModalComponentActionDispatch(
            false,
            null,
            null,
        );
    };

    const handleAppClick = () => {
        onInstallClick({
            clickItem: DOWNLOAD_APP_ACTIONS.COMMON_APP_DOWNLOAD,
            isPlayerError: true,
        });
    };

    const {
        buttonText = (CDP_OK_GOT_IT || 'Ok, got it'),
        // message = "This video can't be played due to some technical problem. Please try again later.",
        buttonAction,
        title = (CDP_CAN_NOT_PLAY_TITLE || 'Cannot Play Video'),
        closeAction,
        channelName,
        hidePrimaryCta,
    } = props;

    let message;
    let sizeExtend;
    let secondBtnText;
    if (props.message) {
        message = props.message;
        if (props.extendedMsg) {
            sizeExtend = props.extendedMsg;
            if (props.deviceUtil.getBrowserVersion() < getLatestChromeBrowser()) {
                message += `\n${CDP_CHROME_UPDATE_MSG || getPlaceholderFromLocal('CDP_CHROME_UPDATE_MSG')}`;
            }
        }
        sizeExtend = props.extendedMsg;
    }
    else if (props.deviceUtil.isMobile()) {
        sizeExtend = true;
        message = BROWSER_NOT_SUPPORTED;
        secondBtnText = BUTTON_OPEN_IN_APP;
    }
    else if (props.deviceUtil.isChrome()) {
        sizeExtend = true;
        message = CDP_CHROME_ERROR_MESSAGE || getPlaceholderFromLocal('CDP_CHROME_ERROR_MESSAGE');
        if (props.deviceUtil.getBrowserVersion() < getLatestChromeBrowser()) {
            message += `\n${CDP_CHROME_UPDATE_MSG}` || getPlaceholderFromLocal('CDP_CHROME_UPDATE_MSG');
        }
    }
    else {
        message = CDP_CAN_NOT_PLAY_MESSAGE || getPlaceholderFromLocal('CDP_CAN_NOT_PLAY_MESSAGE');
    }

    if (message.includes('{channelName}')) {
        message = message.replace(/{channelName}/g, channelName);
    }

    return (
        <div className="cdp-banner-popup">
            <div className={`cdp-banner-inner-pop ${sizeExtend ? 'pop-extend' : ''}`}>
                <svg
                    className="icon-error"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                        stroke="#F29090"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M16 10V17"
                        stroke="#F29090"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M16 23C16.8284 23 17.5 22.3284 17.5 21.5C17.5 20.6716 16.8284 20 16 20C15.1716 20 14.5 20.6716 14.5 21.5C14.5 22.3284 15.1716 23 16 23Z"
                        fill="#F29090"
                    />
                </svg>

                <button
                    className="error-popoup-close"
                    onClick={closeAction || closePopUp}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18.75 5.25L5.25 18.75"
                            stroke="#A3A7AE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18.75 18.75L5.25 5.25"
                            stroke="#A3A7AE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
                <p className="title">{title}</p>
                <p className="description">
                    {message}
                </p>
                { secondBtnText && (
                    <button
                        className="gotit-btn btm-100"
                        onClick={handleAppClick}
                    >
                        {secondBtnText}
                    </button>
                ) }
                {!hidePrimaryCta ? (
                    <button
                        className={sizeExtend ? 'gotit-btn gotit-extended' : 'gotit-btn'}
                        onClick={buttonAction || closePopUp}
                    >
                        {buttonText}
                    </button>
                ) : null }
            </div>
        </div>
    );
};

export default connect(null, {
    hideModalComponentActionDispatch: hideModalComponentAction,
})(withDeviceUtil(withStyles(styles)(CannotPlayVideoPopUp)));

CannotPlayVideoPopUp.defaultProps = {
    extendedMsg: false,
};
