import { Navigate } from 'react-router';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DateTime, RoutingUtil, LanguageProviderUtil, ContentImageProvider, getPosterUrlFromContentDetails, BreadCrumbsUtil, withDeviceUtil } from '@airtel-tv/utils';
import PlaybackContainer from '@airtel-feature/playback/PlaybackContainer';
import DescriptionComponent from '../components/DescriptionComponent';
import CastComponent from '../components/CastComponent';
import {
    PROGRAM_TYPES, ROLE_TYPES, INTERSECTION_OBSERVER_THRESHOLD, RESIZE_OPTION, LOCAL_TILE_TYPES, RAIL_TYPES, ERROR_CODES
} from '@airtel-tv/constants';
import { IntersectionObservableHOC } from '@airtel-tv/lib';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
import { changeMetaData } from '../../../../web/src/modules/notify/NotifyActions';
import { getPageSeoMeta, getSocialShareInfo } from '@airtel-tv/utils/ContentMetaUtil';
import { contentDetailsFetchAction } from '../actions/ContentDetailsAction';
import { LoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/LoaderComponent';
import { SmallLoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/SmallLoaderComponent';
import { fetchAllChannels } from '../../../../web/src/modules/rail/actions/ChannelAction';
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder';
import { getRail } from '../../../../web/src/modules/rail/factories/RailFactory';
import ChannelRelatedContentContainer from '../../../../web/src/modules/rail/containers/ChannelRelatedContentContainer';
import ErrorHandler from '../../../../web/src/modules/error-handler/ErrorHandler';
import { updatePageBreadCrumbs } from '@airtel-tv/ui-lib/molecules/breadcrumbs/actions/BreadCrumbsActions';
import MarkupFactory from '../../../../web/src/modules/watch-actions/factories/MarkupFactory';
import NextEpisodeFactory from '../factory/NextEpisodeFactory';
import { getInfo } from '../builders/InfoBuilder';


class CatchupTvShowDetailContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            isRelatedTvShowElementVisible: this.deviceUtil.isBot(),
        };

        this.LANGUAGE = LanguageProviderUtil.getLanguage();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            contentIdFromParam,
            contentDetails,
            selectedEpisodeId,
            episodesDetails,
            tvShowDetails,
            selectedTvShowId,
            episodeIdFromParams,
        } = nextProps;

        if (
            contentIdFromParam !== prevState.contentIdFromParam
            || contentDetails !== prevState.contentDetails
            || selectedEpisodeId !== prevState.selectedEpisodeId
            || episodesDetails !== prevState.episodesDetails
            || tvShowDetails !== prevState.tvShowDetails
            || selectedTvShowId !== prevState.selectedTvShowId
            || episodeIdFromParams !== prevState.episodeIdFromParams
        ) {
            return {
                contentIdFromParam,
                contentDetails,
                selectedEpisodeId,
                episodesDetails,
                tvShowDetails,
                selectedTvShowId,
                episodeIdFromParams,
            };
        }
        return null;
    }

    componentDidMount() {
        this.init();

        this.updateSeoMeta();
    }

    componentDidUpdate(prevProps = {}, prevState = {}) {
        this.init(prevProps);

        const {
            tvShowDetails,
            episodesDetails,
            contentIdFromParam,
            currentChannel,
            history,
            channelNameFromParams,
            episodeIdFromParams,
            pageProgramType,
        } = this.props;

        if (
            prevProps.contentIdFromParam === contentIdFromParam
            || prevProps.episodeIdFromParams !== episodeIdFromParams
        ) {
            if (tvShowDetails && tvShowDetails.error) {
                ErrorHandler({
                    error: tvShowDetails.error,
                    code: tvShowDetails.error.errorcode,
                });
            }
            else if (episodesDetails && episodesDetails.error) {
                if (ERROR_CODES.ATV014) {
                    const tvShowUrl = RoutingUtil.getLiveTvShowUrl({
                        programType: tvShowDetails.programType,
                        channelName: !currentChannel ? channelNameFromParams : currentChannel.title,
                        showName: tvShowDetails.title,
                        contentId: tvShowDetails.id,
                    });

                    // check to stop infinite loop of redirection
                    if (pageProgramType === PROGRAM_TYPES.EPISODE) {
                        history.push(tvShowUrl);
                    }
                    else {
                        history.push(RoutingUtil.get404Route());
                    }
                }
                else {
                    ErrorHandler({
                        error: episodesDetails.error,
                        code: episodesDetails.error.errorcode,
                    });
                }
            }
        }

        if (!lodashIsEqual(prevState.episodesDetails, episodesDetails)
            || !lodashIsEqual(prevState.tvShowDetails, tvShowDetails)) {
            this.updateSeoMeta();
        }
    }

    updateSeoMeta = () => {
        const {
            episodesDetails,
            tvShowDetails,

            changeMetaDataDispatch,
            pageProgramType,
            contentDetails,
            updatePageBreadCrumbsDispatch,
        } = this.props;

        if (episodesDetails && episodesDetails.id && tvShowDetails && tvShowDetails.id) {
            const {
                title, description, languages, airDate, episodeNum,
            } = episodesDetails;

            const { channelId } = tvShowDetails;

            // const formattedDate = moment(airDate).format('DD-MMMM-YYYY');
            const formattedDate = new DateTime(airDate)
                .format('DD-MMMM-YYYY');
            const { channels } = this.props;

            const currentChannel = channels.find(item => item.id === channelId) || '';

            const metaLanguage = languages && languages[0] ? languages[0] : '';

            const tvShowDetailsSeoMeta = {
                episodeNumber: episodeNum,
                channelId,
                channelName: currentChannel.title || '',
                episodeDate: formattedDate,
            };
            const headMetaData = getPageSeoMeta({
                programType: pageProgramType,
                contentTitle: title,
                contentDescription: description,
                contentLanguage: metaLanguage,
                tvShowDetails: tvShowDetailsSeoMeta,
            });

            const canonicalUrl = pageProgramType === PROGRAM_TYPES.EPISODE
                ? RoutingUtil.getLiveEpisodeShowUrl({
                    channelName: currentChannel.title,
                    showName: title,
                    episodeReleaseDT: formattedDate,
                    episodeNumber: episodeNum,
                    contentId: episodesDetails.id,
                    tvShowId: tvShowDetails.id,
                })
                : RoutingUtil.getLiveTvShowUrl({
                    channelName: currentChannel.title,
                    showName: title,
                    contentId: tvShowDetails.id,
                });
            let breadCrumbsList = [];

            if (pageProgramType === PROGRAM_TYPES.EPISODE) {
                const showPageLink = RoutingUtil.getLiveTvShowUrl({
                    channelName: currentChannel.title,
                    showName: title,
                    contentId: tvShowDetails.id,
                });
                breadCrumbsList = BreadCrumbsUtil.catchupEpisodePageBreadCrumbs({
                    tvShowDetails,
                    episodesDetails,
                    curPageLink: canonicalUrl,
                    currentChannel,
                    showPageLink,
                });
            }
            else {
                breadCrumbsList = BreadCrumbsUtil.catchupShowPageBreadCrumbs({
                    contentDetails,
                    curPageLink: canonicalUrl,
                    currentChannel,
                });
            }
            const watchActionCatalogues = MarkupFactory({
                contentDetails: {
                    programType: PROGRAM_TYPES.LIVETVSHOW,
                },
                baseUrl: this.deviceUtil.getBaseUrl(),
                breadCrumbsList,
            });
            changeMetaDataDispatch({
                headMetaData: {
                    ...headMetaData,
                    image: getPosterUrlFromContentDetails(episodesDetails),
                    link: canonicalUrl,
                    watchActionCatalogues,
                },
            });
            updatePageBreadCrumbsDispatch({ breadCrumbsList });
        }
    };

    getVideoMeta = () => {
        const { contentDetails, episodesDetails } = this.props;
        const {
            programType,
            title,
        } = contentDetails;
        const {
            airDate,
        } = episodesDetails;
        let videoTitle;
        const formattedDate = new DateTime(airDate)
            .format('dd DD MM');
        if (programType === PROGRAM_TYPES.LIVETVSHOW) {
            videoTitle = `${title}`;
        }
        else {
            videoTitle = `${episodesDetails.tile}`;
        }
        return {
            videoTitle,
            subTitle: formattedDate,
        };
    }

    init = (prevProps = {}) => {
        const {
            contentDetailsFetchDispatch,

            selectedEpisodeId,
            episodesDetails,

            tvShowDetails,
            selectedTvShowId,

            contentIdFromParam,

            channels,
            fetchAllChannelsDispatch,

            contentDetails,
            episodeIdFromParams,
        } = this.props;

        if (contentDetails
            && contentDetails.programType === PROGRAM_TYPES.LIVETVSHOW
            && contentDetails.episodeRefs
            && contentDetails.episodeRefs.length < 1) {
            ErrorHandler({ code: ERROR_CODES.ATV014 });
            return;
        }

        if (
            prevProps.contentIdFromParam !== contentIdFromParam
            || prevProps.episodeIdFromParams !== episodeIdFromParams
        ) {
            // fetch tv show if not found
            if (selectedTvShowId
                && (!tvShowDetails || selectedTvShowId !== tvShowDetails.id)
                && (!tvShowDetails || !tvShowDetails.fetching)
            ) {
                contentDetailsFetchDispatch(selectedTvShowId);
            }

            // fetch tv episode if not found
            if (selectedEpisodeId
                && (!episodesDetails || selectedEpisodeId !== episodesDetails.id)
                && (!episodesDetails || !episodesDetails.fetching)
            ) {
                contentDetailsFetchDispatch(selectedEpisodeId);
            }
            if (!channels || channels.length < 1) {
                fetchAllChannelsDispatch();
            }
        }
    };

    showRelatedTvShowElement = () => {
        setTimeout(() => {
            this.setState({
                isRelatedTvShowElementVisible: true,
            });
        }, 1000);
    }

    render() {
        const {
            tvShowDetails,
            contentDetails,
            episodesDetails,
            currentChannel,
            channels,
            selectedEpisodeId,
            pageProgramType,
            currentUrl,
            continueWatchingData,
            allPlans,
            nextEpisodeDetails,
        } = this.props;

        const { isRelatedTvShowElementVisible } = this.state;

        if (contentDetails.id in continueWatchingData && pageProgramType !== PROGRAM_TYPES.EPISODE && currentChannel) {
            const {
                title,
                id: contentId,
                seriesId: tvShowId,
                episodeNum,
                airDate,
            } = continueWatchingData[contentDetails.id].contentResponse;
            const formattedDate = new DateTime(airDate)
                .format('DD-MMMM-YYYY');

            const redirectContinueWatchingUrl = RoutingUtil.getLiveEpisodeShowUrl({
                channelName: currentChannel.title,
                showName: title,
                episodeReleaseDT: formattedDate,
                episodeNumber: episodeNum,
                contentId,
                tvShowId,
            });
            return <Navigate to={redirectContinueWatchingUrl} />;
        }

        const LANGUAGE = LanguageProviderUtil.getLanguage();

        if (!tvShowDetails || !tvShowDetails.id || !episodesDetails || !episodesDetails.id) {
            return <LoaderComponent />;
        }

        let catchUpRailElement = null;
        const { episodeRefs } = tvShowDetails;
        if (channels
            && channels.length > 0
            && episodeRefs
            && episodeRefs.length > 0
            && currentChannel
            && Object.keys(currentChannel).length > 0) {
            const tileItems = episodeRefs.map(item => ({
                ...item,
                id: item.refId,
                channelId: tvShowDetails.channelId,
                programType: PROGRAM_TYPES.CATCHUP_EPISODE,
                selected: item.refId === selectedEpisodeId,
                tvShowId: tvShowDetails.id,
            }));
            const channelId = lodashGet(tvShowDetails, 'channelId', '');

            const railProps = buildRail({
                items: tileItems,
                explicitTileType: LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE,
                explicitRailTitle: LANGUAGE.PREVIOUS_EPISODE_LABEL,
                more: false,
                channels,
                continueWatchingData,
                channelId,
            });
            catchUpRailElement = getRail({
                railType: railProps.railType,
                railProps,
            });
        }

        const descriptionContentDetails = pageProgramType === PROGRAM_TYPES.EPISODE ? episodesDetails : tvShowDetails;

        const logoUrl = ContentImageProvider({
            imageMap: currentChannel && currentChannel.imgs,
            imgType: [
                CONTENT_IMAGE_TYPES.LOGOS_TNPT,
                CONTENT_IMAGE_TYPES.LOGO,
            ],
        });

        let title = `${contentDetails.title}`;
        let castList = [];

        let contentInfo = {};
        if (pageProgramType === PROGRAM_TYPES.LIVETVSHOW) {
            contentInfo = getInfo(tvShowDetails);
        }
        else {
            contentInfo = getInfo(episodesDetails);
            if (episodesDetails.episodeNum) {
                title = `${contentDetails.title} - ${LANGUAGE.EPISODE} ${episodesDetails.episodeNum}`;
            }
        }
        const {
            channelId, credits,
        } = contentDetails;

        if (credits
            && credits.length > 0) {
            castList = credits.filter(person => person.roleType === ROLE_TYPES.ACTOR);
        }

        let socialShareInfo = {};
        if (currentChannel && currentChannel.title) {
            socialShareInfo = getSocialShareInfo({
                programType: pageProgramType,
                showName: contentDetails.title,
                channelName: currentChannel.title,
                airDate: episodesDetails.airDate,
                currentUrl,
                baseUrl: this.deviceUtil.getBaseUrl(),
            });
        }
        let liveChannelUrl = '';
        const channelTitle = lodashGet(currentChannel, 'title');
        if (channelTitle && channelId) {
            liveChannelUrl = RoutingUtil.getContentPlaybackUrlWeb({
                category: PROGRAM_TYPES.LIVETVCHANNEL,
                contentTitle: channelTitle,
                contentId: channelId,
            });
        }

        const loaderElem = <SmallLoaderComponent style={{ marginTop: '20px' }} />;

        const relatedTvShowElement = (
            isRelatedTvShowElementVisible ? (
                <ChannelRelatedContentContainer
                    targetId={contentDetails.channelId}
                    programType={PROGRAM_TYPES.LIVETVSHOW}
                    railType={RAIL_TYPES.TVSHOW_LOGO_43_RAIL}
                    displayTitle={this.LANGUAGE.RECORDED_SHOWS_LABEL}
                />
            ) : (
                <IntersectionObservableHOC
                    fetchMore={this.showRelatedTvShowElement}
                    hasMore={!isRelatedTvShowElementVisible}
                    loaderElem={loaderElem}
                    startPageNumber={1}
                    threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
                />
            )
        );

        const videoMeta = this.getVideoMeta();
        return (
            <>
                <section className="current-vdo pt-2 pt-md-4 pt-sm-4">
                    <div className="container-fluid extended-rail-margin">
                        <div className="row">
                            <PlaybackContainer
                                contentDetails={episodesDetails}
                                nextEpisodeDetails={nextEpisodeDetails}
                                videoMeta={videoMeta}
                            />
                            <DescriptionComponent
                                logo={logoUrl}
                                allPlans={allPlans}
                                title={title}
                                description={descriptionContentDetails.description}
                                info={contentInfo}
                                shortUrl={contentDetails.shortUrl}
                                channelName={currentChannel && currentChannel.title}
                                socialShareInfo={socialShareInfo}
                                liveChannelUrl={liveChannelUrl}
                                contentDetails={tvShowDetails}
                                id={contentDetails.id}
                                isBot={this.deviceUtil.isBot()}
                                resizeOption={RESIZE_OPTION.TAB_LARGE}
                            />
                        </div>
                    </div>
                </section>

                {castList.length > 0 ? (
                    <div className="container-fluid extended-rail-margin">
                        <CastComponent casts={castList} />
                    </div>
                ) : null}

                {catchUpRailElement}


                <ChannelRelatedContentContainer
                    targetId={contentDetails.channelId}
                    programType={PROGRAM_TYPES.LIVETVCHANNEL}
                    railType={RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE}
                    displayTitle={this.LANGUAGE.SIMILAR_CHANNELS}
                />

                {relatedTvShowElement}
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        match,
        contentDetails,
        allPlans,
    } = props;
    const {
        params: {
            contentId: contentIdFromParam,
            episodeId: episodeIdFromParams,
            contentTitle: channelNameFromParams,
        },
        url: currentUrl,
    } = match;

    let pageProgramType = null;

    const {
        contentDetails: contentCollection,
        channel,
        syncContent: { continueWatchingData },
    } = state;

    let selectedTvShowId;
    let tvShowDetails;

    let selectedEpisodeId;
    let episodesDetails;

    let currentChannel = null;

    const channels = channel && channel.channels;
    let nextEpisodeDetails = null;

    if (contentDetails && contentDetails.id) {
        const {
            programType,
        } = contentDetails;

        pageProgramType = programType;
        if (programType === PROGRAM_TYPES.LIVETVSHOW) {
            tvShowDetails = { ...contentDetails };
            selectedTvShowId = tvShowDetails.id;
            currentChannel = channels.find(item => item.id === tvShowDetails.channelId);
            if (episodeIdFromParams) {
                selectedEpisodeId = episodeIdFromParams;
                pageProgramType = PROGRAM_TYPES.EPISODE; // if episode id coming from url then its a EPISODE page
            }
            // select [0] episode details
            else if (lodashGet(tvShowDetails, 'episodeRefs', []).length > 0) {
                const [
                    selectedEpisode,
                ] = tvShowDetails.episodeRefs;
                selectedEpisodeId = selectedEpisode.refId;
            }

            // get episode data from reducer
            if (selectedEpisodeId) {
                episodesDetails = contentCollection[selectedEpisodeId];
            }
            nextEpisodeDetails = NextEpisodeFactory({
                contentDetails,
                episodesDetails,
                channel: currentChannel,
            });
        }
        else if (programType === PROGRAM_TYPES.EPISODE) {
            episodesDetails = { ...contentDetails };
            selectedEpisodeId = episodesDetails.id;
            selectedTvShowId = episodesDetails.seriesId;
            selectedTvShowId = contentCollection[selectedTvShowId];
            if (tvShowDetails && Object.keys(tvShowDetails).length > 0) {
                currentChannel = channels.find(item => item.id === tvShowDetails.channelId);
            }
        }
    }

    const prop = {
        contentIdFromParam,
        contentDetails,

        selectedEpisodeId,
        episodesDetails,

        tvShowDetails,
        selectedTvShowId,

        channels,
        currentChannel,

        channelNameFromParams,
        episodeIdFromParams,
        pageProgramType, // specify if it is a tv show page or a episode page
        currentUrl,
        continueWatchingData,
        nextEpisodeDetails,
        allPlans,
    };

    return prop;
};

CatchupTvShowDetailContainer.defaultProps = {
    tvShowDetails: null,
    episodesDetails: null,
    channels: null,
    currentChannel: null,
    selectedEpisodeId: null,
    selectedTvShowId: null,
    pageProgramType: null,
    episodeIdFromParams: null,
    currentUrl: '',
    nextEpisodeDetails: null,
};

CatchupTvShowDetailContainer.propTypes = {
    fetchAllChannelsDispatch: PropTypes.func.isRequired,
    contentDetailsFetchDispatch: PropTypes.func.isRequired,
    changeMetaDataDispatch: PropTypes.func.isRequired,
    contentIdFromParam: PropTypes.string.isRequired,
    selectedEpisodeId: PropTypes.string,
    selectedTvShowId: PropTypes.string,
    contentDetails: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    tvShowDetails: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    episodesDetails: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    channels: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    currentChannel: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    channelNameFromParams: PropTypes.string.isRequired,
    pageProgramType: PropTypes.string,
    episodeIdFromParams: PropTypes.string,
    currentUrl: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    // eslint-disable-next-line react/forbid-prop-types
    continueWatchingData: PropTypes.object.isRequired,
    updatePageBreadCrumbsDispatch: PropTypes.func.isRequired,
    nextEpisodeDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default withRouter(connect(mapStateToProps, {
    changeMetaDataDispatch: changeMetaData,
    contentDetailsFetchDispatch: contentDetailsFetchAction,
    fetchAllChannelsDispatch: fetchAllChannels,
    updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
})(withDeviceUtil(CatchupTvShowDetailContainer)));
