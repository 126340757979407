/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { CDP_EVENT_CONST, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import EventType from '@airtel-tv/analytics/EventType';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';

class PillComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPreviousArrow: false,
            showNextArrow: true,
        };
        this.showPreviousArrow  = false;
        this.showNextArrow = true;
        this.carouselElementRef = React.createRef();
        this.carouselElementScrollRef = React.createRef();
        this.carouselElementRefCurrent = null;
        this.railInnerWidth = 0;
        this.pillsWidth = 114 + 42 + 12 + 2 ;
        this.scrollLeft = 0;
    }

    componentDidMount() {
        this.next({ scrollEvent: true });
        // this.carouselElementRef.current.addEventListener('scroll', () => {
        //     this.next({ scrollEvent: true });
        // });
        this.fetchNextTab();
    }
    
    componentDidUpdate(prevProps) {
        const { currentTab, tabs } = this.props;
        if (prevProps.currentTab !== currentTab || ((prevProps.seasonId !== this.props.seasonId) && prevProps.seasonId && this.props.seasonId)) {
            this.fetchNextTab();
        }
    }
    
    fetchNextTab = () => {
        const { currentTab, tabs, seasonId, fetchTabs, cleanNextTabDispatch } = this.props;
        const nextTab = (tabs && tabs.findIndex(tab => tab === currentTab) + 1) || -1;
        const previousTab = (tabs && tabs.findIndex(tab => tab === currentTab) - 1);
        if (nextTab >= 0) {
            if (nextTab < tabs.length) {
                fetchTabs(seasonId, tabs[nextTab], true, tabs[previousTab]);
            } else {
                cleanNextTabDispatch();
            }
        }
    }

    next = ({isNext =false, scrollEvent =false}) => {
        if (!this.carouselElementRefCurrent) {
            this.carouselElementRefCurrent = this.carouselElementRef.current;
        }
        const scrolledElement = this.carouselElementRefCurrent;
        this.railInnerWidth =  this.carouselElementScrollRef.current.clientWidth;
        if(isNext){
            this.scrollLeft  += this.pillsWidth;
        }
        else if(!scrollEvent){
            this.scrollLeft -= this.pillsWidth;
        }
        scrolledElement.scrollLeft = this.scrollLeft;
        const maxScroll = this.railInnerWidth - scrolledElement.clientWidth;
        const isElementFullyScrolled = maxScroll <=  this.scrollLeft;
        this.showPreviousArrow = this.scrollLeft > 0;
        this.showNextArrow = !isElementFullyScrolled;
        this.setState({ showNextArrow : this.showNextArrow, showPreviousArrow: this.showPreviousArrow});
    };

    tabClickEvent = () => {
        let { contentDetails, contentDetails: { contentId}, sourceName, packageId } = this.props;
        contentDetails = contentDetails[contentId];
        const { tvShowName, title, cpId, id, programType } = contentDetails;

        const meta = {
            action: CDP_EVENT_CONST.EPISODE_TAB_CLICK,
            source_name: sourceName,
            content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
            package_id: packageId,
        }
        contentDetailsEvent(meta, EventType.EPISODE_TAB_CLICK);
    }
    render() {
        const {
            tabs, currentTab, seasonId, fetchTabs,
        } = this.props;

        return (
            <div className="pills-scroll-cdp">
                { this.showPreviousArrow  && (
                    <AnalyticsButtonComponent
                        className="cdp-pill-btn left"
                        onClick={() => this.next({isNext: false})}
                        aria-label="Scroll Pills Left"
                    >
                        <svg
                            width="14"
                            height="26"
                            viewBox="0 0 14 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.42676 24.4284L11.5696 12.9999L2.42676 1.57129"
                                stroke="#E8EAED"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </AnalyticsButtonComponent>
                ) }
                <div
                    className="cdp-pill-wrapper"
                    ref={this.carouselElementRef}

                >
                    <div
                        className="cdp-pill-wrapper-scroll"
                        ref={this.carouselElementScrollRef}
                    >
                        { tabs.map((tab, index) => (
                            <AnalyticsButtonComponent
                                className={`epi-pills ${currentTab === tab ? ' active' : ''}`}
                                onClick={() => {
                                    index === (tabs.length -1) ?
                                        fetchTabs(seasonId,tab, false, tabs[(tabs && tabs.findIndex(t => t === tab) - 1)])
                                    :   fetchTabs(seasonId,tab,)
                                    this.tabClickEvent();
                                }}
                                key={index}
                            >
                                Ep.
                                {' '}
                                { tab }
                            </AnalyticsButtonComponent>
                        )) }
                    </div>
                    { this.showNextArrow  && (
                        <AnalyticsButtonComponent
                            className="cdp-pill-btn right"
                            onClick={() => this.next({isNext: true})}
                            aria-label="Scroll Pills Right"
                        >
                            <svg
                                width="14"
                                height="26"
                                viewBox="0 0 14 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.42676 24.4284L11.5696 12.9999L2.42676 1.57129"
                                    stroke="#E8EAED"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </AnalyticsButtonComponent>
                    ) }
                </div>
            </div>

        );
    }
}

PillComponent.propTypes = {
    tabs: PropTypes.array,
    currentTab: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    fetchTabs: PropTypes.func.isRequired,
    packageId: PropTypes.string,
};

PillComponent.defaultProps = {
    tabs: [],
    packageId: '',
};


export default PillComponent;
