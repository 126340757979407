import { PropTypes } from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router';
import { PROGRAM_TYPES } from '@airtel-tv/constants';
import VodDetailContainer from '../containers/VodDetailContainer';
import LiveDetailContainer from '../containers/LiveDetailContainer';
import {RoutingUtil} from '@airtel-tv/utils';
import SeriesDetailContainer from '../containers/SeriesDetailContainer';
import CatchupTvShowDetailContainer from '../containers/CatchupTvShowDetailContainer';
import CatchupMovieDetailContainer from '../containers/CatchupMovieDetailContainer';

const getDetailPage = ({ detailProps }) => {
    const { contentDetails } = detailProps;
    const { programType, channelId } = contentDetails;

    switch (programType) {
        case PROGRAM_TYPES.LIVETVMOVIE:
            return <CatchupMovieDetailContainer {...detailProps} />;

        case PROGRAM_TYPES.LIVETVSHOW:
        case !!channelId && PROGRAM_TYPES.EPISODE:
            return <CatchupTvShowDetailContainer {...detailProps} />;
        case PROGRAM_TYPES.MOVIE:
        case PROGRAM_TYPES.VIDEO:
        case PROGRAM_TYPES.OTHER:
        case PROGRAM_TYPES.PROMO:
            return <VodDetailContainer {...detailProps} />;
        case PROGRAM_TYPES.LIVETVCHANNEL:
            // case PROGRAM_TYPES.LIVETVSHOW:
            // case PROGRAM_TYPES.EPISODE:
            return <LiveDetailContainer {...detailProps} />;
        case PROGRAM_TYPES.TVSHOW:
        case PROGRAM_TYPES.SEASON:
        case PROGRAM_TYPES.EPISODE:
            // case PROGRAM_TYPES.LIVETVSHOW:
            // case PROGRAM_TYPES.EPISODE:
            return <SeriesDetailContainer {...detailProps} />;
        default:
            return (
                <Navigate
                    from={RoutingUtil.getHomePage()}
                    to={RoutingUtil.get404Route()}
                />
            );
    }
};

getDetailPage.propTypes = {
    detailProps: PropTypes.shape({
        contentDetails: PropTypes.shape({
            programType: PropTypes.string.isRequired,
            channelId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default getDetailPage;
