import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LinkComponent } from '@airtel-tv/ui-lib/atoms/link/LinkComponent';
import { RoutingUtil, LanguageProviderUtil } from '@airtel-tv/utils';
import EpisodeListComponent from './EpisodeListComponent';
import { calculateProgressPercentage } from '@airtel-tv/utils/GlobalUtil';

export default class SeasonSelectorComponent extends Component {
    constructor(props) {
        super(props);
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
    }

    shouldComponentUpdate(nextProps) {
        // if new season details are not found then stay intact and keep showing previous details
        return nextProps.seasonDetails && nextProps.seasonDetails.id;
    }

    getEpisodeLists() {
        const { seasons, seasonDetails, title, programType } = this.props;

        return seasons.map(season => (
            <li key={season.seasonId}>
                <LinkComponent
                    to={RoutingUtil.getTvEpisodeUrlWeb({
                        programType,
                        title,
                        episodeNumber: season.firstEpsRef && season.firstEpsRef.episodeNumber,
                        episodeName: title,
                        seasonName: season.name,
                        contentId: season.firstEpsRef.refId,
                    })}
                    className={`${seasonDetails.id !== season.seasonId ? 'primary-cta inactive' : 'primary-cta'}`}
                    title={title}
                >
                    {this.LANGUAGE.SEASON}
                    {' '}
                    {season.seasonNumber}
                </LinkComponent>
            </li>
        ));
    }

    toggleMobileDescription = (e) => {
        const element = e.currentTarget;
        element.classList.toggle('active');
        const content = element.nextElementSibling;
        if (content.style.display === 'block') {
            content.style.display = 'none';
        }
        else {
            content.style.display = 'block';
        }
    };

    getSelectedSeasonNumber = () => {
        const { seasons, seasonDetails } = this.props;

        if (!seasonDetails || !seasonDetails.id) {
            return null;
        }

        return seasons.find(season => seasonDetails.id === season.seasonId);
    };

    onSeasonChangeClicked = (e) => {
        const { onSeasonChange } = this.props;
        const seasonId = e ? e.target.getAttribute('data-season-id') : null;
        onSeasonChange(seasonId);
    };

    episodesTiles = () => {
        const {
            title, programType, seasonDetails, contentId, continueWatchingData,
        } = this.props;
        return seasonDetails.episodeRefs.map((episode) => {
            const episodeUrl = RoutingUtil.getTvEpisodeUrlWeb({
                programType,
                title,
                seasonName: seasonDetails.title,
                episodeName: episode.name,
                contentId: episode.refId,
                episodeNumber: episode.episodeNumber,
            });

            const isPlaying = contentId === episode.refId;
            const progressPercentage = calculateProgressPercentage({
                continueWatchingData,
                id: episode.refId,
            });
            const showProgressBar = !!progressPercentage;
            return (
                <EpisodeListComponent
                    key={episode.refId}
                    episodeUrl={episodeUrl}
                    episode={episode}
                    isPlaying={isPlaying}
                    contentDetails={seasonDetails}
                    progressPercentage={progressPercentage}
                    showProgressBar={showProgressBar}
                />
            );
        });
    };

    showFullDescription = (e) => {
        e.currentTarget.firstChild.classList.toggle('d-none');
        e.currentTarget.lastChild.classList.toggle('d-none');
    };

    render() {
        const selectedSeason = this.getSelectedSeasonNumber();
        const { seasonDetails } = this.props;

        if (!selectedSeason || !seasonDetails || !seasonDetails.id) {
            return null;
        }

        return (
            <section className="container-fluid component-top-padding extended-rail-margin">
                <div className="season-wrapper">
                    <div className="title title-with-cta">
                        <h2>{this.LANGUAGE.SEASON_SELECTOR_LABEL}</h2>
                    </div>
                    <ul className="season-list">{this.getEpisodeLists()}</ul>
                </div>

                <div className="grid-wrapper d-flex flex-wrap justify-content-start">
                    {this.episodesTiles()}
                </div>
            </section>
        );
    }
}

SeasonSelectorComponent.defaultProps = {
    seasons: [],
    contentId: '',
    seasonDetails: {},
    onSeasonChange: () => { },
    continueWatchingData: {},
};

SeasonSelectorComponent.propTypes = {
    title: PropTypes.string.isRequired,
    programType: PropTypes.string.isRequired,
    seasons: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    seasonDetails: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    contentId: PropTypes.string,
    onSeasonChange: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    continueWatchingData: PropTypes.object,
};
