import React from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ContentShareComponent from './ContentShareComponent';
import {  RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import {  LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { filterSubscribedPlans, filterXtreamPremium } from '@airtel-tv/utils/GlobalUtil';
import { DESCRIPTION_MAX_LENGTH } from '@airtel-tv/constants/GlobalConst';
import { LOGIN_SOURCE, SUBSCRIPTION_SOURCE } from '@airtel-tv/constants/EventsConst';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { DescriptionWatchListButtonComponent } from '@airtel-tv/ui-lib/molecules/DescriptionWatchListButtonComponent/DescriptionWatchListButtonComponent';
import { ResizeHOC } from '@airtel-tv/lib/hoc/ResizeHOC';
import { withWatchlistDataHOC } from '@airtel-tv/lib/hoc/withWatchlistDataHOC';
import MultiAudioLangComponent from './MultiAudioLangComponent';
import SubscriptionContainer from '../containers/subscription/SubscriptionContainer';
import { triggerLogin } from '../../../../web/src/modules/auth/AuthAction';
// import { triggerLogin } from '../../auth/AuthAction';
// import { filterSubscribedPlans, filterXtreamPremium } from '../../../utilities/CommonUtil';

const DescriptionComponent = (props) => {
    
    const {
        logo,
        info,
        // rating,
        description = '',
        title,
        shortUrl,
        channelName,
        directors,
        isLive,
        seasonDetails,
        episodeNum,
        socialShareInfo,
        liveChannelUrl,
        watchListToggleButtonClick,
        inWatchList,
        contentDetails,
        allPlans,
        isBot,
        multiAudio: {
            parentLang,
            languageMap: audioLanguages,
        },
        languageCodesMap,
        isMaxTabWidth,
        // history,
        navigate
    } = props;

    const LANGUAGE = LanguageProviderUtil.getLanguage();
    let shortDescription = isBot ? description : description.slice(0, DESCRIPTION_MAX_LENGTH);
    const showViewMore = shortDescription.length < description.length && !isBot;
    shortDescription += shortDescription.length < description.length && !isBot ? '... ' : '';
    const toggleDesc = (e) => {
        const element = e.currentTarget;

        element.parentNode.parentNode.parentNode.parentNode.classList.toggle('full-show-description');
        if (element.innerText === LANGUAGE.LABEL_VIEW_MORE) {
            element.previousSibling.innerHTML = description;
            // element.innerText = LANGUAGE.LABEL_VIEW_LESS;
            element.innerText = '';
        }
        else {
            element.previousSibling.innerHTML = `${shortDescription}`;
            element.innerText = LANGUAGE.LABEL_VIEW_MORE;
        }
    };
    const multiAudioLanguagePills = [];
    if (audioLanguages) {
        const { languages: contentLang } = contentDetails;
        const curLang = contentLang ? contentLang[0] : null;
        const parentLangElem = (
            <MultiAudioLangComponent
                contentDetails={contentDetails}
                languageCodesMap={languageCodesMap}
                title={title}
                audioLanguages={audioLanguages}
                parentLang={parentLang}
                langCode={parentLang}
                key={parentLang}
            />
        );
        multiAudioLanguagePills.push(parentLangElem);
        if (curLang !== parentLang) {
            const curLangElem = (
                <MultiAudioLangComponent
                    contentDetails={contentDetails}
                    languageCodesMap={languageCodesMap}
                    title={title}
                    audioLanguages={audioLanguages}
                    parentLang={parentLang}
                    langCode={curLang}
                    key={curLang}
                />
            );
            multiAudioLanguagePills.push(curLangElem);
        }


        Object.keys(audioLanguages).forEach((langCode) => {
            if (langCode === curLang || langCode === parentLang) {
                return;
            }
            const langPill = (
                <MultiAudioLangComponent
                    contentDetails={contentDetails}
                    languageCodesMap={languageCodesMap}
                    title={title}
                    audioLanguages={audioLanguages}
                    parentLang={parentLang}
                    langCode={langCode}
                    key={langCode}
                />
            );
            multiAudioLanguagePills.push(langPill);
        });
    }

    const channelNameElement = !liveChannelUrl ? (
        <>
            {' '}
            {channelName}
            {' '}
        </>
    )
        : (
            <Link to={liveChannelUrl}>
                {channelName}
            </Link>
        );

    const channelSubsDetailElem = !isLive && !liveChannelUrl ? (
        <SubscriptionContainer
            contentDetails={contentDetails}
            isMaxTabWidth={isMaxTabWidth}
        />
    ) : null;
    // const { allPlans } = props;
    const subscribedPlans = filterSubscribedPlans(allPlans && allPlans.subscribedPlans);
    const xtreamPremiumPlan = filterXtreamPremium(allPlans && allPlans.subscribedPlans);

    let logoElem = null;
    if (liveChannelUrl) {
        logoElem = (
            <Link to={liveChannelUrl}>
                <div className="current-vdo__channel-logo float-left">
                    <ThumborImage
                        src={logo}
                        alt=""
                    />
                </div>
            </Link>
        );
    }
    else if (isLive) {
        logoElem = (
            <div className="current-vdo__channel-logo float-left">
                <ThumborImage
                    src={logo}
                    alt=""
                    className={channelName}
                />
            </div>
        );
    }
    const onUnlockXPButton = () => {
        const { isAuthenticated, triggerLoginDispatch } = props;
        if (!isAuthenticated) {
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.SUBSCRIBE_BUTTON,
            });
        }
        else {
            navigate({
                pathname: RoutingUtil.getSubscriptionPage(),
                state: {
                    contentDetails,
                    origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                },
            });
        }
    };

    return (
        <>
            <div className="col-xl-4">
                <div className="current-vdo__info position-relative">
                    <div className="header-section">
                        {
                            logoElem ? (
                                <div className="w-100 d-inline-block">
                                    {logoElem}
                                </div>
                            ) : null
                        }

                        {!channelName ? null
                            : <h2 className="current-vdo__heading mt-3">{channelNameElement}</h2>}


                        <div className="current-vdo__title">
                            <h1 className="description-box-title">{title}</h1>
                            {/* {!xtreamPremiumPlan.length > 0 || !subscribedPlans.length > 0
                                ? (
                                    <button
                                        onClick={onUnlockXPButton}
                                        className="unlock-btn-xtrm"
                                    >
                                        UNLOCK XSTREAM PREMIUM
                                    </button>
                                ) : ''} */}
                        </div>

                        {!seasonDetails ? null : (
                            <ul className="current-vdo__details mt-1">
                                <li>{seasonDetails}</li>
                            </ul>
                        )}

                        {!episodeNum ? null : (
                            <ul className="current-vdo__details mt-1">
                                <li>{`Episode ${episodeNum}`}</li>
                            </ul>
                        )}

                        <ul className="current-vdo__details year-language mt-1">
                            <li className="age-year-lang-wrapper">
                                {info}
                            </li>
                        </ul>
                    </div>

                    <div className="mid-section">
                        {directors && Object.keys(directors).length > 1
                            ? (
                                <ul className="current-vdo__details director mt-3">
                                    <li>{directors}</li>
                                </ul>
                            )
                            : null}
                        {
                            audioLanguages
                                ? (
                                    <>
                                        <div className="audio-lang-list-title">{LANGUAGE.MULTI_AUDIO_HEADING}</div>
                                        <div>
                                            <ul className="audio-lang-list">
                                                {multiAudioLanguagePills}
                                            </ul>
                                        </div>
                                    </>
                                )
                                : null
                        }
                        {!description ? null : (
                            <div className="w-100 d-inline-block mt-3 description-wrapper">
                                <div className="description-heading">{LANGUAGE.DESCRIPTION_HEADING}</div>
                                <div className="video-description mt-1">
                                    <p>
                                        <span>{`${shortDescription}`}</span>
                                        {!showViewMore ? null : (
                                            <button
                                                type="button"
                                                onClick={toggleDesc}
                                            >
                                                {LANGUAGE.LABEL_VIEW_MORE}
                                            </button>
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}

                        <div className={`current-vdo__user-options cta-btn-section w-100${isLive ? ' live-options' : ''}`}>
                            <ContentShareComponent
                                title={title}
                                shortUrl={shortUrl}
                                socialShareInfo={socialShareInfo}
                            />
                            {!isLive
                                ? (
                                    <div className="video-description-watchlist">
                                        <DescriptionWatchListButtonComponent
                                            contentDetails={contentDetails}
                                            inWatchList={inWatchList}
                                            watchListToggleButtonClick={watchListToggleButtonClick}
                                        />
                                    </div>
                                )
                                : null
                            }
                        </div>
                    </div>
                    {
                        !isMaxTabWidth ? channelSubsDetailElem : null
                    }
                </div>
            </div>
            {
                isMaxTabWidth ? channelSubsDetailElem : null
            }
        </>
    );
};

DescriptionComponent.defaultProps = {
    // rating: '',
    channelName: '',
    directors: {},
    isLive: false,
    seasonDetails: '',
    episodeNum: 0,
    info: '',
    description: '',
    shortUrl: '',
    logo: '',
    socialShareInfo: {
        emailShareBody: '',
        facebookUrl: '',
        twitterUrl: '',
        whatsAppUrl: '',
        windowsShareText: '',
    },
    liveChannelUrl: '',
    contentDetails: {},
    isBot: true,
    multiAudio: {
        parentLang: '',
        languageMap: null,
    },
    languageCodesMap: null,
};

DescriptionComponent.propTypes = {
    logo: PropTypes.string, // eslint-disable-next-line react/forbid-prop-types
    info: PropTypes.array,
    // rating: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    shortUrl: PropTypes.string,
    channelName: PropTypes.string,
    directors: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
    isLive: PropTypes.bool,
    seasonDetails: PropTypes.string,
    episodeNum: PropTypes.number,
    isAuthenticated: PropTypes.bool.isRequired,
    triggerLoginDispatch: PropTypes.func.isRequired,
    socialShareInfo: PropTypes.shape({
        emailShareBody: PropTypes.string,
        facebookUrl: PropTypes.string,
        twitterUrl: PropTypes.string,
        whatsAppUrl: PropTypes.string,
        windowsShareText: PropTypes.string,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    liveChannelUrl: PropTypes.string,
    watchListToggleButtonClick: PropTypes.func.isRequired,
    inWatchList: PropTypes.bool.isRequired,
    contentDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
    isBot: PropTypes.bool,
    multiAudio: PropTypes.shape({
        parentLang: PropTypes.string,
        languageMap: PropTypes.object,
    }),
    languageCodesMap: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
    isMaxTabWidth: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
    const {
        authConfig: { isAuthenticated },
    } = state;

    return {
        isAuthenticated,
    };
};
export default withWatchlistDataHOC(connect(mapStateToProps,
    {
        triggerLoginDispatch: triggerLogin,
    })(ResizeHOC(DescriptionComponent)));
