import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { getElementById } from '@airtel-tv/utils/WindowUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';
import styles from './DesktopBannerComponent.scss';

const DesktopBannerBottomComponent = (props) => {
    const { VIEW_INFO} = props;


    const resetAnimationOnViewInfo = () => {
        const cdpBanner = getElementById('banner-details-container');
        if (cdpBanner) {
            cdpBanner.classList.replace('animation', 'animation-revert');
        }
    };

    return <>
        <AnalyticsButtonComponent
            aria-label={VIEW_INFO}
            className="view-info-button button-size-effect"
            onClick={() => {
                resetAnimationOnViewInfo();
            }}
        >
            {VIEW_INFO}
        </AnalyticsButtonComponent>
    </>
}


export default withStyles(styles)(DesktopBannerBottomComponent);