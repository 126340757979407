/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import { ResizeHOC } from '@airtel-tv/lib/hoc/ResizeHOC';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
// import { showModalComponentAction } from '../../modal-popup/ModalPopupActions';
import {
    CDP_EVENT_CONST, ELEMENT_ID, MODAL_POPUP, PROGRAM_TYPES,
} from '@airtel-tv/constants/GlobalConst';
import { withWatchlistDataHOC } from '@airtel-tv/lib/hoc/withWatchlistDataHOC';
// import { triggerLogin } from '../../auth/AuthAction';
// import { showPlayback } from '../../notify/NotifyActions';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { getPrefferedPartnerList, getSourceName } from '@airtel-tv/utils/GlobalUtil';
import EventType from '@airtel-tv/analytics/EventType';
import { SubscriptionUtil } from '@airtel-tv/utils/SubscriptionUtil';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '@airtel-tv/constants';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import ReplayIconSVGComponent from '@airtel-tv/ui-lib/svg/ReplayIconSVGComponent';
import ToolTip from '@airtel-tv/ui-lib/atoms/ToolTip/ToolTip';
import { contentDetailsFetchAction } from '../../../content-details/actions/ContentDetailsAction';
import { showPlayback } from '../../../../../web/src/modules/notify/NotifyActions';
import { triggerLogin } from '../../../../../web/src/modules/auth/AuthAction';
import { showModalComponentAction } from '../../../../../web/src/modules/modal-popup/ModalPopupActions';
import styles from './../BannerComponent/DesktopBannerComponent.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';



class BannerWidgetComponent extends Component {
    constructor(props) {
        super(props);
        const { deviceUtil } = this.props;
        this.isWeb = deviceUtil?.isWeb();

        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.state = {
            railCtaText: '',
            railCtaType: '',
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const { PLAY_CTA = 'PLAY', RESUME_CTA_WITH_EPISODENUM = 'Resume: S{episodeSeasonNum} E{episodeNum}', RESUME_CTA = 'Resume' } = LANGUAGE;
        let {
            railCtaText, continueWatchingData, contentDetails, railCtaType, match: { path, params }, subscriptionDataById, cpDetailsById, contentCollection, matchRoute, location,
        } = nextProps;
        const cpId = lodashGet(contentDetails, 'cpId', '');
        const channelId = SubscriptionUtil.getChannelId({
            cpDetailsById,
            cpId,
        });
        const channelDetails = lodashGet(cpDetailsById, `[${channelId}]`, null);
        const subscriptionDetails = lodashGet(subscriptionDataById, `[${channelId}]`, null);
        const targetHierarchy = lodashGet(channelDetails, 'targetHierarchy', 0);
        const isSubscribed = SubscriptionUtil.isSubscribed(subscriptionDetails, targetHierarchy);
        if (!prevState
            || prevState.railCtaText !== railCtaText
            || prevState.railCtaType !== railCtaType
            || subscriptionDetails !== prevState.subscriptionDetails
            || isSubscribed !== prevState.isSubscribed) {
            const contentId = (
                contentDetails.programType === PROGRAM_TYPES.TVSHOW
                || contentDetails.programType === PROGRAM_TYPES.MOVIE || contentDetails.programType === PROGRAM_TYPES.VIDEO
                    ? contentDetails.id
                    : contentDetails.seriesId
            );
            const { episodeId } = params;
            const isEpisodeRoute = matchRoute(ROUTE_PATHS.TV_EPISODE_PLAYBACK, location.pathname) !== null;
            if (isSubscribed && railCtaType === PLAY_CTA && continueWatchingData && continueWatchingData[contentId] && Object.keys(continueWatchingData[contentId]).length > 0) {
                const { contentResponse: { episodeSeasonNum, episodeNum, playableId } } = continueWatchingData[contentId];
                if (isEpisodeRoute || matchRoute(ROUTE_PATHS.PLAYBACK, location.pathname) !== null) {
                    railCtaText = episodeSeasonNum && episodeNum ? RESUME_CTA_WITH_EPISODENUM.replace(/{episodeSeasonNum}/g, episodeSeasonNum)
                        .replace(/{episodeNum}/g, episodeNum) : RESUME_CTA;
                }
                else if (isEpisodeRoute && ((playableId === episodeId) || (playableId === contentCollection?.[episodeId]?.playableId))) {
                    railCtaText = RESUME_CTA;
                }
            }
            return {
                railCtaText,
                subscriptionDetails,
                isSubscribed,
                contentId,
            };
        }

        return null;
    }

    onTrailerClick = () => {
        const {
            showModalComponentActionDispatch, contentDetails, history, contentDetails: {
                id,
                title,
                programType,
                tvShowName, cpId, trailers,
            },
            location,
        } = this.props;
        if (trailers.length === 1) {
            const sourceName = getSourceName(location.pathname);
            const meta = {
                action: CDP_EVENT_CONST.TRAILER_VIEW,
                source_name: sourceName,
                content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
                content_id: id,
                cp_name: cpId,
            };

            if (programType === PROGRAM_TYPES.EPISODE) {
                meta.episode_name = title;
            }

            contentDetailsEvent(meta, EventType.TRAILER_VIEW);

            const params = {
                showPlaybackTrailer: true,
                trailerId: trailers[0].refId,
            };
            history.push({
                pathname: `${location.pathname}`,
                search: LocationUtil.objectToQueryParams(params),
            });
        }
        else {
            const sourceName = getSourceName(location.pathname);
            const meta = {
                action: CDP_EVENT_CONST.TRAILER_CLICK,
                source_name: sourceName,
                content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
                content_id: id,
                cp_name: cpId,
            };

            if (programType === PROGRAM_TYPES.EPISODE) {
                meta.episode_name = title;
            }

            contentDetailsEvent(meta, EventType.TRAILER_CLICK);
            showModalComponentActionDispatch({
                showModalValue: true,
                componentNameValue: MODAL_POPUP.TRAILER_POPUP,
                overrideCrossButtonValue: true,
                payload: {
                    contentDetails,
                },
            });
        }
    };

    isAddedToWatchList = (contentId) => {
        const { watchList } = this.props;
        return watchList[contentId] && Object.keys(watchList[contentId]).length > 0;
    };

    addToWatchlistEvent = () => {
        const {
            contentDetails: {
                cpId,
                id,
                title,
                programType,
                tvShowName,
            },
        } = this.props;
        const sourceName = SOSUtil.getRouteSourceNameMappedFromCMS(getSourceName(location.pathname));
        const meta = {
            action: this.isAddedToWatchList(id) ? ANALYTICS_ACTIONS.REMOVE_FROM_WATCHLIST : ANALYTICS_ACTIONS.ADD_TO_WATCHLIST_CLICK,
            source_name: sourceName,
            content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
            asset_name: ANALYTICS_ASSETS.WATCHLIST,
        };

        if (programType === PROGRAM_TYPES.EPISODE) {
            meta.episode_name = title;
        }

        contentDetailsEvent(meta, EventType.WATCHLIST_CLICK);
    };

    render() {
        const {
            contentDetails, watchListToggleButtonClick, railCtaClicked, showCtaText = true, railCtaType, windowExist, continueWatchingData,
        } = this.props;
        const { railCtaText, isSubscribed, contentId } = this.state;
        const trailers = lodashGet(contentDetails, 'trailers', []);
        const {
            ADDED_TO_WATCH_LIST_CAPITALIZE = 'Added To Watchlist', ADD_TO_WATCH_LIST_CAPITALIZE = 'Add To Watchlist', WATCH_TRAILER = 'Watch Trailer', GO_FULLSCREEN = 'Watch in Fullscreen',
        } = this.LANGUAGE;
        const { shouldAddInWL = true, contentAutoplay = false, appInAppRedirection = false } = contentDetails;

        return (
            <div className="action-area-banner">
                {/* <AnalyticsButtonComponent
                    className={`play-btn common-white-btn  ${!railCtaText ? 'shimmer' : ''}`}
                    onClick={() => railCtaClicked({
                        railCtaText,
                        railCtaType,
                        appInAppRedirection,
                    })}
                    id={ELEMENT_ID.PLAY_CONTENT_BUTTON}
                    description="PLAY CONTENT BUTTON"
                >
                    {railCtaText
                        ? (
                            <>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21.3889 11.36L7.8911 3.11102C7.77741 3.04154 7.64726 3.0036 7.51404 3.00111C7.38083 2.99861 7.24935 3.03165 7.13314 3.09683C7.01692 3.162 6.92017 3.25696 6.85283 3.37193C6.7855 3.4869 6.75 3.61774 6.75 3.75098V20.2488C6.75 20.3821 6.7855 20.5129 6.85283 20.6279C6.92017 20.7429 7.01692 20.8378 7.13314 20.903C7.24935 20.9682 7.38083 21.0012 7.51404 20.9987C7.64726 20.9962 7.77741 20.9583 7.8911 20.8888L21.3889 12.6399C21.4985 12.5729 21.5891 12.4788 21.652 12.3668C21.7148 12.2547 21.7478 12.1284 21.7478 11.9999C21.7478 11.8714 21.7148 11.7451 21.652 11.633C21.5891 11.521 21.4985 11.427 21.3889 11.36Z"
                                        fill="#0C0F12"
                                    />
                                </svg>
                                <p>{ contentAutoplay && isSubscribed ? GO_FULLSCREEN : railCtaText }</p>
                            </>
                        )
                        : null}
                </AnalyticsButtonComponent> */}

                {shouldAddInWL && (
                    <AnalyticsButtonComponent
                        className={`watchlist-btn button-size-effect ${this.isAddedToWatchList(contentDetails.id) ? 'added-to-watchlist' : 'add-to-watchlist'} ${!checkWindowExist() && 'shimmer'}`}
                        onClick={(e) => {
                            e.preventDefault();
                            this.addToWatchlistEvent();
                            watchListToggleButtonClick(contentDetails);
                        }}
                        id={ELEMENT_ID.ADD_TO_WATCHLIST}
                        description={this.isAddedToWatchList(contentDetails.id) ? 'ADDED TO WATCHLIST' : 'ADD TO WATCHLIST'}
                    >
                        <ToolTip description={this.isAddedToWatchList(contentDetails.id) ? 'ADDED TO WATCHLIST' : 'ADD TO WATCHLIST'} />
                        {checkWindowExist() && (this.isAddedToWatchList(contentDetails.id) ? (
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16.125 9.75L10.625 15L7.875 12.375"
                                    stroke="#E8EAED"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                    stroke="#E8EAED"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        ) : (
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                    stroke="#E8EAED"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M8.25 12H15.75"
                                    stroke="#E8EAED"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 8.25V15.75"
                                    stroke="#E8EAED"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )) }
                        {checkWindowExist() && (this.isAddedToWatchList(contentDetails.id) ? ADDED_TO_WATCH_LIST_CAPITALIZE : ADD_TO_WATCH_LIST_CAPITALIZE) }
                    </AnalyticsButtonComponent>
                )}

                {trailers.length > 0 && (
                    <AnalyticsButtonComponent
                        className="watchlist-btn button-size-effect"
                        onClick={this.onTrailerClick}
                        id={ELEMENT_ID.WATCH_TRAILER_BUTTON}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M20.25 3.75H3.75C2.92157 3.75 2.25 4.42157 2.25 5.25V18.75C2.25 19.5784 2.92157 20.25 3.75 20.25H20.25C21.0784 20.25 21.75 19.5784 21.75 18.75V5.25C21.75 4.42157 21.0784 3.75 20.25 3.75Z"
                                stroke="#E8EAED"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M19.125 7.5H21"
                                stroke="#E8EAED"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M19.125 16.5H21"
                                stroke="#E8EAED"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M3 7.5H4.875"
                                stroke="#E8EAED"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M3 16.5H4.875"
                                stroke="#E8EAED"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.625 7.5H10.125"
                                stroke="#E8EAED"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.625 16.5H10.125"
                                stroke="#E8EAED"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.875 7.5H15.375"
                                stroke="#E8EAED"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.875 16.5H15.375"
                                stroke="#E8EAED"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        {WATCH_TRAILER}
                    </AnalyticsButtonComponent>
                )}


                {/* ONLY APPLICABLE WHEN WATCH TRAILERS BUTTON IS ACTIVE */}
                {/* <TrailerTilePopUp /> */}
                {!this.isWeb && continueWatchingData && continueWatchingData[contentId] && Object.keys(continueWatchingData[contentId]).length > 0 && (
                    <button className="start-from-bigning-btn">
                        <ReplayIconSVGComponent />
                    </button>
                )}
            </div>
        );
    }
}

BannerWidgetComponent.propTypes = {
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showModalComponentActionDispatch: PropTypes.func.isRequired,
    watchListToggleButtonClick: PropTypes.func.isRequired,
    watchList: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isAuthenticated: PropTypes.bool.isRequired,
    triggerLoginDispatch: PropTypes.func.isRequired,
    railCtaType: PropTypes.string.isRequired,
    railCtaActionUrl: PropTypes.string,
    railCtaText: PropTypes.string.isRequired,
    showPlaybackDispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    deviceUtil: PropTypes.object.isRequired,
};

BannerWidgetComponent.defaultProps = {
    railCtaActionUrl: '',
};

function mapStateToProps(state) {
    const {
        syncContent: {
            watchList,
            continueWatchingData,
        },
        userConfig,
        authConfig,
        appConfig,
    } = state;
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', null);
    return {
        watchList,
        isAuthenticated: authConfig.isAuthenticated,
        continueWatchingData,
        preferredPartnerPlans,
        subscriptionDataById: userConfig.subscriptionDataById,
        cpDetailsById: appConfig.cpDetailsById,
    };
}

export default withDeviceUtil(connect(
    mapStateToProps,
    {
        showModalComponentActionDispatch: showModalComponentAction,
        triggerLoginDispatch: triggerLogin,
        showPlaybackDispatch: showPlayback,
        contentDetailsFetchDispatch: contentDetailsFetchAction,
    },
)(withWatchlistDataHOC(withRouter(withStyles(styles)(BannerWidgetComponent)))));
