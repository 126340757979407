import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
// import { ThumborImage } from '@airtel-tv/ui-lib/atoms'; 
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
// import withDeviceUtil from '../../shared/hoc/WithDeviceUtil';
import { hideModalComponentAction } from '../../../../../web/src/modules/modal-popup/ModalPopupActions';
// import { hideModalComponentAction } from '../../modal-popup/ModalPopupActions';
import { showPlayback } from '../../../../../web/src/modules/notify/NotifyActions';
// import { showPlayback } from '../../notify/NotifyActions';
import { getSourceName } from '@airtel-tv/utils/GlobalUtil';
import EventType from '@airtel-tv/analytics/EventType';
import { CDP_EVENT_CONST, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import {LocationUtil} from '@airtel-tv/utils/LocationUtil';
import styles from './TrailerTilePopup.scss';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
class TrailerTilePopUp extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    closePopUp = () => {
        const {
            hideModalComponentActionDispatch,
        } = this.props;
        hideModalComponentActionDispatch(
            false,
            null,
            null,
        );
    }

    getImage = images => images.LANDSCAPE_169

    playTrailer = (trailer) => {
        this.closePopUp();
        const { history, location, contentDetails: {programType, title, tvShowName, id, cpId } } = this.props;

        const sourceName = getSourceName(location.pathname)
        const meta = {
            action: CDP_EVENT_CONST.TRAILER_VIEW,
            source_name: sourceName,
            content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
        }

        if (programType === PROGRAM_TYPES.EPISODE) {
            meta['episode_name'] = title;
        }

        contentDetailsEvent(meta, EventType.TRAILER_VIEW);

        const params = {
            showPlaybackTrailer: true,
            trailerId: trailer.refId
        }
        history.push({ 
            pathname: `${location.pathname}`,
            search: LocationUtil.objectToQueryParams(params)
        })
    }

    getDuration = (duration) => {
        const durationInitial = duration || 0;
        const remainder = durationInitial % 60;
        const quotient = Math.trunc(durationInitial / 60);
        const label = `${quotient ? quotient.toString().concat('m ') : ''}${remainder ? remainder.toString().concat('s') : ''}`
        return label.length ? label : '0s';
    }

    render() {
        const { contentDetails } = this.props;
        const trailers = lodashGet(contentDetails, 'trailers', []);
        const description = lodashGet(contentDetails, 'description', '');

        return (
            <section className="popup-modal-area">
                <div className="trailer-popup">
                    <div className="head">
                        <p className="title">
                            Trailers
                        </p>
                        <AnalyticsButtonComponent
                            onClick={this.closePopUp}
                            aria-label="Close Pop up"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18.75 5.25L5.25 18.75"
                                    stroke="#A3A7AE"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M18.75 18.75L5.25 5.25"
                                    stroke="#A3A7AE"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </AnalyticsButtonComponent>


                    </div>
                    <div className="rail-holder">
                        {trailers.length && trailers.map(trailer => (
                            <div className="trailer-tile active" key={trailer.refId}>
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.playTrailer(trailer)
                                }}>
                                    <div className="trailer-img">
                                        <ThumborImage
                                            src={this.getImage(trailer.images)}
                                            imageSettings={{
                                                1000: 400,
                                                500: 250,
                                            }}
                                        />
                                        <img
                                            className="trailer-center-play"
                                            src="/static/cdp-assets/tile-center-play.svg"
                                        />
                                    </div>
                                    <div className="trailer-info">
                                        <p className="name">{trailer.name}</p>
                                        <p className="duration">{this.getDuration(trailer.duration)}</p>
                                        <p className="about">{description}</p>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    const {
        appConfig,
        userConfig,
        planConfig,
        notify,
        popupConfig,
    } = state;
    const redirectUrl = lodashGet(planConfig, 'redirectUrl', '');
    const isFetchingDone = lodashGet(notify, 'isFetchingDone', null);
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', []);
    const planStartingPrice = lodashGet(appConfig, 'subscriptionData.planStartingPrice', {});
    const popups = lodashGet(popupConfig, 'allPopUps', null);

    return {
        planStartingPrice,
        userConfig,
        isFetchingDone,
        redirectUrl,
        popups,
        preferredPartnerPlans,
        cpDetailsById,
        showPlayback: notify.showPlayback
    };
}

export default connect(mapStateToProps, {
    hideModalComponentActionDispatch: hideModalComponentAction,
    showPlaybackDispatch: showPlayback
})(withDeviceUtil(withRouter(withStyles(styles)(TrailerTilePopUp))));
