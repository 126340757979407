import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { CDP_EVENT_CONST, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import EventType from '@airtel-tv/analytics/EventType';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { cleanNextTab } from '../../../content-details/actions/ContentDetailsAction';

class EpisodeDropDownComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSeason: {},
            dropdownActive: false,
        };
    }

    componentDidMount() {
        const { activeSeasonId, seasons } = this.props;
        const selectedSeason = seasons.find(season => season.seasonId === activeSeasonId);
        this.setState({ selectedSeason });
        // this.getTabs(selectedSeason, true);
    }

    componentDidUpdate(prevProps) {
        const { activeSeasonId, seasons } = this.props;
        if (((prevProps.activeSeasonId !== this.props.activeSeasonId) && prevProps.activeSeasonId && this.props.activeSeasonId)) {
            const selectedSeason = seasons.find(season => season.seasonId === activeSeasonId);
            this.setState({ selectedSeason });
        }  
    }
    getTabs = (season, isInitialFetch = false) => {
        this.props.cleanNextTabDispatch();
        const { fetchTabs, tabs, contentDetails } = this.props;
        const { seasonId } = season;
        this.setState({ selectedSeason: season });
        const { currentTab } = contentDetails || LocationUtil.getQueryParams(this.props);
        if (currentTab && tabs && isInitialFetch) {
            fetchTabs(seasonId, currentTab);
        } else {
            fetchTabs(seasonId);
        }
    }

    openDropDown = () => {
        const buttonElement = document.getElementById('season-button');
        if (buttonElement.classList.contains('active')) {
            buttonElement.classList.remove('active');
        }
        else {
            buttonElement.classList.add('active');
            this.setState({ dropdownActive: true });
        }
        let { contentDetails, contentDetails: { contentId}, sourceName, packageId } = this.props;
        contentDetails = contentDetails[contentId];
        const { tvShowName, title, cpId, id, programType } = contentDetails;
        const meta = {
            action: CDP_EVENT_CONST.SEASON_CLICK,
            source_name: sourceName,
            content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
            package_id: packageId,
        }
        contentDetailsEvent(meta, EventType.SEASON_CLICK);
    }

    closeDropDown = () => {
        const buttonElement = document.getElementById('season-button');
        if (buttonElement.classList.contains('active')) {
            buttonElement.classList.remove('active');
        }
    }

    render() {
        const { seasons } = this.props;
        const { selectedSeason, dropdownActive } = this.state;
        return (
            <div
                className="season-btn-container"

                id="season-button"
            >
                <AnalyticsButtonComponent
                    className="season-btn"
                    onClick={this.openDropDown}
                    onMouseLeave={this.closeDropDown}
                >
                    {/* use class active to get avtive state */}
                    { selectedSeason.name && selectedSeason.name.toLowerCase() }
                    {' '}
                    <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="caret-down"
                    >
                        <path
                            d="M1 0.999999L7 7L13 1"
                            stroke="#E8EAED"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <ul className="episode-dropdown-subs">
                        {/* use class acttive on lli element to get selected state of button */}
                        { seasons && seasons.length > 0 && seasons.map(season => (<li onClick={() => this.getTabs(season)}><a>{ season.name.toLowerCase() }</a></li>)) }
                    </ul>
                </AnalyticsButtonComponent>
            </div>
        );
    }
}

EpisodeDropDownComponent.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    seasons: PropTypes.array,
    fetchTabs: PropTypes.func.isRequired,
    packageId: PropTypes.string,
};

EpisodeDropDownComponent.defaultProps = {
    seasons: [],
    packageId: '',
};

const mapStateToProps = (state) => {
    const { contentDetails } = state;

    return {
        contentDetails,
    };
};

export default withRouter(connect(mapStateToProps, {
    cleanNextTabDispatch: cleanNextTab,
})(EpisodeDropDownComponent));
