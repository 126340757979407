/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './MoreInfoPopUp.scss';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
// import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { hideModalComponentAction } from '../../../../../web/src/modules/modal-popup/ModalPopupActions';
import { getAudioLanguages } from '@airtel-tv/utils/GlobalUtil';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';

class MoreInfoPopUp extends Component {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.state = {

        };
    }

    componentWillUnmount() {
        if (this.deviceUtil.isMobile()) {
            document.body.style = "overflow: scroll"
            document.body.style.position = 'unset';
        }
    }

    closePopUp = () => {
        const {
            hideModalComponentActionDispatch,
        } = this.props;
        hideModalComponentActionDispatch(
            false,
            null,
            null,
        );
    }

    render() {
        const {
            contentDetails: {
                title,
                shortDescription,
            },
            showAudio = true
        } = this.props;
        const { AUDIO_AND_SUBTITLES = "Audio & Subtitles", AUDIO = "Audio" } = this.LANGUAGE;
        return (
            <div className="popup-modal-area">
                <div className="more-info-popup">

                    <AnalyticsButtonComponent
                        onClick={this.closePopUp}
                        className="more-info-close"
                        aria-label="Close Pop up"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18.75 5.25L5.25 18.75"
                                stroke="#A3A7AE"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M18.75 18.75L5.25 5.25"
                                stroke="#A3A7AE"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </AnalyticsButtonComponent>

                    <div className="content-detail-area">
                        {title && <h2 className="pop-title">{ title }</h2>}
                        <p className="pop-desc" dangerouslySetInnerHTML={{__html: shortDescription.replace(/\n/g, "<br />") }}></p>
                    </div>
                    { showAudio && <div className="content-information">
                        <h2 className="audio-title">{ AUDIO_AND_SUBTITLES }</h2>
                        <p className="audio-desc">
                            {AUDIO}:
                            {' '}
                            {getAudioLanguages(this.props)}
                        </p>
                        {/* <p className="audio-desc">Subtitles: Hindi & English</p> */}
                    </div>}
                    {/* <div className="popup-bottom-strip">
                        <ThumborImage
                            src="/static/cdp-assets/bottom-strip.svg"
                        />
                    </div> */}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        appConfig,
        userConfig,
        planConfig,
        notify,
        popupConfig,
    } = state;
    const redirectUrl = lodashGet(planConfig, 'redirectUrl', '');
    const isFetchingDone = lodashGet(notify, 'isFetchingDone', null);
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', []);
    const planStartingPrice = lodashGet(appConfig, 'subscriptionData.planStartingPrice', {});
    const popups = lodashGet(popupConfig, 'allPopUps', null);
    // eslint-disable-next-line camelcase
    const { language_map_web } = appConfig;
    // eslint-disable-next-line camelcase
    const languageMap = language_map_web;

    return {
        planStartingPrice,
        userConfig,
        isFetchingDone,
        redirectUrl,
        popups,
        preferredPartnerPlans,
        cpDetailsById,
        languageMap,
    };
}

export default connect(mapStateToProps, {
    hideModalComponentActionDispatch: hideModalComponentAction,
})(withDeviceUtil(withRouter(withStyles(styles)(MoreInfoPopUp))));
