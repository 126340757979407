import lodashGet from 'lodash/get';
import { Navigate } from 'react-router';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    RELATION_TYPES,
    PROGRAM_TYPES,
    ROLE_TYPES,
    INTERSECTION_OBSERVER_THRESHOLD,
    RESIZE_OPTION,
    DIMENSION_PAGE_MAP,
    ERROR_CODES,
 } from '@airtel-tv/constants';
import { LoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/LoaderComponent';
import { SmallLoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/SmallLoaderComponent';
import { LinkComponent } from '@airtel-tv/ui-lib/atoms/link/LinkComponent';
import { RoutingUtil, LanguageProviderUtil, getPosterUrlFromContentDetails, BreadCrumbsUtil, withDeviceUtil } from '@airtel-tv/utils';
import { IntersectionObservableHOC } from '@airtel-tv/lib';
import {
    secondsTohhhmmm,
} from '@airtel-tv/utils/GlobalUtil';
import {  
    getSocialShareInfo,
    getSeasonTitle,
    getCDPFooterContent,
    getLangfromCode, 
    getPageSeoMeta 
} from '@airtel-tv/utils/ContentMetaUtil';

import { seriesDetailsFetchAction } from '../actions/ContentDetailsAction';
import PlaybackContainer from '@airtel-feature/playback/PlaybackContainer';
import DescriptionComponent from '../components/DescriptionComponent';
import CastComponent from '../components/CastComponent';
import RelatedContentContainer from '../../../../web/src/modules/rail/containers/RelatedContentContainer'
import SeasonSelectorComponent from '../components/SeasonSelectorComponent';
import ErrorHandler from '../../../../web/src/modules/error-handler/ErrorHandler';
import { changeMetaData } from '../../../../web/src/modules/notify/NotifyActions';
import MarkupFactory from '../../../../web/src/modules/watch-actions/factories/MarkupFactory';
import { updatePageBreadCrumbs } from '@airtel-tv/ui-lib/molecules/breadcrumbs/actions/BreadCrumbsActions';
import NextEpisodeFactory from '../factory/NextEpisodeFactory';
import { getInfo } from '../builders/InfoBuilder';
import { updateOnPageDescriptionAction } from '../../../../web/src/modules/footer/actions/FooterAction';

class SeriesDetailContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.deviceUtil = props.deviceUtil;

        this.state = {
            contentDetails: undefined,
            contentIdFromParam: undefined,

            tvShowDetails: undefined,
            selectedTvShowId: undefined,

            seasonDetails: undefined,
            selectedSeasonId: undefined,

            episodesDetails: undefined,
            selectedEpisodeId: undefined,

            isRecommendedRailVisible: this.deviceUtil.isBot(),

            isAuthStateChanged: false,

            isAuthenticated: undefined,
        };

        this.isDidMounted = false;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            contentDetails,
            contentIdFromParam,

            selectedSeasonId,
            seasonDetails,

            selectedEpisodeId,
            episodesDetails,

            tvShowDetails,
            selectedTvShowId,
            isAuthenticated,
        } = nextProps;
        if (
            !prevState
            || prevState.contentDetails !== contentDetails
            || prevState.contentIdFromParam !== contentIdFromParam
            || prevState.selectedSeasonId !== selectedSeasonId
            || prevState.seasonDetails !== seasonDetails
            || prevState.selectedEpisodeId !== selectedEpisodeId
            || prevState.episodesDetails !== episodesDetails
            || prevState.tvShowDetails !== tvShowDetails
            || prevState.selectedTvShowId !== selectedTvShowId
            || prevState.isAuthenticated !== isAuthenticated
        ) {
            const isAuthStateChanged = prevState.isAuthenticated !== undefined && prevState.isAuthenticated !== isAuthenticated;

            return {
                contentDetails,
                contentIdFromParam,

                selectedSeasonId,
                seasonDetails,

                selectedEpisodeId,
                episodesDetails,

                tvShowDetails,
                selectedTvShowId,
                isAuthenticated,
                isAuthStateChanged,
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    componentDidMount() {
        this.isDidMounted = true;
        if (this.contentNotFound()) {
            return;
        }
        this.updateSeoMeta();
    }

    componentDidUpdate(prevProps) {
        if (this.contentNotFound()) {
            return;
        }

        this.updateSeoMeta(prevProps);
    }


    updateSeoMeta = (prevProps = {}) => {
        const { changeMetaDataDispatch, contentDetails, updatePageBreadCrumbsDispatch, cpDetailsById, updateOnPageDescriptionActionDispatch, customCdpContent = {} } = this.props;
        const { tvShowDetails, episodesDetails, seasonDetails } = this.state;

        if (
            // ALL THREE ID SHOULD EXISTS
            (lodashGet(tvShowDetails, 'id')
                && lodashGet(seasonDetails, 'id')
                && lodashGet(episodesDetails, 'id'))
            // ANY OF THE ID MUST BE DIFFERENT
            && (lodashGet(prevProps, 'tvShowDetails.id') !== lodashGet(tvShowDetails, 'id')
                || lodashGet(prevProps, 'seasonDetails.id') !== lodashGet(seasonDetails, 'id')
                || lodashGet(prevProps, 'episodesDetails.id') !== lodashGet(episodesDetails, 'id'))
        ) {
            const {
                programType,
                title,
                id,
                cpId
            } = contentDetails;

            const {
                episodeNum,
                episodeSeasonNum,
                durSec, 
                downloadable
            } = episodesDetails;

            const channelPartnerName = lodashGet(cpDetailsById[cpId], 'title', '');
            let headMetaData = getPageSeoMeta({
                programType,
                tvShowDetails: {
                    showName: tvShowDetails.title,
                    seasonNumber: episodeSeasonNum,
                    episodeNumber: episodeNum,
                    episodeName: title,
                    channelName: channelPartnerName
                },
            });

            let content;
            if(customCdpContent[id]) {
                let { seoTitle = '', seoDescription = '',seoFooterContent = ''} =  customCdpContent[id]    
                content = seoFooterContent;
                headMetaData = {
                    ...headMetaData,
                    title: seoTitle ? seoTitle: headMetaData.title,
                    description: seoDescription? seoDescription: headMetaData.description
                }
            } else if(customCdpContent[tvShowDetails.id]) {
                let { seoTitle = '', seoDescription = '', seoFooterContent = ''} = customCdpContent[tvShowDetails.id]
                content = seoFooterContent;
                headMetaData = {
                    ...headMetaData,
                    title: seoTitle ? seoTitle: headMetaData.title,
                    description: seoDescription? seoDescription: headMetaData.description
                }
            } else {
                const {
                    programType, title, languages, credits, genres
                } = tvShowDetails;
                
                let actorsForFooter = (credits || []).map(actor => {
                    let actorUrl =  RoutingUtil.getArtistUrlWeb({
                        name: actor.displayTitle,
                        id: actor.id,
                    });
                    return {
                        ...actor,
                        actorUrl
                    }
                });

                let languageMap = languages.map(item => ({
                    name: getLangfromCode({ lang: item }),
                    url: RoutingUtil.getDimensionPageUrlV2({
                        pageTitle: DIMENSION_PAGE_MAP[programType],
                        lang: getLangfromCode({ lang: item })
                    })
                }));

                let genreMap = genres.map(item => ({
                    name: item,
                    url: RoutingUtil.getDimensionPageUrlV2({
                        pageTitle: DIMENSION_PAGE_MAP[programType],
                        genres: item
                    })
                }));

                const duration = durSec ? secondsTohhhmmm(durSec): '';
           
 
                const pageLink = lodashGet(cpDetailsById[cpId], 'pageLink', '');

                const channelUrl = RoutingUtil.getChannelPartnerPageUrl({
                    channelPartnerName,
                    pageLink,
                })

                content = getCDPFooterContent({ 
                    programType: PROGRAM_TYPES.TVSHOW, 
                    actorsForFooter,
                    channelPartnerName,
                    contentTitle: title,
                    languageMap,
                    genreMap,
                    duration,
                    channelUrl,
                    baseUrl: this.deviceUtil.getBaseUrl(),
                    downloadable
                });
            }

            if(content) {
                updateOnPageDescriptionActionDispatch(
                    {onPageDescription: content,}
                )
            }
            let seoMarkups = [];
            let curPageLink = '/';
            let showPageLink = '/';
            let seasonPageLink = '/';
            let breadCrumbsList = [];
            switch (contentDetails.programType) {
                case PROGRAM_TYPES.EPISODE:
                    curPageLink = RoutingUtil.getTvEpisodeUrlWeb({
                        programType,
                        title: tvShowDetails.title,
                        episodeNumber: episodeNum,
                        episodeName: title,
                        seasonName: seasonDetails.title,
                        contentId: id,
                    });
                    showPageLink = RoutingUtil.getContentPlaybackUrlWeb({
                        category: programType,
                        contentTitle: tvShowDetails.title,
                        contentId: tvShowDetails.id,
                    });
                    seasonPageLink = RoutingUtil.getTvSeasonUrlWeb({
                        programType,
                        title: tvShowDetails.title,
                        seasonName: seasonDetails.title,
                        contentId: seasonDetails.id,
                    });
                    breadCrumbsList = BreadCrumbsUtil.tvEpisodePageBreadCrumb({
                        contentDetails,
                        curPageLink,
                        showPageLink,
                        seasonPageLink,
                    });
                    seoMarkups = MarkupFactory({
                        contentDetails,
                        tvShowDetails,
                        seasonDetails,
                        baseUrl: this.deviceUtil.getBaseUrl(),
                        breadCrumbsList,
                    });
                    changeMetaDataDispatch({
                        headMetaData: {
                            ...headMetaData,
                            image: getPosterUrlFromContentDetails(contentDetails) || getPosterUrlFromContentDetails(episodesDetails),
                            link: curPageLink,
                            watchActionCatalogues: seoMarkups,
                        },
                    });
                    updatePageBreadCrumbsDispatch({ breadCrumbsList });
                    break;

                case PROGRAM_TYPES.SEASON:
                    curPageLink = RoutingUtil.getTvSeasonUrlWeb({
                        programType,
                        title: tvShowDetails.title,
                        seasonName: seasonDetails.title,
                        contentId: id,
                    });
                    showPageLink = RoutingUtil.getContentPlaybackUrlWeb({
                        category: programType,
                        contentTitle: tvShowDetails.title,
                        contentId: tvShowDetails.id,
                    });
                    breadCrumbsList = BreadCrumbsUtil.tvSeasonPageBreadCrumb({
                        contentDetails,
                        curPageLink,
                        showPageLink,
                    });
                    seoMarkups = MarkupFactory({
                        contentDetails,
                        episodeDetails: episodesDetails,
                        baseUrl: this.deviceUtil.getBaseUrl(),
                        breadCrumbsList,
                    });
                    changeMetaDataDispatch({
                        headMetaData: {
                            ...headMetaData,
                            image: getPosterUrlFromContentDetails(episodesDetails),
                            link: curPageLink,
                            watchActionCatalogues: seoMarkups,
                        },
                    });
                    updatePageBreadCrumbsDispatch({ breadCrumbsList });
                    break;

                case PROGRAM_TYPES.TVSHOW:
                    curPageLink = RoutingUtil.getContentPlaybackUrl({
                        category: programType,
                        contentTitle: tvShowDetails.title,
                        contentId: id,
                    });
                    breadCrumbsList = BreadCrumbsUtil.tvShowPageBreadCrumb({
                        contentDetails,
                        curPageLink,
                    });
                    seoMarkups = MarkupFactory({
                        contentDetails,
                        baseUrl: this.deviceUtil.getBaseUrl(),
                        breadCrumbsList,
                    });
                    changeMetaDataDispatch({
                        headMetaData: {
                            ...headMetaData,
                            image: getPosterUrlFromContentDetails(episodesDetails),
                            link: curPageLink,
                            watchActionCatalogues: seoMarkups,
                        },
                    });
                    updatePageBreadCrumbsDispatch({ breadCrumbsList });
                    break;

                default:
                    break;
            }
        }
    }

    contentNotFound = () => {
        const {
            episodesDetails,
            tvShowDetails,
        } = this.state;

        if (!lodashGet(episodesDetails, 'id', false)
            && (lodashGet(tvShowDetails, 'seriesTvSeasons', []).length < 1 && lodashGet(tvShowDetails, 'id', false))) {
            ErrorHandler({ code: ERROR_CODES.LOCAL1004 });
            return true;
        }

        return false;
    }

    // onSeasonChange = (selectedSeasonId) => {
    //     this.setState({
    //         changedSeasonId: selectedSeasonId,
    //     });
    // }

    init = (reload) => {
        const { seriesDetailsFetchActionDispatch, contentIdFromParam, contentDetails } = this.props;

        if (contentDetails && contentDetails.fetching) {
            return;
        }

        if (!reload && contentDetails && contentDetails.error) {
            return;
        }

        if (!contentIdFromParam || contentIdFromParam === contentDetails.id) {
            return;
        }

        seriesDetailsFetchActionDispatch(contentIdFromParam);
    }


    findDirectors = (castList) => {
        if (!castList || !castList.length) {
            return null;
        }
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        // getting directors
        const castData = castList.filter(
            data => (data?.roleType || '').toLowerCase() === ROLE_TYPES.DIRECTOR,
        );
        if (!castData || !castData.length) {
            return null;
        }

        const topCastData = castData.length > 3 ? castData.slice(0, 3) : castData;

        const directorData = topCastData.map((casting, index) => (
            <LinkComponent
                className="link link-theme-secondary"
                key={casting.id}
                to={RoutingUtil.getArtistUrlWeb({
                    name: casting.displayTitle,
                    id: casting.id,
                })}
                title={casting.displayTitle}
            >
                {casting.displayTitle}
                {topCastData[index + 1] ? ', ' : ''}
            </LinkComponent>
        ));
        return (
            <p>
                {' '}
                {LANGUAGE.DIRECTOR_TITLE}
                :
                <span className="ml-1">
                    {''}
                    {/* {directorData.join(', ')} */}
                    {directorData}
                </span>
            </p>
        );
    };

    getTvShowName = () => {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const { contentDetails } = this.props;
        const {
            episodeSeasonNum,
            episodeNum,
            programType,
            title,
            tvShowName,
        } = contentDetails;
        const lowerCasedTitle = title.toLowerCase();
        switch (programType) {
            case PROGRAM_TYPES.TVSHOW:
                return `${title}`;
            case PROGRAM_TYPES.SEASON:
                return `${tvShowName} - ${lowerCasedTitle}`;
            case PROGRAM_TYPES.EPISODE:
                return `${tvShowName} - ${LANGUAGE.SEASON} ${episodeSeasonNum} ${LANGUAGE.EPISODE} ${episodeNum}`;
            default:
                return null;
        }
    };

    getVideoMeta = (playbackContentDetails) => {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const { contentDetails } = this.props;
        const {
            episodeSeasonNum,
            episodeNum,
            programType,
            title,
            tvShowName,
        } = contentDetails;
        const lowerCasedTitle = title.toLowerCase();
        let videoTitle;
        switch (programType) {
            case PROGRAM_TYPES.TVSHOW:
                videoTitle = `${title} - ${LANGUAGE.SEASON} 1 ${LANGUAGE.EPISODE} 1`;
                break;
            case PROGRAM_TYPES.SEASON:
                videoTitle = `${tvShowName} - ${lowerCasedTitle} ${LANGUAGE.EPISODE} 1`;
                break;
            case PROGRAM_TYPES.EPISODE:
                videoTitle = `${tvShowName} - ${LANGUAGE.SEASON} ${episodeSeasonNum} ${LANGUAGE.EPISODE} ${episodeNum}`;
                break;
            default:
                videoTitle = '';
        }

        const subTitle = playbackContentDetails.title || '';

        return {
            videoTitle,
            subTitle,
        };
    }

    getSeasonDetails = () => {
        const { contentDetails } = this.props;
        const {
            title, programType,
        } = contentDetails;

        switch (programType) {
            case PROGRAM_TYPES.TVSHOW:
                return null;
            case PROGRAM_TYPES.SEASON:
                return null;
            case PROGRAM_TYPES.EPISODE:
                // return `Season ${episodeSeasonNum} Episode ${episodeNum} - ${title}`;
                return `${title}`;
            default:
                return null;
        }
    };

    showRecommendedRail = () => {
        setTimeout(() => {
            this.setState({
                isRecommendedRailVisible: true,
            });
        }, 1000);
    }

    render() {
        const {
            tvShowDetails,
            episodesDetails,
            seasonDetails,
            contentDetails,
            isAuthStateChanged,
        } = this.state;
        const {
            currentUrl, continueWatchingData, nextEpisodeDetails, cpDetailsById, allPlans,
        } = this.props;
        if ((isAuthStateChanged || !this.isDidMounted) && continueWatchingData[contentDetails.id] && contentDetails.programType !== PROGRAM_TYPES.EPISODE) {
            const {
                episodeTvShowName: title,
                episodeNum: episodeNumber,
                title: episodeName,
                id: contentId,
                episodeSeasonNum,
            } = continueWatchingData[contentDetails.id].contentResponse;

            const redirectContinueWatchingUrl = RoutingUtil.getTvEpisodeUrlWeb({
                programType: PROGRAM_TYPES.EPISODE,
                title,
                episodeNumber,
                episodeName,
                seasonName: getSeasonTitle(episodeSeasonNum),
                contentId,
            });
            return <Navigate to={redirectContinueWatchingUrl} />;
        }

        if ((!tvShowDetails || !episodesDetails || !seasonDetails) && !contentDetails.fetching) {
            return <Navigate to={RoutingUtil.get404Route()} />;
        }


        if (
            !lodashGet(episodesDetails, 'id', false)
            || !lodashGet(tvShowDetails, 'id', false)
            || !lodashGet(seasonDetails, 'id', false)
        ) {
            return <LoaderComponent />;
        }

        const descriptionMeta = {
            ...contentDetails,
        };

        const {
            cpId, description, shortUrl, images: contentImages,
        } = descriptionMeta;
        const info = getInfo(contentDetails);
        const seasonInfo = this.getSeasonDetails();
        const directors = this.findDirectors(descriptionMeta.credits);
        const title = this.getTvShowName();

        const socialShareInfo = getSocialShareInfo({
            programType: contentDetails.programType,
            showName: title,
            currentUrl,
            baseUrl: this.deviceUtil.getBaseUrl(),
        });

        const playbackContentDetails = {
            ...episodesDetails,
            images: contentImages || episodesDetails.images,
        };

        const videoMeta = this.getVideoMeta(playbackContentDetails);

        let castList = [];
        if (contentDetails.credits && contentDetails.credits.length > 0) {
            castList = contentDetails.credits.filter(element => element.roleType === ROLE_TYPES.ACTOR);
        }

        const { isRecommendedRailVisible } = this.state;

        const loaderElem = <SmallLoaderComponent style={{ marginTop: '20px' }} />;

        const recommendedContentElement = (
            isRecommendedRailVisible ? (
                <RelatedContentContainer
                    targetId={tvShowDetails.id}
                    relationType={RELATION_TYPES.TVSHOW}
                    programTypes={PROGRAM_TYPES.TVSHOW}
                />
            ) : (
                <IntersectionObservableHOC
                    fetchMore={this.showRecommendedRail}
                    hasMore={!isRecommendedRailVisible}
                    loaderElem={loaderElem}
                    startPageNumber={1}
                    threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
                />
            )
        );
        const logUrl = lodashGet(cpDetailsById[cpId], 'cpIconURL', '');
        return (
            <>
                <section className="current-vdo pt-2 pt-md-4 pt-sm-4">
                    <div className="container-fluid  extended-rail-margin">
                        <div className="row mx-0">
                            {/* player */}
                            <PlaybackContainer
                                contentDetails={playbackContentDetails}
                                nextEpisodeDetails={nextEpisodeDetails}
                                videoMeta={videoMeta}
                            />

                            <DescriptionComponent
                                logo={logUrl}
                                allPlans={allPlans}
                                title={title}
                                info={info}
                                // rating={imdbRating}
                                description={description}
                                shortUrl={shortUrl}
                                seasonDetails={seasonInfo}
                                directors={directors}
                                socialShareInfo={socialShareInfo}
                                contentDetails={tvShowDetails}
                                id={contentDetails.seriesId || contentDetails.id}
                                isBot={this.deviceUtil.isBot()}
                                resizeOption={RESIZE_OPTION.TAB_LARGE}
                            />
                        </div>
                    </div>


                </section>

                {/* <section className="current-vdo ">
                </section> */}

                <SeasonSelectorComponent
                    title={tvShowDetails.title}
                    programType={tvShowDetails.programType}
                    seasons={tvShowDetails.seriesTvSeasons}
                    seasonDetails={seasonDetails}
                    contentId={episodesDetails.id}
                    continueWatchingData={continueWatchingData}
                />

                {castList.length > 0 ? (
                    <div className="container-fluid extended-rail-margin">
                        <CastComponent casts={castList} />
                    </div>
                ) : null}

                {/* related content rails */}
                {recommendedContentElement}
            </>
        );
    }
}

SeriesDetailContainer.defaultProps = {
    selectedSeasonId: null,
    seasonDetails: null,
    selectedEpisodeId: null,
    episodesDetails: null,
    tvShowDetails: null,
    selectedTvShowId: null,
    currentUrl: '',
    continueWatchingData: {},
    nextEpisodeDetails: null,
};

SeriesDetailContainer.propTypes = {
    contentDetails: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    seriesDetailsFetchActionDispatch: PropTypes.func.isRequired,
    changeMetaDataDispatch: PropTypes.func.isRequired,
    contentIdFromParam: PropTypes.string.isRequired,
    selectedSeasonId: PropTypes.string,
    seasonDetails: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    selectedEpisodeId: PropTypes.string,
    episodesDetails: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    tvShowDetails: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    selectedTvShowId: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    currentUrl: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    isAuthenticated: PropTypes.bool.isRequired,
    continueWatchingData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    updatePageBreadCrumbsDispatch: PropTypes.func.isRequired,
    nextEpisodeDetails: PropTypes.shape({
        details: PropTypes.object,
        link: PropTypes.string,
    }),
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state, props) => {
    const { match, contentDetails, allPlans } = props;
    const { params: { contentId: contentIdFromParam }, url: currentUrl } = match;
    const {
        contentDetails: contentCollection,
        syncContent: {
            continueWatchingData,
        },
        authConfig: {
            isAuthenticated,
        },
        appConfig: {
            cpDetailsById,
            customCdpContent
        },
    } = state; // reducer
    let selectedTvShowId;
    let tvShowDetails;

    let selectedSeasonId;
    let seasonDetails;

    let selectedEpisodeId;
    let episodesDetails;

    let nextEpisodeDetails = null;

    if (contentDetails && contentDetails.id) {
        const { programType } = contentDetails;

        if (programType === PROGRAM_TYPES.TVSHOW) {
            tvShowDetails = { ...contentDetails };
            selectedTvShowId = tvShowDetails.id;

            if (lodashGet(tvShowDetails, 'seriesTvSeasons', []).length > 0) {
                const [
                    selectedSeason,
                ] = tvShowDetails.seriesTvSeasons;
                selectedSeasonId = selectedSeason.seasonId;
                seasonDetails = contentCollection[selectedSeasonId];

                // get episode details
                if (lodashGet(seasonDetails, 'episodeRefs', []).length > 0) {
                    const [
                        selectedEpisode,
                    ] = seasonDetails.episodeRefs;
                    selectedEpisodeId = selectedEpisode.refId;
                    episodesDetails = contentCollection[selectedEpisodeId];

                    if (!episodesDetails) {
                        const seasonEpisodeDetail = seasonDetails.episodeRefs.find(episodeRef => contentCollection[episodeRef.refId]);
                        episodesDetails = contentCollection[seasonEpisodeDetail.refId];
                    }
                }
            }
        }
        else if (programType === PROGRAM_TYPES.SEASON) {
            seasonDetails = { ...contentDetails };
            selectedSeasonId = seasonDetails.id;

            selectedTvShowId = seasonDetails.seriesId;
            tvShowDetails = contentCollection[selectedTvShowId];

            // get episode details
            if (lodashGet(seasonDetails, 'episodeRefs', []).length > 0) {
                const [
                    selectedEpisode,
                ] = seasonDetails.episodeRefs;
                selectedEpisodeId = selectedEpisode.refId;
                episodesDetails = contentCollection[selectedEpisodeId];
                if (!episodesDetails) {
                    const seasonEpisodeDetail = seasonDetails.episodeRefs.find(episodeRef => contentCollection[episodeRef.refId]);
                    episodesDetails = contentCollection[seasonEpisodeDetail.refId];
                }
            }
        }
        else if (programType === PROGRAM_TYPES.EPISODE) {
            episodesDetails = { ...contentDetails };
            selectedEpisodeId = episodesDetails.id;

            selectedTvShowId = episodesDetails.seriesId;
            tvShowDetails = contentCollection[selectedTvShowId];

            selectedSeasonId = episodesDetails.seasonId;
            seasonDetails = contentCollection[selectedSeasonId];
        }

        nextEpisodeDetails = NextEpisodeFactory({
            contentDetails,
            contentCollection,
        });
    }

    const prop = {
        contentIdFromParam,

        contentDetails,

        selectedSeasonId,
        seasonDetails,

        selectedEpisodeId,
        episodesDetails,

        tvShowDetails,
        selectedTvShowId,
        currentUrl,
        isAuthenticated,
        continueWatchingData,
        nextEpisodeDetails,
        allPlans,

        cpDetailsById,
        customCdpContent
    };

    return prop;
};

export default withRouter(
    connect(
        mapStateToProps,
        {
            changeMetaDataDispatch: changeMetaData,
            seriesDetailsFetchActionDispatch: seriesDetailsFetchAction,
            updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
            updateOnPageDescriptionActionDispatch: updateOnPageDescriptionAction
        },
    )(withDeviceUtil(SeriesDetailContainer)),
);