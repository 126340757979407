import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import React from 'react';
import getContentLayoutPage from '../factories/ContentLayoutFactory';


function ContentLayoutContainer(props) {
    const layoutPage = getContentLayoutPage({ props });

    return (
        <>
            {layoutPage}
        </>
    );
}

export default withRouter(ContentLayoutContainer);
