import {
    call, put, takeEvery, getContext, select,
} from 'redux-saga/effects';
import { contentDetailsFetchCompletedAction, contentDetailsFetchErrorAction, ContentDetailsActions, episodeTabsFetchCompletedAction, setCurrentTab } from '../actions/ContentDetailsAction';
import { getContentDetails, getepisodeTabs, getSeriesDetails, getTabDetails  } from '../../../../web/src/service/end-points/ContentApiService';
import { isOnline } from '@airtel-tv/utils/WindowUtil';
import { ERROR_CODES } from '@airtel-tv/constants';
import ErrorHandler from '../../../../web/src/modules/error-handler/ErrorHandler';
import { getContentDetailsDataFromReducer } from '@airtel-tv/redux/StoreListing';
import { redirectTo, showToastr } from '@airtel-tv/utils';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';

function* fetchContentDetails(action) {
    const { contentId } = action;

    try {
        const deviceUtil = yield getContext('deviceUtil');
        const contentDetails = yield call(getContentDetails, {
            contentId,
            deviceUtil,
        });
        const payload = {
            [contentId]: {
                ...contentDetails,
                error: null,
                fetching: false,
            },
            playableId: contentDetails.playableId,
        };
        
        yield put(contentDetailsFetchCompletedAction({ payload }));
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            [contentId]: {
                error,
                fetching: false,
            },
        };

        yield put(contentDetailsFetchErrorAction({ payload }));
    }
}

function* fetchSeriesDetails(action) {
    const { contentId, seasonId } = action;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const contentDetails = yield call(getSeriesDetails, {
            contentId,
            deviceUtil,
        });
        const {
            seriesTvSeasons,
        } = contentDetails;
        let allContentDetails = {};
        const season = seriesTvSeasons?.length && seriesTvSeasons;
        allContentDetails = {
            [contentId]: contentDetails,
            seasons: seriesTvSeasons,
            contentId,
        };
        const seasonID = seasonId || season?.[0]?.seasonId;
        // allContentDetails[seasonID] = seasonId || season[0];
        allContentDetails.currentSeasonId = seasonID;
        // adding has self sufficient data to solve : https://bsbportal.atlassian.net/browse/ATVWEB-1054
        // hasSelfSufficientContent means has its default show/season/episode
        // allContentDetails[contentId].hasSelfSufficientContent = true;

        yield put(contentDetailsFetchCompletedAction({ payload: allContentDetails }));
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error.errorcode && error.errorcode === ERROR_CODES.ATV999) {
            error = { errorcode: ERROR_CODES.LOCAL1009 };
        }
        else if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            [contentId]: {
                error,
                fetching: false,
            },
        };

        if (error) {
            ErrorHandler({
                code: error.errorcode,
                errorTitle: error.appErrorMessage,
                error,
            });
        }

        yield put(contentDetailsFetchErrorAction({ payload }));
    }
}

function* fetchEpisodeTabs(action) {
    const { seasonId, tab, isNextTab, previousTab } = action;
    let previousTabContentDetails;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const contentDetails = yield call(getepisodeTabs, {
            seasonId,
            tab,
            deviceUtil,
        });
        if(previousTab) {
            previousTabContentDetails = yield call(getepisodeTabs, {
                seasonId,
                tab: previousTab,
                deviceUtil,
            });
        } 
        const {
            tabs,
            episodeRefs,
            currentTab,
        } = contentDetails;
        const allContentDetails = !previousTab ? {
            [seasonId]: contentDetails,
            tabs,
            currentSeasonId: seasonId,
            [currentTab]: episodeRefs,
        }: {
            [seasonId]: contentDetails,
            tabs,
            currentSeasonId: seasonId,
            [currentTab]: episodeRefs,
            [previousTab]: previousTabContentDetails.episodeRefs,
        };

        if(previousTab) {
            allContentDetails.previousTab = previousTab;
        } else {
            allContentDetails.previousTab = '';
        }

        if (isNextTab) {
            allContentDetails.nextTab = currentTab;
        }
        else {
            allContentDetails.currentTab = currentTab;
        }
        // adding has self sufficient data to solve : https://bsbportal.atlassian.net/browse/ATVWEB-1054
        // hasSelfSufficientContent means has its default show/season/episode
        if (allContentDetails && allContentDetails[seasonId]) {
            allContentDetails[seasonId].hasSelfSufficientContent = true;
        }
        yield put(contentDetailsFetchCompletedAction({ payload: allContentDetails })); 
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error.errorcode && error.errorcode === ERROR_CODES.ATV999) {
            error = { errorcode: ERROR_CODES.LOCAL1009 };
        }
        else if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            [seasonId]: {
                error,
                fetching: false,
            },
        };

        if (error) {
            ErrorHandler({
                code: error.errorcode,
                errorTitle: error.appErrorMessage,
                error,
            });
        }
        yield put(contentDetailsFetchErrorAction({ payload }));
    }
}

//NOTE: this saga fetches unique tab from episode Id and seasonId and renders the correct data on screen
function* fetchParticularEpisodeTab(action) {
    const { seasonId, playId, episodeId, history, location } = action;

    try {
        const deviceUtil = yield getContext('deviceUtil');
        yield call(fetchContentDetails, {contentId: episodeId});
        const storeContentDetails = yield select(getContentDetailsDataFromReducer)
        const episodeNum = storeContentDetails[episodeId]?.episodeNum
        const firstTabSeasonDetails = yield call(getepisodeTabs, {
            seasonId,
            deviceUtil,
        });
        let seasonTabs = [...firstTabSeasonDetails?.tabs];
        if(seasonTabs.length) {
          seasonTabs = seasonTabs.map((tab) => tab.split(' - '));
        }

        const episodeTabIndex = seasonTabs.findIndex((tab) => ((episodeNum-Math.max(tab[0], tab[1]))*(episodeNum- Math.min(tab[0], tab[1])) <= 0));
        //below one is a simple comparision, used this approach as BE keeps changing min and max
        //episodeNum >= +tab[0] && episodeNum <= +tab[1]
        yield call(fetchEpisodeTabs, {
            seasonId,
            tab:firstTabSeasonDetails?.tabs[episodeTabIndex],
            deviceUtil,
        });
        if(playId && episodeId !== playId) {
            yield call(fetchContentDetails, {contentId: playId});
        }
        // yield put(setCurrentTab({currentTab: firstTabSeasonDetails?.tabs[episodeTabIndex]}))
        // const params = {
        //     currentTab: firstTabSeasonDetails?.tabs[episodeTabIndex]
        // };
        // history.replace({
        //     pathname: `${location.pathname}`,
        //     search: `${LocationUtil.objectToQueryParams(params)}`,
        // });

        // const contentDetails = yield call(getepisodeTabs, {
        //     seasonId,
        //     deviceUtil,
        // });
        // const {
        //     tabs,
        //     episodeRefs,
        //     currentTab,
        // } = contentDetails;
        // const allContentDetails = {
        //     [seasonId]: contentDetails,
        //     tabs,
        //     currentSeasonId: seasonId,
        //     [currentTab]: episodeRefs,
        // };
        // if (isNextTab) {
        //     allContentDetails.nextTab = currentTab;
        // }
        // else {
        //     allContentDetails.currentTab = currentTab;
        // }
        // // adding has self sufficient data to solve : https://bsbportal.atlassian.net/browse/ATVWEB-1054
        // // hasSelfSufficientContent means has its default show/season/episode
        // if (allContentDetails && allContentDetails[seasonId]) {
        //     allContentDetails[seasonId].hasSelfSufficientContent = true;
        // }
        // yield put(contentDetailsFetchCompletedAction({ payload: allContentDetails }));
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error.errorcode && error.errorcode === ERROR_CODES.ATV999) {
            error = { errorcode: ERROR_CODES.LOCAL1009 };
        }
        else if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            [seasonId]: {
                error,
                fetching: false,
            },
        };

        if (error) {
            ErrorHandler({
                code: error.errorcode,
                errorTitle: error.appErrorMessage,
                error,
            });
        }

        yield put(contentDetailsFetchErrorAction({ payload }));
    }
}


// NOTE: This saga get first episode data of a season if seasonId is given else take data of first season
function* seriesDetailsAndTabFetch(action) {
    const { contentId, seasonId } = action.payload;

   try {
        yield call(fetchSeriesDetails, {contentId, seasonId});
        const storeContentDetails = yield select(getContentDetailsDataFromReducer);
        yield call(fetchEpisodeTabs, {seasonId: seasonId || storeContentDetails[contentId]?.seriesTvSeasons[0]?.seasonId})
   } catch(e) {
        console.log(e);
   }    

}

function* fetchSeasonNextEpisodeTabData(action) {
    const { seasonId, episodeId } = action.payload;

    try {
        const deviceUtil = yield getContext('deviceUtil');
        const storeContentDetails = yield select(getContentDetailsDataFromReducer)
        const episodeNum = storeContentDetails[episodeId]?.episodeNum
        const firstTabSeasonDetails = yield call(getepisodeTabs, {
            seasonId,
            deviceUtil,
        });
        let seasonTabs = [...firstTabSeasonDetails?.tabs];
        if(seasonTabs.length) {
          seasonTabs = seasonTabs.map((tab) => tab.split(' - '));
        }
        const episodeTabIndex = seasonTabs.findIndex((tab) => ((episodeNum - Math.max(tab[0], tab[1]))*(episodeNum - Math.min(tab[0], tab[1])) <= 0));
        
        //below one is a simple comparision, used this approach as BE keeps changing min and max
        //episodeNum >= +tab[0] && episodeNum <= +tab[1]

        yield call(fetchEpisodeTabs, {
            seasonId,
            tab:firstTabSeasonDetails?.tabs?.[episodeTabIndex],
            deviceUtil,
        });
        // if(firstTabSeasonDetails.tabs.length > 1) {
        //     const isAscendingOrder = episodeTabIndex === 0;
        //     yield put(contentDetailsFetchCompletedAction({ payload: {isAscendingOrder} }));
        // }

        // yield put(setCurrentTab({currentTab: firstTabSeasonDetails?.tabs[episodeTabIndex]}))
        // const params = {
        //     currentTab: firstTabSeasonDetails?.tabs[episodeTabIndex]
        // };
        // history.replace({
        //     pathname: `${location.pathname}`,
        //     search: `${LocationUtil.objectToQueryParams(params)}`,
        // });

        // const contentDetails = yield call(getepisodeTabs, {
        //     seasonId,
        //     deviceUtil,
        // });
        // const {
        //     tabs,
        //     episodeRefs,
        //     currentTab,
        // } = contentDetails;
        // const allContentDetails = {
        //     [seasonId]: contentDetails,
        //     tabs,
        //     currentSeasonId: seasonId,
        //     [currentTab]: episodeRefs,
        // };
        // if (isNextTab) {
        //     allContentDetails.nextTab = currentTab;
        // }
        // else {
        //     allContentDetails.currentTab = currentTab;
        // }
        // // adding has self sufficient data to solve : https://bsbportal.atlassian.net/browse/ATVWEB-1054
        // // hasSelfSufficientContent means has its default show/season/episode
        // if (allContentDetails && allContentDetails[seasonId]) {
        //     allContentDetails[seasonId].hasSelfSufficientContent = true;
        // }
        // yield put(contentDetailsFetchCompletedAction({ payload: allContentDetails }));
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error.errorcode && error.errorcode === ERROR_CODES.ATV999) {
            error = { errorcode: ERROR_CODES.LOCAL1009 };
        }
        else if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            [seasonId]: {
                error,
                fetching: false,
            },
        };

        if (error) {
            ErrorHandler({
                code: error.errorcode,
                errorTitle: error.appErrorMessage,
                error,
            });
        }

        yield put(contentDetailsFetchErrorAction({ payload }));
    }   

}
 
// function* fetchTabDetails(action) {
//     const { seasonId } = action;

//     try {
//         const deviceUtil = yield getContext('deviceUtil');
//         const contentDetails = yield call(getTabDetails, {
//             seasonId,
//             deviceUtil,
//         });

//         const {
//             season,
//         } = contentDetails;

//         const allContentDetails = {
//         //     [season.id]: {
//         //         ...season,
//         //         error: null,
//         //         fetching: false,
//         //     },
//         };

//         // adding has self sufficient data to solve : https://bsbportal.atlassian.net/browse/ATVWEB-1054
//         // hasSelfSufficientContent means has its default show/season/episode
//         // allContentDetails[seasonId].hasSelfSufficientContent = true;


//         yield put(contentDetailsFetchCompletedAction({ payload: allContentDetails }));
//     }
//     catch (e) {
//         console.error(e);
//         let error = e && e.data ? e.data : e;

//         if (error.errorcode && error.errorcode === ERROR_CODES.ATV999) {
//             error = { errorcode: ERROR_CODES.LOCAL1009 };
//         }
//         else if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
//             error = { errorcode: ERROR_CODES.LOCAL1002 };
//         }

//         const payload = {
//             [seasonId]: {
//                 error,
//                 fetching: false,
//             },
//         };

//         yield put(contentDetailsFetchErrorAction({ payload }));
//     }
// }

export default [
    takeEvery(ContentDetailsActions.CONTENT_DETAIlS_FETCH, fetchContentDetails),
    takeEvery(ContentDetailsActions.SERIES_DETAIlS_FETCH, fetchSeriesDetails),
    takeEvery(ContentDetailsActions.FETCH_EPISODE_TABS, fetchEpisodeTabs),
    takeEvery(ContentDetailsActions.FETCH_PARTICULAR_EPISODE_TAB, fetchParticularEpisodeTab),
    takeEvery(ContentDetailsActions.SERIES_DETAILS_AND_TAB_FETCH, seriesDetailsAndTabFetch),
    takeEvery(ContentDetailsActions.FETCH_SEASON_NEXT_EPISODES, fetchSeasonNextEpisodeTabData),
    // takeEvery(ContentDetailsActions.TAB_DETAILS_FETCH, fetchTabDetails),
];
