import React, { PureComponent } from 'react';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import PlaybackContainer from '@airtel-feature/playback/PlaybackContainer';
import DescriptionComponent from '../components/DescriptionComponent';
import MarkupFactory from '../../../../web/src/modules/watch-actions/factories/MarkupFactory';
import CastComponent from '../components/CastComponent';
import {
    RELATION_TYPES,
    PROGRAM_TYPES,
    ROLE_TYPES,
    INTERSECTION_OBSERVER_THRESHOLD,
    RESIZE_OPTION,
    DIMENSION_PAGE_MAP,
} from '@airtel-tv/constants';
import RelatedContentContainer from '../../../../web/src/modules/rail/containers/RelatedContentContainer';
import { changeMetaData } from '../../../../web/src/modules/notify/NotifyActions';
import {
    secondsTohhhmmm,
} from '@airtel-tv/utils/GlobalUtil';
import { getSocialShareInfo, getCDPFooterContent, getLangfromCode, getPageSeoMeta } from '@airtel-tv/utils/ContentMetaUtil';
import {RoutingUtil, LanguageProviderUtil, getPosterUrlFromContentDetails, actorDirectorParser, BreadCrumbsUtil, withDeviceUtil } from '@airtel-tv/utils';
import { LinkComponent } from '@airtel-tv/ui-lib/atoms/link/LinkComponent';
import { SmallLoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/SmallLoaderComponent';
import { IntersectionObservableHOC } from '@airtel-tv/lib';
import { updatePageBreadCrumbs } from '@airtel-tv/ui-lib/molecules/breadcrumbs/actions/BreadCrumbsActions';
import { getInfo } from '../builders/InfoBuilder';
import { updateOnPageDescriptionAction } from '../../../../web/src/modules/footer/actions/FooterAction';

class VodDetailContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            isRecommendedRailVisible: this.deviceUtil.isBot(),
        };
    }

    componentDidMount() {
        this.updateSeoMeta();
    }

    updateSeoMeta = () => {
        const {
            contentDetails,
            changeMetaDataDispatch,
            updatePageBreadCrumbsDispatch,
            cpDetailsById,
            updateOnPageDescriptionActionDispatch,
            customCdpContent = {}
        } = this.props;

        const {
            title, description, programType, languages, id, credits, genres, cpId, durSec, downloadable
        } = contentDetails;
        let { actorsCatalogue } = actorDirectorParser(credits);
        let content;
        if(customCdpContent[id]) {
            let {seoFooterContent = ''}  = customCdpContent[id];
            content = seoFooterContent;
        } else {
            let actorsForFooter = (credits || []).map(actor => {
                let actorUrl =  RoutingUtil.getArtistUrlWeb({
                    name: actor.displayTitle,
                    id: actor.id,
                });
                return {
                    ...actor,
                    actorUrl
                }
            });

            let languageMap = languages.map(item => ({
                name: getLangfromCode({ lang: item }),
                url: RoutingUtil.getDimensionPageUrlV2({
                    pageTitle: DIMENSION_PAGE_MAP[programType],
                    lang: getLangfromCode({ lang: item })
                })
            }));

            let genreMap = genres.map(item => ({
                name: item,
                url: RoutingUtil.getDimensionPageUrlV2({
                    pageTitle: DIMENSION_PAGE_MAP[programType],
                    genres: item
                })
            }));

            const duration = durSec ? secondsTohhhmmm(durSec): '';

            const channelPartnerName = lodashGet(cpDetailsById[cpId], 'title', '');
            const pageLink = lodashGet(cpDetailsById[cpId], 'pageLink', '');

            const channelUrl = RoutingUtil.getChannelPartnerPageUrl({
                channelPartnerName,
                pageLink,
            })

            content = getCDPFooterContent({ 
                programType, 
                actorsForFooter,
                channelPartnerName,
                contentTitle: title,
                languageMap,
                genreMap,
                duration,
                channelUrl,
                baseUrl: this.deviceUtil.getBaseUrl(),
                downloadable
            });
        }
        actorsCatalogue = actorsCatalogue && actorsCatalogue.length > 0 ? actorsCatalogue.map(actor => actor.name) : [];

        const metaLanguage = languages && languages[0] ? languages[0] : '';

        const genreForMeta = genres.join(' ');
        const actorForMeta = actorsCatalogue.join(', ');
        let headMetaData = getPageSeoMeta({
            programType,
            contentTitle: title,
            contentDescription: description,
            contentLanguage: metaLanguage,
            genreForMeta,
            actorForMeta,
        });

        const curPageLink = RoutingUtil.getContentPlaybackUrlWeb({
            category: programType,
            contentTitle: title,
            contentId: id,
        });
        const breadCrumbsList = BreadCrumbsUtil.moviePageBreadCrumbs({
            contentDetails,
            curPageLink,
        });
        const seoMarkups = MarkupFactory({
            contentDetails,
            baseUrl: this.deviceUtil.getBaseUrl(),
            breadCrumbsList,
        });
        
        if(customCdpContent[id]) {
            let {seoTitle = '', seoDescription = ''}  = customCdpContent[id];
            headMetaData = {
                ...headMetaData,
                title: seoTitle ? seoTitle: headMetaData.title,
                description: seoDescription? seoDescription: headMetaData.description
            }
        }
        changeMetaDataDispatch({
            headMetaData: {
                ...headMetaData,
                image: getPosterUrlFromContentDetails(contentDetails),
                link: curPageLink,
                watchActionCatalogues: seoMarkups,
            },
        });

        if(content) {
            updateOnPageDescriptionActionDispatch(
                {onPageDescription: content,}
            )

        }
        updatePageBreadCrumbsDispatch({ breadCrumbsList });
    };


    findDirectors = (castDatas) => {
        if (!castDatas || !castDatas.length) {
            return null;
        }
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        // getting directors
        const castData = castDatas.filter(
            data => (data?.roleType || '').toLowerCase() === ROLE_TYPES.DIRECTOR,
        );
        if (!castData || !castData.length) {
            return null;
        }
        const directorData = castData.map((casting, index) => (
            <LinkComponent
                key={casting.id}
                to={RoutingUtil.getArtistUrlWeb({
                    name: casting.displayTitle,
                    id: casting.id,
                })}
                title={casting.displayTitle}
            >
                {casting.displayTitle}
                {castData[index + 1] ? ', ' : ''}
            </LinkComponent>
        ));
        return (
            <p>
                {' '}
                {LANGUAGE.DIRECTOR_TITLE}
                :
                <span className="ml-1">
                    {''}
                    {/* {directorData.join(', ')} */}
                    {directorData}
                </span>
            </p>
        );
    };

    showRecommendedRail = () => {
        setTimeout(() => {
            this.setState({
                isRecommendedRailVisible: true,
            });
        }, 1000);
    }

    render() {
        const {
            contentId, contentDetails, match, languageCodesMap, cpDetailsById, allPlans,
        } = this.props;
        const { url: currentUrl } = match;
        const {
            cpId, description, title, shortUrl, langToContentMap,
        } = contentDetails;

        const info = getInfo(contentDetails);
        const directors = this.findDirectors(contentDetails.credits);
        let castList = [];
        if (contentDetails.credits && contentDetails.credits.length > 0) {
            castList = contentDetails.credits.filter(element => element.roleType === ROLE_TYPES.ACTOR);
        }

        const socialShareInfo = getSocialShareInfo({
            programType: contentDetails.programType,
            showName: title,
            currentUrl,
            baseUrl: this.deviceUtil.getBaseUrl(),
        });

        const { isRecommendedRailVisible } = this.state;

        const loaderElem = <SmallLoaderComponent style={{ marginTop: '20px' }} />;

        const recommendedContentElement = (
            isRecommendedRailVisible ? (
                <RelatedContentContainer
                    targetId={contentId}
                    relationType={RELATION_TYPES.VIDEO}
                    programTypes={lodashGet(contentDetails, 'programType', PROGRAM_TYPES.MOVIE)}
                />
            ) : (
                <IntersectionObservableHOC
                    fetchMore={this.showRecommendedRail}
                    hasMore={!isRecommendedRailVisible}
                    loaderElem={loaderElem}
                    startPageNumber={1}
                    threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
                />
            )
        );
        const videoMeta = {
            videoTitle: title,
        };
        const playerElement = (
            <PlaybackContainer
                contentDetails={contentDetails}
                videoMeta={videoMeta}
            />
        );
        const logUrl = lodashGet(cpDetailsById[cpId], 'cpIconURL', '');

        return (
            <>
                <section className="current-vdo pt-2 pt-md-4 pt-sm-4">
                    <div className="container-fluid extended-rail-margin">
                        <div className="row mx-0">
                            {playerElement}
                            <DescriptionComponent
                                logo={logUrl}
                                title={title}
                                allPlans={allPlans}
                                info={info}
                                description={description}
                                shortUrl={shortUrl}
                                directors={directors}
                                socialShareInfo={socialShareInfo}
                                contentDetails={contentDetails}
                                id={contentDetails.id}
                                isBot={this.deviceUtil.isBot()}
                                multiAudio={langToContentMap}
                                languageCodesMap={languageCodesMap}
                                resizeOption={RESIZE_OPTION.TAB_LARGE}
                            />
                        </div>
                    </div>
                </section>
                {castList.length > 0 ? (
                    <div className="container-fluid extended-rail-margin">
                        <CastComponent casts={castList} />
                    </div>
                ) : null}

                {recommendedContentElement}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        appConfig: {
            languageFilters: languageCodesMap,
            cpDetailsById,
            customCdpContent
        },
        allPlans,
    } = state;
    return {
        customCdpContent,
        languageCodesMap,
        cpDetailsById,
        allPlans,
    };
};

VodDetailContainer.defaultProps = {
    languageCodesMap: null,
};

VodDetailContainer.propTypes = {
    contentDetails: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    contentId: PropTypes.string.isRequired,
    changeMetaDataDispatch: PropTypes.func.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    // eslint-disable-next-line react/forbid-prop-types
    match: PropTypes.object.isRequired,
    languageCodesMap: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    updatePageBreadCrumbsDispatch: PropTypes.func.isRequired,
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,

};

export default withRouter(connect(
    mapStateToProps,
    {
        changeMetaDataDispatch: changeMetaData,
        updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
        updateOnPageDescriptionActionDispatch: updateOnPageDescriptionAction
    },
)(withDeviceUtil(VodDetailContainer)));