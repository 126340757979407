import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import EpisodeDropDownComponent from '../EpisodeDropDownComponent/EpisodeDropDownComponent';
import PillComponent from '../PillComponent/PillComponent';
import TileComponent from '../TileComponent/TileComponent';
import { cleanNextTab, episodeTabsFetchAction } from '@airtel-feature/content-details/content-details/actions/ContentDetailsAction';
import { getSourceName } from '@airtel-tv/utils/GlobalUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './EpisodeRailComponent.scss';

class EpisodeRailComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    fetchTabs = (seasonId, tab, isNextTab = false, previousTab) => {
        const { fetchTabsActionDispatch } = this.props;
        this.setState({ isLoading: true });
        fetchTabsActionDispatch({
            seasonId,
            tab,
            isNextTab,
            previousTab
        });
        this.setState({
            isLoading: false,
        });
    }

    render() {
        const {
            contentDetails,
            contentDetails: {
                seasons,
                tabs,
                currentTab,
                currentSeasonId,
            },
            continueWatchingData,
            contentId,
            cleanNextTabDispatch,
            location,
            packageId,
            analyticsPageId,
        } = this.props;
        const episodes = contentDetails[currentTab];
        const { isLoading } = this.state;
        const sourceName = getSourceName(location.pathname);
        if (isLoading) {
            return null;
        }
        return (
            <div className="episode-down-area" id="episodeDownArea">
                { seasons && seasons.length > 1 && (
                    <EpisodeDropDownComponent
                        seasons={seasons}
                        fetchTabs={this.fetchTabs}
                        contentId={contentId}
                        activeSeasonId={currentSeasonId}
                        contentDetails={contentDetails}
                        sourceName={sourceName}
                        tabs={tabs}
                        packageId={packageId}
                    />
                ) }
                { currentSeasonId && tabs && tabs.length > 1 && (
                    <PillComponent
                        tabs={tabs}
                        currentTab={currentTab}
                        seasonId={currentSeasonId}
                        fetchTabs={this.fetchTabs}
                        contentDetails={contentDetails}
                        sourceName={sourceName}
                        cleanNextTabDispatch={cleanNextTabDispatch}
                        packageId={packageId}
                    />
                ) }
                { episodes && episodes.length > 0 && <TileComponent episodes={episodes} continueWatchingData={continueWatchingData} contentDetails={contentDetails} sourceName={sourceName} packageId={packageId} pageId={analyticsPageId} /> }
            </div>
        );
    }
}

EpisodeRailComponent.propTypes = {
    contentId: PropTypes.string.isRequired,
    contentDetails: PropTypes.object.isRequired,
    fetchTabsActionDispatch: PropTypes.func.isRequired,
    continueWatchingData: PropTypes.object.isRequired,
    packageId: PropTypes.string,
};

EpisodeRailComponent.defaultProps = {
    packageId: '',
};

const mapStateToProps = (state) => {
    const {
        contentDetails, syncContent: {
            continueWatchingData,
        },
    } = state;

    return {
        contentDetails,
        continueWatchingData,
    };
};

export default withRouter(connect(mapStateToProps, {
    fetchTabsActionDispatch: episodeTabsFetchAction,
    cleanNextTabDispatch: cleanNextTab,
})(withStyles(styles)(EpisodeRailComponent)));
