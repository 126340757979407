import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import CastDetailComponent from '../CastDetailcomponent/CastDetailcomponent';
import styles from './CastAndMoreComponent.scss';

const CastAndMoreComponent = (props) => {
    useStyles(styles);
    const { tiles } = props;

    const tile = tiles && tiles.length > 0 ? tiles[0] : {};

    const { languageMap } = props;
    return (
        <>
            <CastDetailComponent
                languageMap={languageMap}
                {...tile}
            />
        </>
    );
};

CastAndMoreComponent.defaultProps = {
};

CastAndMoreComponent.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    tiles: PropTypes.array.isRequired,
    pageId: PropTypes.string.isRequired,
    languageMap: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const {
        appConfig,
    } = state;

    return {
        languageMap: appConfig.language_map,
    };
};

export default connect(mapStateToProps, {
})(CastAndMoreComponent);
