import React, {
    Suspense, useEffect, useRef, useState, useCallback,
} from 'react';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import lodashGet from 'lodash/get';
import { PropTypes } from 'prop-types';
import BLOCK_CODES from '@airtel-tv/constants/BlockCodeConst';
import { BROWSER_LIST } from '@airtel-tv/constants/BrowserConst';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { openContentInApp } from '@airtel-tv/services/DeeplinkApiService';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { getSourceName, onInstallClick } from '@airtel-tv/utils/GlobalUtil';
import {
    checkAndAddScript,
} from '@airtel-tv/utils/WindowUtil';
import { ERROR_ACTIONS, ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { ERROR_HANDLING_TYPES } from '@airtel-tv/constants/ErrorConst';
import PlaybackConfigBuilder from '@airtel-feature/playback/builders/PlaybackConfigBuilder';
// import PlaybackConfigBuilder from '../../playback/builders/PlaybackConfigBuilder';
import PlayerFactory from '@airtel-feature/playback/factories/PlayerFactory';
// import PlayerFactory from '../../playback/factories/PlayerFactory';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
// import withDeviceUtil from '../../shared/hoc/WithDeviceUtil';
import { CDP_EVENT_CONST, IMAGE_PATHS, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import AnalyticsHelperV2 from '@airtel-feature/playback/helpers/AnalyticsHelperV2';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import EventType from '@airtel-tv/analytics/EventType';
// import AnalyticsHelperV2 from '../../playback/helpers/AnalyticsHelperV2';
import { STREAM_TYPES } from '@airtel-tv/constants/BrowserConst';
import { DeviceTypeUtil } from '@airtel-tv/utils';
import { showPlayback } from '@airtel-feature/notify/NotifyActions';
import { toggleTrailerPlayback } from '../../../../tv/src/modules/homepage/actions/QuickViewActions';

const TrailerContainer = ({
    controlBar,
    playableId,
    langInfo,
    adsData,
    playbackData,
    authToken,
    deviceUtil,
    previewImageCodeBlocked,
    blockBitRateOnLive,
    videoMeta,
    playbackSessionId,
    playTrailer,
    canPlayTrailer,
    contentDetails,
    flagControl,
    controlBarProps,
    isTrailerTile,
    videoResolutionLimiter,
    useLegacyShakaCps,
    ErrorHandler,
    toggleTrailerPlaybackDispatch,
    isCDP,
    trailerRefId,
    playerCallbackHandler,
    setVideoTagRefNode = null,
    enableNativeControls = false,
    posterUrl,
    onLoadCallback,
    timeUpdateCallback,
    enableAnalyticsOnTile,
    playerStreamingConfig,
    onFirstPlayCallback,
}) => {
    useEffect(() =>
        // checkAndAddScript(
        //     'https://googleads.github.io/videojs-ima/node_modules/video.js/dist/video-js.min.css',
        //     'link',
        // );
        () => {
            toggleTrailerPlaybackDispatch(false);
            if (playerCallbackHandler && typeof playerCallbackHandler === 'function') {
                playerCallbackHandler({
                    isPlayerError: false,
                    isTrailerPlaying: false,
                    isTrailerEnded: false,
                });
            }
        },
    []);

    const playbackConfigParamsRef = useRef();
    const playbackCallInitTimeStampRef = useRef(new Date().getTime());
    const [
        componentState,
        setComponentState,
    ] = useState({
        contentDetails: {},
        playbackDetails: {},
    });
    // const { contentDetails } = componentState;

    const endEvent = useCallback(() => {
        toggleTrailerPlaybackDispatch(false);
        if (playerCallbackHandler && typeof playerCallbackHandler === 'function') {
            playerCallbackHandler({
                isPlayerError: false,
                isTrailerPlaying: false,
                isTrailerEnded: true,
            });
        }
        const {
            programType, id, cpId, tvShowName, title,
        } = contentDetails;
        const meta = {
            action: CDP_EVENT_CONST.TRAILER_END,
            source_name: 'content_detail_page_'.concat(id),
            content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
        };

        if (programType === PROGRAM_TYPES.EPISODE) {
            meta.episode_name = title;
        }
        contentDetailsEvent(meta, EventType.TRAILER_END);
    }, [
        isCDP,
    ]);

    const playerErrorHandling = (error) => {
        console.error(error);
        const err = error.error ? error.error : error;
        if (playerCallbackHandler && typeof playerCallbackHandler === 'function') {
            playerCallbackHandler({
                isPlayerError: true,
                isTrailerPlaying: false,
                isTrailerEnded: false,
            });
        }
        if (!err.code) {
            return;
        }
        const {
            browser, os, isDrm, isMsp,
        } = playbackConfigParamsRef.current || {};
        const parsedError = ErrorHandler({
            error: err,
            code: err.code,
            browser,
            os,
        });
        const { errorData, handledBy, action: errorAction } = parsedError;

        const imageUrl = lodashGet(
            contentDetails,
            [
                'images',
                'LANDSCAPE_169',
            ],
            IMAGE_PATHS.DEFAULT_PLAYER_BACKGROUND,
        );
        const LANGUAGE = LanguageProviderUtil.getLanguage();

        // For handling Safari browser error message
        if (
            BROWSER_LIST.SAFARI === browser
      && isDrm
      && isMsp
      && err.code !== ERROR_CODES.VIDEOJS_BROWSER_NOT_SUPPORTED
        ) {
            errorData.message = LANGUAGE.OPEN_IN_SUPPORTED_BROWSER_OPEN_CHROME;
            errorData.buttonText = LANGUAGE.BUTTON_RETRY;
        }

        if (handledBy === ERROR_HANDLING_TYPES.ERROR_PLAYER_COMPONENT) {
            const errorPlayerHandler = () => {
                switch (errorAction) {
                    case ERROR_ACTIONS.RETRY_PLAYBACK:
                        // reload();
                        break;
                    case ERROR_ACTIONS.MOBILE_APP_PUSH: {
                        if (contentDetails.shortUrl) {
                            openContentInApp({
                                contentIdShortLink: contentDetails.shortUrl,
                                isMobile: deviceUtil.isMobile(),
                            });
                        }
                        else {
                            onInstallClick({
                                clickItem: DOWNLOAD_APP_ACTIONS.COMMON_APP_DOWNLOAD,
                                isPlayerError: true,
                            });
                        }
                        break;
                    }
                    default:
                        // reload();
                        break;
                }
            };
        }
    };

    const onTimeUpdate = (playbackCurrentTime) => {
        if (playerCallbackHandler && typeof playerCallbackHandler === 'function') {
            playerCallbackHandler({
                playbackCurrentTime,
            });
        }
    };

    const browserNotSupported = (error) => {
        playerErrorHandling(error);
    };

    useEffect(() => {
        setComponentState(prev => ({
            ...prev,
            playbackDetails: playbackData[playableId],
        }));
    }, [
        playbackData,
    ]);

    const { playbackDetails } = componentState;

    if (!playbackDetails || !Object.keys(playbackDetails).length || !playableId) {
        return <></>;
    }

    const playbackConfigParams = PlaybackConfigBuilder.buildParams({
        playbackDetails,
        contentDetails,
        authToken,
        deviceUtil,
        playbackSessionId,
        nextEpisodeDetails: null,
        previewImageCodeBlocked,
        blockBitRateOnLive,
        videoMeta,
    });
    playbackConfigParamsRef.current = playbackConfigParams;
    const { playbackType } = playbackConfigParams;
    const playbackConfig = PlaybackConfigBuilder.buildConfig(playbackConfigParams);
    if (useLegacyShakaCps.includes(playbackDetails.cp) && playbackConfig.streamType !== STREAM_TYPES.LIVE) {
        const { additionalProperties: { integratedSplash = 'no' } = {} } = playbackDetails;
        if (integratedSplash.toLowerCase() === 'yes') {
            playbackConfig.useLegacyShaka = false;
        }
        else {
            playbackConfig.useLegacyShaka = true;
        }
    }


    const playerEventsInitialized = async (playerEvents, playerFunctions) => {
        const { playerAnalyticsV2 = false, trailerAutoPlayAnalytics = true } = flagControl;
        if (playerAnalyticsV2 && trailerAutoPlayAnalytics && (!isTrailerTile || enableAnalyticsOnTile)) {
            new AnalyticsHelperV2({
                playerEvents,
                contentDetails: {
                    ...contentDetails,
                    id: playbackDetails.contentId,
                    programType: playbackDetails.contentType,
                },
                playbackDetails,
                playbackCallInitTimeStamp: playbackCallInitTimeStampRef.current,
                playerFunctions,
                sourceName: getSourceName(location.pathname),
                playbackSessionId,
            });
        }
        // let canPlayTrailerCallbck;
        // if(typeof canPlayTrailer === 'function') {
        //     canPlayTrailerCallbck = canPlayTrailer();
        // } else {
        //     canPlayTrailerCallbck = canPlayTrailer;
        // }

        // const callback = await canPlayTrailerCallbck;
        const callback = canPlayTrailer;
        playerEvents?.emitter.on(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, () => {
            callback && typeof callback === 'function' && callback(playerFunctions);
        });
        playerEvents?.emitter.on(PLAYER_EVENTS_LIST.LOADED, () => {
            callback && typeof callback === 'function' && callback(playerFunctions);
        });


        playerEvents?.emitter.on(PLAYER_EVENTS_LIST.LOADED, playEvent);
        playerEvents?.emitter.on(PLAYER_EVENTS_LIST.ENDEND, endEvent);
        playerEvents?.emitter.on(PLAYER_EVENTS_LIST.VOLUME_CHANGE, volumeChangeEvent);
        playerEvents?.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, onTimeUpdate);
        playerEvents?.emitter.on(PLAYER_EVENTS_LIST.FIRST_PLAY, (meta) => {
            onFirstPlayCallback && onFirstPlayCallback(meta);
            if (playerCallbackHandler && typeof playerCallbackHandler === 'function') {
                playerCallbackHandler({
                    isPlayerError: false,
                    isTrailerPlaying: true,
                    isTrailerEnded: false,
                });
            }
        });
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ERROR_CDN_RETRY, (error) => {
            playerEvents.emit(PLAYER_EVENTS_LIST.ERROR, error);
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, (playbackCurrentTime) => {
            timeUpdateCallback && timeUpdateCallback(playbackCurrentTime);
        });
    };

    const playEvent = (data) => {
        onLoadCallback && onLoadCallback(data);
        const {
            programType, id, cpId, tvShowName, title,
        } = contentDetails;
        const sourceName = getSourceName(location.pathname);
        const meta = {
            action: CDP_EVENT_CONST.TRAILER_VIEW,
            source_name: sourceName,
            content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
        };

        if (programType === PROGRAM_TYPES.EPISODE) {
            meta.episode_name = title;
        }
        contentDetailsEvent(meta, EventType.TRAILER_VIEW);
        if (playerCallbackHandler && typeof playerCallbackHandler === 'function') {
            playerCallbackHandler({
                meta: data,
            });
        }
    };


    const volumeChangeEvent = () => {
        const {
            programType, id, cpId, tvShowName, title,
        } = contentDetails;
        const videoTag = document.getElementById('video-box');
        const eventType = videoTag?.volume === 0 ? 'TRAILER_MUTE' : 'TRAILER_UNMUTE';
        const meta = {
            action: CDP_EVENT_CONST[eventType],
            source_name: 'content_detail_page_'.concat(id),
            content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
        };
        if (programType === PROGRAM_TYPES.EPISODE) {
            meta.episode_name = title;
        }
        contentDetailsEvent(meta, EventType[eventType]);
    };

    const playerElement = (
        <PlayerFactory
            playbackType={playbackType}
            reload={() => {}}
            playbackConfig={{
                ...playbackConfig,
                setVideoTagRefNode,
                videoResolutionLimiter,
                enableNativeControls,
                posterUrl,
            }}
            playerStreamingConfig={playerStreamingConfig || {}}
            playbackDetails={playbackDetails}
            browserNotSupported={browserNotSupported}
            playerEventsInitialized={playerEventsInitialized}
            adsData={adsData}
            langInfo={langInfo}
            ControlBar={controlBar}
            contentDetails={contentDetails}
            playTrailer={playTrailer}
            // bufferingGoal={1}
            isTrailerTile={isTrailerTile}
            contentAutoplay
            controlBarProps={controlBarProps}
            hideControlsOnIdleStateOnly={deviceUtil.isMobile()}
            trailerRefId={trailerRefId}
            hideAdContainer
        />
    );

    const content = (
        <Suspense fallback={<></>}>
            {playerElement}
        </Suspense>
    );

    return content;
};


TrailerContainer.propTypes = {
    controlBar: PropTypes.func.isRequired,
    playableId: PropTypes.string.isRequired,
    langInfo: PropTypes.any,
    adsData: PropTypes.object.isRequired,
    playbackData: PropTypes.any.isRequired,
    authToken: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired,
    previewImageCodeBlocked: PropTypes.bool.isRequired,
    blockBitRateOnLive: PropTypes.bool.isRequired,
    videoMeta: PropTypes.shape({
        videoTitle: PropTypes.string,
        subTitle: PropTypes.string,
    }),
    playbackSessionId: PropTypes.any,
    playTrailer: PropTypes.bool.isRequired,
    trailerRefId: PropTypes.string,
};


TrailerContainer.propTypes = {
    controlBar: PropTypes.func.isRequired,
    playableId: PropTypes.string.isRequired,
    trailerRefId: PropTypes.string,
    langInfo: PropTypes.any,
    adsData: PropTypes.object.isRequired,
    playbackData: PropTypes.any.isRequired,
    authToken: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired,
    previewImageCodeBlocked: PropTypes.bool.isRequired,
    blockBitRateOnLive: PropTypes.bool.isRequired,
    videoMeta: PropTypes.shape({
        videoTitle: PropTypes.string,
        subTitle: PropTypes.string,
    }),
    playbackSessionId: PropTypes.any,
    playTrailer: PropTypes.bool.isRequired,
    enableAnalyticsOnTile: PropTypes.bool,
};

TrailerContainer.defaultProps = {
    loginTriggered: false,
    enableAnalyticsOnTile: false,
    lang: [],
    nextEpisodeDetails: null,
    videoMeta: {
        videoTitle: '',
        subTitle: '',
    },
};

const mapStateToProps = (state, props) => {
    const {
        contentDetails: contentCollection,
        appConfig,
        authConfig,
        authConfig: { loginTriggered },
    } = state;
    const {
        useLegacyShakaCps = [
            'SONYLIV_VOD',
        ],
    } = appConfig;
    const { blockFeature } = appConfig;
    const previewImageCodeBlocked = lodashGet(
        blockFeature,
        BLOCK_CODES.PREVIEW_IMAGE,
        true,
    );
    const blockBitRateOnLive = lodashGet(
        blockFeature,
        BLOCK_CODES.BIT_RATE_FOR_LIVE,
        true,
    );

    return {
        adsData: appConfig.adsData,
        langInfo: appConfig.web_languages_codes,
        flagControl: appConfig.flagControl || {},
        isAuthenticated: authConfig.isAuthenticated,
        authToken: authConfig.token,
        contentCollection,
        previewImageCodeBlocked,
        blockBitRateOnLive,
        playbackSessionId: AuthenticationUtil.generateSessionId(),
        loginTriggered,
        logoutTriggered: authConfig.logoutTriggered,
        useLegacyShakaCps,
    };
};

export default connect(mapStateToProps, {
    toggleTrailerPlaybackDispatch: toggleTrailerPlayback,
    showPlaybackDispatch: showPlayback,
})(withDeviceUtil(withRouter(TrailerContainer)));
