import React from 'react';
import PropTypes from 'prop-types';
import { LinkComponent } from '@airtel-tv/ui-lib/atoms/link/LinkComponent';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { ProgressBarComponent } from '@airtel-tv/ui-lib/molecules/ProgressBarComponent/ProgressBarComponent';
import { LockOverlayComponent } from '@airtel-tv/ui-lib/molecules/overlay/LockOverlayComponent';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { isContentLockedHOC } from '@airtel-tv/lib/hoc/isContentLockedHOC';

const EpisodeListComponent = ({
    episodeUrl,
    episode,
    isPlaying,
    // formattedDate,
    showProgressBar,
    progressPercentage,
    isLocked,
    onSubscribeButtonClick,
    contentDetails,
}) => {
    const LANGUAGE = LanguageProviderUtil.getLanguage();

    const hoverLayer = !isLocked ? (
        <div className="overlay">
            <div className="card-center-play-icon">
                <i className="icon40 icon-player-play" />
            </div>
        </div>
    ) : (
        <LockOverlayComponent
            contentDetails={contentDetails}
            onBtnClick={onSubscribeButtonClick}
        />
    );

    return (

        <div className="grid-col position-relative">
            <LinkComponent
                to={episodeUrl}
                title={episode.name}
            >
                <div
                    className={`grid-img ${isPlaying ? 'now-playing' : ''}`}
                    style={{ '--aspect-ratio': 0.56 }}
                >
                    <ThumborImage
                        src={
                            episode.images
                                ? episode.images.LANDSCAPE_169 || episode.images.PORTRAIT
                                : ''
                        }
                        alt={episode.name}
                        className="w-100 img-fluid"
                    />
                    <ProgressBarComponent
                        progressPercentage={progressPercentage}
                        showProgressBar={showProgressBar}
                    />
                    {hoverLayer}
                    {/* {!isPlaying ? null : (
                        <span className="now-playing">{LANGUAGE.LABEL_NOW_PLAYING}</span>
                    )} */}
                </div>

                <div className="listFooter w-100 d-inline-block mt-0 mt-md-2 mt-sm-2">
                    <p className="w-100 d-inline-block text-truncate card-title-text">
                        {`${LANGUAGE.EPISODE} ${episode.episodeNumber}`}
                    </p>
                    <h2 className="w-100 d-inline-block text-truncate">
                        {episode.name}
                    </h2>
                </div>
            </LinkComponent>
        </div>
    );
};

EpisodeListComponent.defaultProps = {
    isPlaying: false,
    isLocked: false,
    // formattedDate: {},
};

EpisodeListComponent.propTypes = {
    episodeUrl: PropTypes.string.isRequired,
    episode: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types,
    isPlaying: PropTypes.bool,
    progressPercentage: PropTypes.number.isRequired,
    showProgressBar: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool,
    onSubscribeButtonClick: PropTypes.func.isRequired,
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types,
    // formattedDate: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
};

export default isContentLockedHOC(EpisodeListComponent);
