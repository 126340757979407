/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
// import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
// import RoutingUtil from '../../../utilities/RoutingUtil';
// import { contentDetailsEvent } from '../../analytics/FunctionalEvents';
// import { CDP_EVENT_CONST } from '../../../constants/AppConst';
// import { getSourceName } from '../../../utilities/CommonUtil';
// import EventType from '../../analytics/EventType';
import { TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import getTile from '../../../../../web/src/modules/rail/factories/TileFactory';
// import getTile from '../../rail/factories/TileFactory';
import HorizontalLazyLoaderHOC from '../../../../../web/src/modules/shared/hoc/HorizontalLazyLoaderHOC';
import styles from '../CastAndMoreComponent/CastAndMoreComponent.scss';

const StarringRailLayoutComponent = (props) => {
    const [
        hoveredTile,
        setHoveredTile,
    ] = useState('');
    const LANGUAGE = LanguageProviderUtil.getLanguage();

    const {
        tiles = [], railCtaActionUrl, railIndex, imageSettings, railId, packageId, pageId,
    } = props;

    let lazyTiles = [];
    if (!tiles || tiles.length <= 0) {
        return null;
    }
    const tileElements = tiles.map((tile, i) => {
        const tileProps = {
            key: `${tile.id}-${i}`,
            ...tile,
            railCtaActionUrl,
            railPosition: railIndex,
            tilePosition: i,
            railId,
            imageSettings,
            tileType: TILE_TYPES.PEOPLE_RAIL,
            pageId,
            hoveredTile,
            setHoveredTile,
            packageId,
        };
        const tileElement = getTile({
            tileProps,
        });
        return tileElement;
    });
    const key = `${railId}-${railIndex}`;
    lazyTiles = (
        <HorizontalLazyLoaderHOC
            railKey={key}
            tileElements={tileElements}
        />
    );


    const openAllTiles = () => {
        const { history } = props;
        history.push({
            pathname: railCtaActionUrl,
        });
    };
    const { STARRING = 'Starring', SEE_ALL = 'See all' } = LANGUAGE;

    return (
        <>
            <div className="staring-rail-wrapper">
                <div className="title-holder">
                    <h1 className="title">{STARRING}</h1>
                    {railCtaActionUrl !== null && packageId && packageId !== '' && <AnalyticsButtonComponent onClick={openAllTiles}>{SEE_ALL}</AnalyticsButtonComponent> }
                </div>
                <div className="staring-rail">
                    {lazyTiles}
                </div>
            </div>
        </>
    );
};

StarringRailLayoutComponent.propTypes = {
    contentDetails: PropTypes.object,
    tiles: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    railCtaActionUrl: PropTypes.string,
    packageId: PropTypes.string,
};

StarringRailLayoutComponent.defaultProps = {
    contentDetails: {},
    railCtaActionUrl: '',
    packageId: '',
};

export default withRouter(withStyles(styles)(StarringRailLayoutComponent));
