import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';
import styles from './TabContainer.scss';

const TabContainer = (props) => {
    const {
        className,
        onKeyDown,
        tiles = [],
        autoScroll,
        setActiveTab,
        cdpActiveTabInitial = 0,
        cdpTabOpen,
    } = props;
    const {
        CDP_TABS_ID, CDP_TABS_ACTIVE_CLASS, CDP_TABS_CLASS,
    } = ELEMENT_ID;

    const [
        activeTabState,
        setActiveTabState,
    ] = useState(cdpActiveTabInitial);
    const onTabPressed = ({ index, isFocus }) => {
        setActiveTabState(index);
        setActiveTab({
            index,
            isFocus,
        });
        autoScroll();
    };
    console.log('tabcontainer rerendered')

    return (
        tiles.map((tile, index) => {
            const id = `${CDP_TABS_ID.replace('{index}', index)}`;
            const {
                title, hydrationAdditionalQueryParams: {
                    pageId = '',
                } = {},
            } = tile;
            const focusLeftElement = `${CDP_TABS_ID.replace('{index}', index - 1)}`;
            const focusRightElement = `${CDP_TABS_ID.replace('{index}', index + 1)}`;
            return (
                <AnalyticsButtonComponent
                    id={id || pageId}
                    focusLeftElement={focusLeftElement}
                    focusRightElement={focusRightElement}
                    onFocus={() => onTabPressed({
                        index,
                        isFocus: true,
                        tile,
                    })}
                    onClick={() => onTabPressed({
                        index,
                        isFocus: false,
                        tile,
                    })}
                    onKeyDown={({ event }) => onKeyDown({
                        event,
                        index,
                    })}
                    clickEventOnfocus
                    meta={{
                        action: `${title} click`,
                        asset_name: title,
                        rail_type: 'MULTILIST_RAIL',
                    }}
                    className={`${CDP_TABS_CLASS} dark-pills ${className} ${activeTabState === index ? CDP_TABS_ACTIVE_CLASS : ''}`}
                >
                    { title }
                </AnalyticsButtonComponent>
            );
        })
    );
};


TabContainer.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    tile: PropTypes.object.isRequired,
    title: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    contentDetails: PropTypes.object,
    layoutFetchActionDispatch: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    contentId: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    layoutDetails: PropTypes.object,
    className: PropTypes.string,
    autoScroll: PropTypes.func,
    dataSnDown: PropTypes.string,
    dataSnLeft: PropTypes.string,
    dataSnRight: PropTypes.string,
    id: PropTypes.string,
    index: PropTypes.number,
    onKeyDown: PropTypes.func,
};

TabContainer.defaultProps = {
    title: '',
    layoutDetails: {},
    contentDetails: {},
    className: '',
    autoScroll: () => {},
    dataSnDown: '',
    dataSnLeft: '',
    dataSnRight: '',
    id: '',
    index: 0,
    onKeyDown: () => {},
};

export default withStyles(styles)(memo(TabContainer));
