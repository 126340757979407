import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LanguageProviderUtil, RoutingUtil, withDeviceUtil } from '@airtel-tv/utils';
import { layoutFetchAction } from '@airtel-feature/layout/actions/LayoutActions';
import { getPackageContentByLayout, getPackIdsFromLayout } from '@airtel-feature/layout/helpers/LayoutHelper';
import ErrorHandler from '../../../../web/src/modules/error-handler/ErrorHandler';
import { ERROR_SOURCES } from '@airtel-tv/constants/ErrorCodes';
import { PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { isObjEmpty } from '@airtel-tv/utils/GlobalUtil';
import {  
    getSocialShareInfo,
} from '@airtel-tv/utils/ContentMetaUtil'
import { packagePartialFetchAction } from '@airtel-feature/layout/actions/PackageActions';
import LayoutUtil from '@airtel-feature/layout/utils/LayoutUtil';
import WidgetComponent from '../../../../web/src/modules/channel-partner/components/WidgetComponent';
import { ResizeHOC } from '@airtel-tv/lib';
import { getPosterUrlFromContentDetails } from '@airtel-tv/utils';
import { changeMetaData } from '../../../../web/src/modules/notify/NotifyActions';

class EventDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.deviceUtil = props.deviceUtil;
        this.state = {};
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { pageId } = nextProps;
        const {
            layoutPackages,
            packageContents,
            layoutError,
        } = nextProps;


        const contentForLayoutPackages = getPackageContentByLayout(layoutPackages, packageContents);
        const newContentAvailable = !lodashIsEqual(contentForLayoutPackages, prevState.contentForLayoutPackages);
        if (
            (pageId !== prevState.pageId
            || layoutPackages !== prevState.layoutPackages
            || newContentAvailable
            || layoutError !== prevState.layoutError)
        ) {
            return {
                pageId,
                layoutPackages,
                contentForLayoutPackages,
                layoutError,
            };
        }

        return null;
    }

    componentDidMount() {
        this.init();
        this.ErrorHandle();
        this.updateSeoMeta();
    }

    componentDidUpdate() {
        this.ErrorHandle();
    }

    init = () => {
        const { packageFetchDispatch } = this.props;
        const { layoutPackages } = this.props;
        // if (!layoutPackages || (Object.keys(layoutPackages).length < 1)) {
        //     layoutFetchActionDispatch({
        //         pageId,
        //     });
        // }

        const packageIds = getPackIdsFromLayout(layoutPackages);
        packageFetchDispatch({
            packageIds,
            offset: 0,
        });
    }

    updateSeoMeta = () => {
        const {
            seoTitle, seoDescription, changeMetaDataDispatch, contentDetails, pageId,
        } = this.props;
        const { title } = contentDetails;
        const headMetaData = {
            title: seoTitle,
            description: seoDescription,
        };

        changeMetaDataDispatch({
            headMetaData: {
                ...headMetaData,
                image: getPosterUrlFromContentDetails(contentDetails),
                link: RoutingUtil.getEventsPageUrl({
                    eventName: title,
                    eventId: pageId,
                }),
            },
        });
    }

    ErrorHandle = () => {
        const {
            layoutError,
            layoutPackages,
        } = this.state;

        if (layoutError && !isObjEmpty(layoutError) && layoutPackages && Object.keys(layoutPackages).length < 1) {
            ErrorHandler({
                error: layoutError,
                code: layoutError.errorcode,
                source: ERROR_SOURCES.LAYOUT_CONTAINER,
            });
        }
    }

    render() {
        const {
            layoutPackages, pageId, contentForLayoutPackages,
        } = this.state;
        const { contentDetails, isMaxTabWidth } = this.props;
        const { title } = contentDetails;
        const widgetDetails = {
            ds: lodashGet(contentDetails, 'description', ''),
            widgetType: lodashGet(contentDetails, 'widgetType', ''),
        };

        const isBot = this.deviceUtil.isBot();
        const socialShareInfo = getSocialShareInfo({
            programType: PROGRAM_TYPES.EVENT,
            showName: contentDetails.title,
            currentUrl: RoutingUtil.getEventsPageUrl({
                eventName: title,
                eventId: pageId,
            }),
            baseUrl: this.deviceUtil.getBaseUrl(),
        });
        const railElements = LayoutUtil.getRailElement({
            paginatedData: layoutPackages,
            contentForLayoutPackages,
            pageId,
        });
        return (
            <div>
                <WidgetComponent
                    contentDetails={contentDetails}
                    widgetDetails={widgetDetails}
                    isMaxTabWidth={isMaxTabWidth}
                    socialShareInfo={socialShareInfo}
                    isBot={isBot}
                />
                {railElements}
            </div>
        );
    }
}

EventDetailContainer.propTypes = {
    layoutPackages: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    packageContents: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    layoutError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    pageId: PropTypes.string.isRequired,
    packageFetchDispatch: PropTypes.func.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isMaxTabWidth: PropTypes.bool,
    seoTitle: PropTypes.string.isRequired,
    seoDescription: PropTypes.string.isRequired,
    changeMetaDataDispatch: PropTypes.func.isRequired,
};

EventDetailContainer.defaultProps = {
    layoutPackages: null,
    packageContents: null,
    layoutError: null,
    isMaxTabWidth: false,
};

function mapStateToProps(state, props) {
    const { appConfig, packageContents } = state;

    const allEventData = lodashGet(appConfig, 'allEventData', {});
    const pageId = lodashGet(props, 'match.params.pageId', '');
    const eventInfo = lodashGet(allEventData, `[${pageId}]`, {});
    const contentDetails = lodashGet(eventInfo, 'contentDetails', {});
    const layoutPackages = lodashGet(eventInfo, 'layoutPackages', []);
    const seoTitle = lodashGet(eventInfo, 'seoTitle', '');
    const seoDescription = lodashGet(eventInfo, 'seoDesc', '');

    return {
        layoutPackages,
        packageContents,
        pageId,
        contentDetails,
        seoTitle,
        seoDescription,
    };
}

export default connect(mapStateToProps, {
    layoutFetchActionDispatch: layoutFetchAction,
    packageFetchDispatch: packagePartialFetchAction,
    changeMetaDataDispatch: changeMetaData,
})(withDeviceUtil(ResizeHOC(EventDetailContainer)));
