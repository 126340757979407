export const ContentDetailsActions = {
    CONTENT_DETAIlS_FETCH: 'CONTENT_DETAIlS_FETCH',
    CONTENT_DETAIlS_FETCH_ERROR: 'CONTENT_DETAIlS_FETCH_ERROR',
    CONTENT_DETAIlS_FETCH_COMPLETED: 'CONTENT_DETAIlS_FETCH_COMPLETED',

    SERIES_DETAIlS_FETCH: 'SERIES_DETAIlS_FETCH',
    SERIES_DETAIlS_FETCH_ERROR: 'SERIES_DETAIlS_FETCH_ERROR',
    SERIES_DETAIlS_FETCH_COMPLETED: 'SERIES_DETAIlS_FETCH_COMPLETED',
    TAB_DETAILS_FETCH: 'TAB_DETAILS_FETCH',
    FETCH_EPISODE_TABS: 'FETCH_EPISODE_TABS',
    EMPTY_DETAIL_ACTION: 'EMPTY_DETAIL_ACTION',
    SET_PREV_PAGE_TITLE: 'SET_PREV_PAGE_TITLE',
    CLEAN_NEXT_TAB: 'CLEAN_NEXT_TAB',
    FETCH_PARTICULAR_EPISODE_TAB: 'FETCH_PARTICULAR_EPISODE_TAB',
    SERIES_DETAILS_AND_TAB_FETCH: 'SERIES_DETAILS_AND_TAB_FETCH',
    FETCH_SEASON_NEXT_EPISODES: 'FETCH_SEASON_NEXT_EPISODES',
};

export const contentDetailsFetchAction = payload => ({
    type: ContentDetailsActions.CONTENT_DETAIlS_FETCH,
    contentId: payload,
});

export const contentDetailsFetchCompletedAction = payload => ({
    type: ContentDetailsActions.CONTENT_DETAIlS_FETCH_COMPLETED,
    ...payload,
});

export const contentDetailsFetchErrorAction = payload => ({
    type: ContentDetailsActions.CONTENT_DETAIlS_FETCH_ERROR,
    ...payload,
});


export const seriesDetailsFetchAction = payload => ({
    type: ContentDetailsActions.SERIES_DETAIlS_FETCH,
    ...payload,
});

export const tabDetailsFetchAction = payload => ({
    type: ContentDetailsActions.TAB_DETAILS_FETCH,
    ...payload,
});

export const episodeTabsFetchAction = payload => ({
    type: ContentDetailsActions.FETCH_EPISODE_TABS,
    ...payload,
});

export const fetchParticularEpisodeFetchAction = payload => ({
    type: ContentDetailsActions.FETCH_PARTICULAR_EPISODE_TAB,
    ...payload,
});

export const seriesDetailsFetchCompletedAction = payload => ({
    type: ContentDetailsActions.SERIES_DETAIlS_FETCH_COMPLETED,
    ...payload,
});

export const seriesDetailsFetchErrorAction = payload => ({
    type: ContentDetailsActions.SERIES_DETAIlS_FETCH_ERROR,
    ...payload,
});

export const emptyDetailAction = () => ({
    type: ContentDetailsActions.EMPTY_DETAIL_ACTION,
});

export const setPreviousPageTitleAction = payload => ({
    type: ContentDetailsActions.SET_PREV_PAGE_TITLE,
    payload
});

export const cleanNextTab = (payload) => ({
    type: ContentDetailsActions.CLEAN_NEXT_TAB,
    payload
}); 

export const seriesDetailsAndTabFetchAction = (payload) => ({
    type: ContentDetailsActions.SERIES_DETAILS_AND_TAB_FETCH,
    payload
}); 

export const fetchSeasonNextEpisodeDataAction = (payload) => ({
    type: ContentDetailsActions.FETCH_SEASON_NEXT_EPISODES,
    payload
}); 

export default {
    contentDetailsFetchAction,
    contentDetailsFetchCompletedAction,
    setPreviousPageTitleAction,
    seriesDetailsFetchAction,
    seriesDetailsFetchCompletedAction,
    cleanNextTab,
    fetchParticularEpisodeFetchAction,
    seriesDetailsAndTabFetchAction,
    fetchSeasonNextEpisodeDataAction
};
