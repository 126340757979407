import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import {
    getPrefferedPartnerList, updateLionsgateCPTrailerURL, getTrailerPlaybackDetails, readStitchId,
    getDateWithTitle, getShowTime,
} from '@airtel-tv/utils/GlobalUtil';
import {
    getSocialShareInfo,
} from '@airtel-tv/utils/ContentMetaUtil';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import {
    IMAGE_PATHS, MODAL_POPUP, PROGRAM_TYPES, ELEMENT_ID, ICON_POSITION, LOCAL_STORE_KEYS, APPLICATION_DOMAINS,
} from '@airtel-tv/constants/GlobalConst';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { contentDetailsEvent, unlockNowClickEvent, clickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import EventType from '@airtel-tv/analytics/EventType';
import { ThumborUtil } from '@airtel-tv/utils/ThumborUtil';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { EVENT_SOURCE_NAME, LOGIN_SOURCE, SUBSCRIPTION_SOURCE } from '@airtel-tv/constants/EventsConst';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
// import { showModalComponentAction } from '../../modal-popup/ModalPopupActions';
// import TrailerControlBarComponent from '../../playback/components/control-bars/TrailerControlBarComponent';
import { checkWindowExist, getCurrentUrl, getElementById } from '@airtel-tv/utils/WindowUtil';
import { playbackDetailsFetchAction, playbackWithoutLoginDetailsFetchAction } from '@airtel-feature/playback/actions/PlaybackActions';
// import { playbackDetailsFetchAction, playbackWithoutLoginDetailsFetchAction } from '../../playback/actions/PlaybackAction';
import { SubscriptionUtil } from '@airtel-tv/utils/SubscriptionUtil';
// import { intersection } from 'lodash';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import Lottie from 'lottie-react';
import { showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { PLAYER_STATE } from '@airtel-tv/constants';
import { updatePreferredPartnerSelections } from '../../../../../web/src/modules/user/UserAction';
// import { updatePreferredPartnerSelections } from '../../user/UserAction';
import PlayerContainer from '../../container/PlayerContainer';
import { hideBannerOnPlay, showBannerOnPlay } from '../../../../../web/src/modules/notify/NotifyActions';
import timeAnimation from '../../timerAnimation.json';
import Timer from '../TimerComponent/TimerComponent';
// import { ROUTE_PATHS } from '@airtel-tv/constants/RouteConsts';
import TrailerContainer from '../../container/TrailerContainer';
import TrailerControlBarComponent from '../../../../../web/src/modules/playbackComponents/components/control-bars/TrailerControlBarComponent';
import ContentShareComponent from '../../../content-details/components/ContentShareComponent';
import styles from './DesktopBannerComponent.scss';


class DesktopBannerComponent extends Component {
    constructor(props) {
        super(props);
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.observer;
        this.deviceUtil = props.deviceUtil;
        this.isMobile = this.deviceUtil.isMobile();
        this.state = {
            // playTrailer: false,
            opaqueTrailer: false,
            isTrailerVisible: false,
            playbackDetails: {},
            contentType: '',
            prevPlayState: '',
        };
        this.animationTimer = 3000;
        this.animationTrailerTimer = 0;
        this.timerRef;
        this.mouseMoveTimerRef;
        this.playTrailerPromise;
    }

    componentDidUpdate(prevProps) {
        const { playingOnBanner: prevPlayingOnBanner } = prevProps;
        // TODO: UNCOMMENT ON TRAILER STORY

        const {
            contentDetails: {
                trailers, cpId, playableId, id,
            }, playbackDetails, playingOnBanner, loginTriggered, modalConfig,
        } = this.props;
        const { prevPlayState } = this.state;
        const { playTrailer } = this.props;
        const trailerRefId = (Array.isArray(trailers) && trailers[0]?.refId) || '';
        const cdpDescriptionContainer = getElementById('cdp-banner-left-controller');
        if (prevPlayingOnBanner !== prevPlayingOnBanner && cdpDescriptionContainer) {
            cdpDescriptionContainer.style.display = playingOnBanner ? 'none' : 'block';
        }
        if (prevProps.playbackDetails !== playbackDetails && Object.keys(playbackDetails).length && (trailerRefId !== '' && !playbackDetails[trailerRefId]?.error)) {
            this.getPlaybackData();
        }
        if ((modalConfig.showModal || loginTriggered) && playTrailer && !prevPlayState) {
            this.setState({ prevPlayState: PLAYER_STATE.LOGIN_PAUSE });
            this.props.updatePlayTrailerState(false);
        }
        else if (!modalConfig.showModal && !loginTriggered && !playTrailer && prevPlayState === PLAYER_STATE.LOGIN_PAUSE) {
            this.setState({ prevPlayState: '' });
            this.props.updatePlayTrailerState(true);
        }
    }

    componentDidMount() {
        const {
            contentDetails: {
                trailers, cpId, playableId, id, contentAutoplay = false, playAsTrailer = false,
                appInAppRedirection = false,
            },
            contentDetails,
            showBannerOnPlayDispatch,
            isAuthenticated,
            playbackWithoutLoginDetailsFetchActionDispatch,
        } = this.props;
        let trailerRefId = null;
        let trailerUrl = null;
        if (!checkWindowExist()) {
            return;
        }

        trailerRefId = Array.isArray(trailers) && trailers[0]?.refId;
        trailerUrl = Array.isArray(trailers) && trailers[0]?.trailerUrl;
        // TODO: UNCOMMENT ON TRAILER STORY

        document.querySelector('header')?.classList.remove('fixed');
        if ((isAuthenticated || playAsTrailer) && contentAutoplay && !appInAppRedirection) { // ADD CHECK FOR AUTOPLAY
            showBannerOnPlayDispatch();
            this.headerSetTimeout();
            setTimeout(() => this.playContent(), 2000);
        }
        else if (trailerRefId) {
            playbackWithoutLoginDetailsFetchActionDispatch({
                isTrailer: true,
                contentId: trailerRefId,
                contentDetails,
            });
        }
        else {
            if (trailerRefId) {
                const newTrailerURl = updateLionsgateCPTrailerURL(trailerUrl, cpId);
                const playbackDetails = getTrailerPlaybackDetails(trailerRefId, newTrailerURl);
                this.setState({ playbackDetails });

                const headerHeight = document.querySelector('header')?.getBoundingClientRect()?.height || 102;
                const containerEle = getElementById(ELEMENT_ID.CDP_BANNER);
                const options = {
                    root: null,
                    rootMargin: '0px 0px 0px 0px',
                    threshold: 0.75,
                };
                this.observer = new IntersectionObserver((entries, _) => {
                    entries.forEach((entry) => {
                        const ele = entry?.target;
                        const outOfScreen = entry?.rootBounds?.height < entry?.boundingClientRect?.height;
                        if (!entry.isIntersecting && outOfScreen) {
                            // this.setState(() => ({playTrailer: false}));
                            return;
                        }
                        if (!ele) {
                            return;
                        }
                        const playTrailer = entry.isIntersecting;
                        // alert(playTrailer);
                        if (this.state.opaqueTrailer) {
                            // alert(playTrailer);
                            this.props.updatePlayTrailerState(playTrailer);
                        }
                        this.setState({ isTrailerVisible: entry.isIntersecting });
                    });
                }, options);
                if (containerEle) {
                    this.observer?.observe(containerEle);
                }
            }
            document.addEventListener('scroll', this.showHeaderOnscroll);
            this.playTrailerPromise = this.canPlayTrailer();
        }
        setTimeout(() => {
            const cdpBanner = getElementById(ELEMENT_ID.BANNER_DETAIL_CONTAINER);
            if (cdpBanner) {
                cdpBanner.classList.add('animation');
            }
        }, this.animationTimer);
    }

    getPlaybackData = () => {
        const {
            contentDetails: {
                trailers, cpId, playableId, id,
            }, isAuthenticated, playbackDetails,
        } = this.props;
        const trailerRefId = Array.isArray(trailers) && trailers[0]?.refId;
        const trailerUrl = playbackDetails[trailerRefId]?.playback?.playUrl ? playbackDetails[trailerRefId]?.playback?.playUrl : Array.isArray(trailers) && trailers[0]?.trailerUrl;
        if (trailerRefId) {
            const newTrailerURl = updateLionsgateCPTrailerURL(trailerUrl, cpId);
            const playbackDetailslocal = getTrailerPlaybackDetails(trailerRefId, newTrailerURl);
            if (playbackDetails[trailerRefId]?.drm) {
                playbackDetailslocal[trailerRefId].drm = playbackDetails[trailerRefId].drm;
                playbackDetailslocal[trailerRefId].playback = playbackDetails[trailerRefId].playback;
            }
            playbackDetailslocal[trailerRefId].mspCp = playbackDetails[trailerRefId]?.mspCp ? playbackDetails[trailerRefId].mspCp : false;
            this.setState({ playbackDetails: playbackDetailslocal });
            const headerHeight = document.querySelector('header')?.getBoundingClientRect()?.height || 102;
            const containerEle = getElementById(ELEMENT_ID.CDP_BANNER);
            const options = {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                threshold: 0.75,
            };
            this.observer = new IntersectionObserver((entries, _) => {
                entries.forEach((entry) => {
                    const ele = entry?.target;
                    const outOfScreen = entry?.rootBounds?.height < entry?.boundingClientRect?.height;
                    if (!entry.isIntersecting && outOfScreen) {
                        // this.setState(() => ({playTrailer: false}));
                        return;
                    }
                    if (!ele) {
                        return;
                    }
                    const playTrailer = entry.isIntersecting;
                    if (this.state.opaqueTrailer) {
                        this.props.updatePlayTrailerState(playTrailer);
                    }
                    this.setState({ isTrailerVisible: entry.isIntersecting });
                });
            }, options);
            if (containerEle) {
                this.observer?.observe(containerEle);
            }
            document.addEventListener('scroll', this.showHeaderOnscroll);
            this.playTrailerPromise = this.canPlayTrailer();
        }
    };

    headerSetTimeout = () => {
        clearTimeout(this.mouseMoveTimerRef);
        this.mouseMoveTimerRef = setTimeout(() => {
            if (document.querySelector('header')?.contains(document.activeElement)) {
                clearTimeout(this.mouseMoveTimerRef);
            }
            else {
                this.headerToogle('hide');
            }
        }, 2000);
    };

    showHeaderOnscroll = (event) => {
        const bodyBoundingRect = document.querySelector('body').getBoundingClientRect();
        if (bodyBoundingRect.top === 0 && this.props.playTrailer) {
            document.querySelector('header')?.classList.remove('fixed');
            this.headerSetTimeout();
            return;
        }
        this.headerToogle('alwaysOn');
        document.querySelector('header')?.classList.add('fixed');
    };

    componentWillUnmount() {
        const containerEle = getElementById(ELEMENT_ID.CDP_BANNER);
        if (containerEle) {
            this.observer?.unobserve(containerEle);
        }
        if (this.timerRef) {
            clearTimeout(this.timerRef);
        }
        // document.getElementsByTagName('main')[0].style.paddingTop = '102px';
        if (containerEle) {
            containerEle.style.marginTop = '0px';
        }
        document.removeEventListener('scroll', this.showHeaderOnscroll);
        clearTimeout(this.mouseMoveTimerRef);
        this.headerToogle('alwaysOn');
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const { contentDetails, subscriptionDataById, cpDetailsById } = nextProps;
        const cpId = lodashGet(contentDetails, 'cpId', '');
        const channelId = SubscriptionUtil.getChannelId({
            cpDetailsById,
            cpId,
        });
        const channelDetails = lodashGet(cpDetailsById, `[${channelId}]`, null);
        const subscriptionDetails = lodashGet(subscriptionDataById, `[${channelId}]`, null);
        const targetHierarchy = lodashGet(channelDetails, 'targetHierarchy', 0);
        const isSubscribed = SubscriptionUtil.isSubscribed(subscriptionDetails, targetHierarchy);

        if (!prevState
            || isSubscribed !== prevState.isSubscribed) {
            return {
                isSubscribed,
            };
        }
        return null;
    }

    // HANDLING FOR MOBILE TO BE ADDED
    getImage = ({ images, isMobile }) => {
        if (isMobile) {
            return images?.SQUARE || images?.SQUARE_HD || images?.PORTRAIT || images?.PORTRAIT_HD || images?.LANDSCAPE_169 || '';
        }
        return images?.LANDSCAPE_169 || IMAGE_PATHS.DEFAULT_PLAYER_BACKGROUND;
    };

    canPlayTrailer = () => {
        const { contentDetails: { trailers } } = this.props;
        const trailerRefId = Array.isArray(trailers) && trailers[0]?.refId;
        const callback = () => {
            const { playbackDetails } = this.state;
            if (playbackDetails && playbackDetails[trailerRefId]?.playback && checkWindowExist()) {
                const cdpBanner = getElementById(ELEMENT_ID.CDP_BANNER);
                if (cdpBanner) {
                    // cdpBanner.classList.add('animation');
                    // cdpBanner.classList.remove('animation-revert');
                    const trailerContainer = document.getElementsByClassName('trailer-container')[0];
                    const imageBannerContainer = document.getElementsByClassName('desktop-img')[0];
                    if (trailerContainer) {
                        trailerContainer.classList.add('opaque');
                    }
                    if (imageBannerContainer) {
                        imageBannerContainer.classList.add('transparent');
                        imageBannerContainer.style.display = 'none';
                    }
                    // document.getElementsByTagName('main')[0].style.paddingTop = '0px';
                    // const headElementHeight = getElementById(ELEMENT_ID.HEADER_WEB)?.offsetHeight;
                    // cdpBanner.style.marginTop = `-${headElementHeight}px`;

                    if (!document.querySelector('header')?.contains(document.activeElement)) {
                        this.headerToogle('hide');
                    }
                    this.setState(prev => ({
                        ...prev,
                        opaqueTrailer: true,
                        // playTrailer: prev.isTrailerVisible,
                    }));
                    this.props.updatePlayTrailerState(this.state.isTrailerVisible);
                }
            }
        };

        return () => {
            setTimeout(() => {
                callback();
            }, this.animationTrailerTimer);
        };
    };

    playContent = () => {
        const cdpBanner = getElementById(ELEMENT_ID.CDP_BANNER);
        if (cdpBanner) {
            const playerContainer = document.getElementsByClassName('player-container')[0];
            const imageBannerContainer = document.getElementsByClassName('image-banner-container')[0];
            if (playerContainer) {
                playerContainer.classList.add('opaque');
            }
            if (imageBannerContainer) {
                imageBannerContainer.classList.add('transparent');
                imageBannerContainer.style.display = 'none';
            }
            const headElementHeight = getElementById(ELEMENT_ID.HEADER_WEB).offsetHeight;

            if (!document.querySelector('header')?.contains(document.activeElement)) {
                this.headerToogle('hide');
            }
            this.setState(prev => ({
                ...prev,
                showPlayer: true,
            }));
        }
    };

    getLanguage = () => {
        const { languageMap = [], contentDetails: { languages = [] } } = this.props;
        const langArray = [];
        if (languageMap.length === 0) {
            return [];
        }
        languageMap?.forEach((lang) => {
            if (languages && languages.includes(lang.lan)) {
                langArray.push(lang.n);
            }
        });

        return langArray;
    };

    shareEvent = () => {
        const meta = {
            action: ANALYTICS_ACTIONS.SHARE_CLICK,
            source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
            asset_name: ANALYTICS_ASSETS.SHARE,
        };
        contentDetailsEvent(meta, EventType.SHARE_CLICK);
    };

    getUserSessionId = () => {
        const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META) || {};
        const { userSessionId = '' } = analyticsMeta;
        return userSessionId;
    };


    // railCtaClicked = async ({ railCtaText, railCtaType, appInAppRedirection }) => {
    //     const {
    //         isAuthenticated,
    //         triggerLoginDispatch,
    //         location,
    //         history,
    //         navigate,
    //         contentCollection,
    //         sid,
    //         customerType,
    //         NavigateToPaymentPage,
    //         bannerActions,
    //         hideBannerOnPlayDispatch,
    //         playbackDetailsFetchDispatch,
    //         contentDetails: {
    //             cpId,
    //             id,
    //             title,
    //             programType,
    //             tvShowName,
    //             contentAutoplay = false,
    //             playAsTrailer = false,
    //         },
    //     } = this.props;

    //     const { WEBVIEW_CTA_TYPE = 'WEBVIEW', PLAY_CTA = 'PLAY' } = this.LANGUAGE;
    //     const userSessionId = this.getUserSessionId();

    //     const stitchId = readStitchId();
    //     const sourceName = location.pathname;
    //     const subscriptionsMeta = {
    //         action: `${railCtaText} click`,
    //         asset_name: railCtaText,
    //         ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
    //         source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    //         content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
    //         content_id: id,
    //         cp_name: cpId,
    //         sid,
    //         user_session_id: userSessionId,
    //         playback_stitch_key: stitchId,
    //     };

    //     if (!isAuthenticated) {
    //         clickEvent({
    //             ...subscriptionsMeta,
    //             asset_name: LOGIN_SOURCE.LOGIN_TO_WATCH,
    //         });
    //         triggerLoginDispatch({
    //             loginTriggered: true,
    //             sourceOfLoginTrigger: LOGIN_SOURCE.PLAY_CLICK,
    //         });
    //     }
    //     else if (appInAppRedirection) {
    //         const meta = {
    //             action: `${railCtaText} click`,
    //             source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    //             content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
    //             content_id: id,
    //             cp_name: cpId,
    //             user_session_id: userSessionId,
    //             playback_stitch_key: stitchId,
    //             asset_name: railCtaText,
    //             app_in_app_redirection: appInAppRedirection
    //         };
    //         contentDetailsEvent(meta, EventType.CLICK);
    //         playbackDetailsFetchDispatch({
    //             contentId: id,
    //         });
    //     }
    //     else if (railCtaType === WEBVIEW_CTA_TYPE) {
    //         const { action = {} } = bannerActions || {};
    //         let parsedUrl;
    //         let isWebDeeplink;
    //         if (action?.url) {
    //             parsedUrl = new URL(action?.url);
    //             const { hostname } = parsedUrl;
    //             isWebDeeplink = Object.values(APPLICATION_DOMAINS || []).includes(hostname);
    //         }

    //         if (!this.showXmpClaimPopUp()) {
    //             contentDetailsEvent(subscriptionsMeta, EventType.CLICK);
    //             if (customerType === 'NON_AIRTEL') {
    //                 navigate(`${RoutingUtil.getSubscriptionPage()}`,
    //                     {
    //                         state: {
    //                             origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
    //                             cpId,
    //                             ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
    //                             contentUrl: location.pathname,
    //                         },
    //                     });
    //             }
    //             else if (parsedUrl && isWebDeeplink) {
    //                 const { pathname = '/', search = '' } = parsedUrl || {};
    //                 const { showModalComponentActionDispatch, uid } = this.props;
    //                 if (pathname.includes('/xpp-claim')) {
    //                     showModalComponentActionDispatch({
    //                         showModalValue: true,
    //                         componentNameValue: MODAL_POPUP.XPP_CLAIM_MODAL,
    //                         overrideCrossButtonValue: false,
    //                         payload: {
    //                             popupVisibleMeta: {
    //                                 asset_name: 'xpp_claim_popup',
    //                                 source_name: 'content_detail_page',
    //                             },
    //                             backGroundCustomClass: '',
    //                             popUpcustomClass: '',
    //                             uid,
    //                             showLottie: false,
    //                             closeBtnClickedHandler: () => {},
    //                         },

    //                     });
    //                     return;
    //                 }
    //                 navigate(`${pathname}${search}`,
    //                     {
    //                         state: {
    //                             origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
    //                             cpId,
    //                             ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
    //                             contentUrl: location.pathname,
    //                         },
    //                     });
    //             }
    //             else {
    //                 NavigateToPaymentPage();
    //             }
    //         }
    //         else {
    //             contentDetailsEvent(subscriptionsMeta, EventType.XMP_CLICK);
    //         }
    //     }
    //     else if (railCtaType === PLAY_CTA) {
    //         if (!this.showXmpClaimPopUp()) {
    //             const meta = {
    //                 action: `${railCtaText} click`,
    //                 source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    //                 content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
    //                 content_id: id,
    //                 cp_name: cpId,
    //                 user_session_id: userSessionId,
    //                 playback_stitch_key: stitchId,
    //                 asset_name: railCtaText,
    //             };

    //             if (programType === PROGRAM_TYPES.EPISODE) {
    //                 meta.episode_name = title;
    //             }

    //             contentDetailsEvent(meta, EventType.CLICK);
    //             const { currentTab } = contentCollection;
    //             if (contentAutoplay) {
    //                 const videoNode = getElementById(ELEMENT_ID.TOGGLE_FULLSCREEN);
    //                 if (videoNode) {
    //                     videoNode.click();
    //                 }
    //             }
    //             else if (!location.search.includes('showPlayback')) {
    //                 const params = {
    //                     showPlayback: true,
    //                 };
    //                 if (currentTab) {
    //                     history.replace({
    //                         pathname: `${location.pathname}`,
    //                         search: `${LocationUtil.objectToQueryParams({ currentTab })}`,
    //                     });
    //                     params.currentTab = currentTab;
    //                 }
    //                 // history.push({ pathname: `${location.pathname}?${LocationUtil.objectToQueryParams(params)}` });
    //                 navigate(`${location.pathname}?${LocationUtil.objectToQueryParams(params)}`);
    //             }
    //         }
    //         else {
    //             contentDetailsEvent(subscriptionsMeta, EventType.XMP_CLICK);
    //         }
    //     }
    // };

    resetAnimationOnViewInfo = () => {
        const cdpBanner = getElementById(ELEMENT_ID.CDP_BANNER);
        if (cdpBanner) {
            cdpBanner.classList.replace('animation', 'animation-revert');
        }
    };

    showXmpClaimPopUp = () => {
        const { preferredPartnerPlans, location } = this.props;
        const { isSubscribed } = this.state;
        const PREFFERED_PARTNER_LIST = getPrefferedPartnerList(preferredPartnerPlans);
        const { contentDetails } = this.props;

        if (!isSubscribed && preferredPartnerPlans && preferredPartnerPlans.length && PREFFERED_PARTNER_LIST.includes(contentDetails.cpId)) {
            const contentUrl = location.pathname;
            // showModalComponentActionDispatch({
            //     showModalValue: true,
            //     componentNameValue: MODAL_POPUP.XMP_CLAIM_MODAL_POPUP,
            //     overrideCrossButtonValue: true,
            //     payload: {
            //         contentDetails,
            //         contentUrl,
            //         origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE_XMP_MODAL,
            //     },
            // });
            const {
                preferredPartnerPlans, updatePreferredPartnerSelectionDispatch,
                contentDetails, circle, sid, customerType, uid,
            } = this.props;

            const cpName = lodashGet(contentDetails, 'cpId', '');
            const selectedOffer = preferredPartnerPlans && preferredPartnerPlans[0] && preferredPartnerPlans[0].offers.filter(offer => offer.cp === cpName);
            const selectedProductId = selectedOffer && selectedOffer.length && selectedOffer[0].productId;
            const referenceId = lodashGet(preferredPartnerPlans[0], 'refId', null);
            const preferredPartnerOfferPlanId = lodashGet(preferredPartnerPlans[0], 'planId', null);
            const preferredPartners = {
                planId: preferredPartnerOfferPlanId,
                products: [
                    selectedProductId,
                ],
                referenceId,
            };
            updatePreferredPartnerSelectionDispatch({
                preferredPartners,
                fromContentDetail: true,
            });
            const pageLoadAnalyticsMeta = {
                pageUrl: getCurrentUrl(),
                appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
                circle,
                sid,
                active_tab: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
                source: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
                ingressIntent: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
                pageName: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
                service: ANALYTICS_ASSETS.AIRTELTV,
                customer_type: customerType,
                uid,
            };
            unlockNowClickEvent(pageLoadAnalyticsMeta, true);
            return true;
        }
        return false;
    };

    shareButton = (railCtaText) => {
        const advanceIconRail = this.props.layoutPackages.find(item => item?.format?.ty === 'ADVANCE_ICON_RAIL');
        const iconParams = advanceIconRail?.format?.iconParams;
        if (!(iconParams || []).includes('share')) {
            return null;
        }
        const { SHARE_CONTENT = 'Share Content' } = this.LANGUAGE;
        const {
            location, pageId, deviceUtil, contentDetails: { title, shortUrl }, match: { path, params }, contentCollection,
        } = this.props;
        let { contentDetails: { shareShortUrl } } = this.props;
        const { episodeId } = params;
        if (episodeId) {
            shareShortUrl = checkWindowExist() ? window.location.href : shareShortUrl;
        }
        const socialShareInfo = getSocialShareInfo({
            programType: PROGRAM_TYPES.EVENT,
            showName: title,
            currentUrl: RoutingUtil.getEventsPageUrl({
                eventName: title,
                eventId: pageId,
            }),
            baseUrl: deviceUtil.getBaseUrl(),
            shareShortUrl,
        });
        return (
            <AnalyticsButtonComponent
                className="cta-cdp-banner over-flow-visible button-default-remove"
                onClick={this.shareEvent}
            >
                <div className="tool-tip-cdp-banner">{SHARE_CONTENT}</div>
                <ContentShareComponent
                    title={title}
                    shortUrl={shortUrl}
                    shareShortUrl={shareShortUrl}
                    socialShareInfo={socialShareInfo}
                    location={location}
                    hideWhatsapp
                    fromContentDetail
                    railCtaText={railCtaText}
                />
            </AnalyticsButtonComponent>
        );
    };

    headerToogle(state) {
        if (this.isMobile) {
            return;
        }
        if (state === 'show') {
            if (this.mouseMoveTimerRef) {
                clearTimeout(this.mouseMoveTimerRef);
            }
            document.querySelector('header')?.classList.add('show-header');
            document.querySelector('header')?.classList.remove('hide-header');
        }
        else if (state === 'hide') {
            document.querySelector('header')?.classList.add('hide-header');
            document.querySelector('header')?.classList.remove('show-header');
        }
        else if (state === 'alwaysOn') {
            if (this.mouseMoveTimerRef) {
                clearTimeout(this.mouseMoveTimerRef);
            }
            document.querySelector('header')?.classList.remove('hide-header');
            if (document.querySelector('header')) {
                document.querySelector('header').style.marginTop = '0px';
            }
        }
    }

    showTimer = ({ matchStartTime = 0, matchTimerOffset = 0 }) => {
        const currentTime = Date.now();
        const startDiff = matchStartTime - currentTime;
        return (matchTimerOffset > startDiff && startDiff > 0);
    };


    getAirTime = ({ matchStartTime = 0, matchStartTimeOffset = 0 }) => {
        const currentTime = Date.now();
        const startTime = getShowTime(matchStartTime);
        const curDate = new Date().getDate();
        const matchDate = new Date(matchStartTime).getDate();
        const matchMonth = new Date(matchStartTime).getMonth();
        const matchYear = new Date(matchStartTime).getFullYear();
        let dayOfMatch = 'Today';
        const noOfDaysInCurMonth = new Date(matchYear, matchMonth, 0).getDate();
        if ((noOfDaysInCurMonth == curDate && matchDate == 1) || (curDate + 1) === matchDate) {
            dayOfMatch = 'Tomorrow';
        }
        if (matchStartTimeOffset > matchStartTime - currentTime > 0) {
            return (
                <p className="live-tag-player start-reminder-tag">
                    {dayOfMatch}
                    {' '}
                    at
                    {' '}
                    { startTime.toUpperCase() }
                </p>
            );
        }
    };

    getPlaybackType = () => {
        const { playbackDetails, contentDetails: { id = '' } } = this.props;
        const { contentType } = this.state;
        const { CDP_TRAILER = 'Trailer' } = this.LANGUAGE;

        if (id && playbackDetails[id]?.success && contentType === '') {
            if (playbackDetails && playbackDetails[id]?.contentType === PROGRAM_TYPES.TRAILER) {
                this.setState({ contentType: CDP_TRAILER });
            }
            else if (!this.isMobile && playbackDetails[id]?.contentPlayTag) {
                this.setState({ contentType: playbackDetails[id]?.contentPlayTag });
            }
            else {
                this.setState({ contentType: playbackDetails[id]?.contentPlayTag || playbackDetails[id]?.playbackType });
            }
        }
        return playbackDetails[id]?.success || false;
    };

    matchStartTimer = ({ matchStartTime = 0, matchTimerOffset = 0 }) => {
        if (matchStartTime && this.showTimer({
            matchStartTime,
            matchTimerOffset,
        })) {
            return (
                <p className="starting-timer">
                    {/* place your lottie file here */}
                    <Lottie
                        loop={1}
                        animationData={timeAnimation}
                        play
                    />
                    <span className="start-text">Starting in</span>
                    <span className="timer"><Timer airTime={matchStartTime} /></span>
                </p>
            );
        }
        return null;
    };

    upcomingTextValue = (reminderTime) => {
        const { UPCOMING = 'Coming Soon', COMING_ON = 'Coming on' } = this.LANGUAGE;
        if (reminderTime) {
            return `${COMING_ON} ${getDateWithTitle(reminderTime, 'short', true)}`;
        }
        return UPCOMING;
    };

    render() {
        const {
            imageSettings,
            deviceUtil,
            contentDetails, playingOnBanner,
            contentDetails: {
                images, id, title, shortUrl, trailers, playableId, contentAutoplay = false, shouldAddInCW = true, reminderTime, matchStartTime = 0, upcoming,
                appInAppRedirection = false,
            },
            isAuthenticated,
            ErrorHandler,
            matchStartTimeOffset,
            matchTimerOffset,
            playbackDetails: playbackDetailsFromProps,
            bannerActions,
            isSSRData,
        } = this.props;
        let { railCtaText = '', railCtaType = '' } = bannerActions || {};
        railCtaText = !isSSRData ? railCtaText : '';
        const {
            playbackDetails,
            isSubscribed,
            contentType = '',
        } = this.state;
        const { isMobile } = this;
        const {
            SHARE_CONTENT = 'Share Content', VIEW_INFO = 'View Info', VOLUME = 'Volume', CDP_TRAILER = 'Trailer',
        } = this.LANGUAGE;
        const { playTrailer } = this.props;
        const trailerRefId = Array.isArray(trailers) && trailers[0]?.refId;
        const showTeaserTag = (contentAutoplay && this.getPlaybackType() && contentType !== '');
        const showLiveTag = id && playbackDetailsFromProps[id]?.success && playbackDetailsFromProps[id]?.playbackType === 'LIVE' || (matchStartTime && Date.now() > matchStartTime);
        const upcomingDate = upcoming ? this.upcomingTextValue(reminderTime) : '';
        const airTime = matchStartTime && railCtaText ? this.getAirTime({
            contentAutoplay,
            matchStartTime,
            matchStartTimeOffset,
        }) : null;

        const imgWidth = this.deviceUtil.isBot() || isMobile ? 500 : 1600;
        let imageUrl = this.getImage({
            images,
            isMobile,
        });
        const imageFormat = this.deviceUtil.getImageFormat(imageUrl);
        imageUrl = ThumborUtil.getThumborUrl(imgWidth, 0, imageUrl, imageFormat);
        if (!checkWindowExist()) {
            // convert image to base64 on server side fro optimization
            imageUrl = `preloadImage${imageUrl}preloadImage`;
        }

        const primaryLanguage = this.getLanguage()?.[0] || '';
        const hasTrailer = bannerActions && trailerRefId && playbackDetails && playbackDetails[trailerRefId]?.playback;

        return (
            <>
                <div
                    className={`cdp-banner-wrapper cdp-player-banner ${contentAutoplay && !appInAppRedirection ? ' autoplay-class-cdp' : ''}`}
                    id={ELEMENT_ID.CDP_BANNER}
                >
                    { playableId && (contentAutoplay && !appInAppRedirection) && railCtaText
                        && (
                            <div className="player-container player-gradient transparent-container">
                                <PlayerContainer
                                    contentId={id}
                                    contentDetails={contentDetails}
                                    playableId={playableId}
                                    shouldAddInCW={shouldAddInCW}
                                    trailerRefId={trailerRefId}
                                />
                                <div className="player-gradient-cdp" />
                            </div>
                        )
                    }
                    {hasTrailer || showTeaserTag ? <div className="trailer-tag">{ contentType !== '' ? contentType : CDP_TRAILER }</div> : null}
                    {/* note:- kindly do not change any class or html  tag hierarchy without discussion */}
                    {/* on banner first load use class "cdp-banner-wrapper" only without any animation  class */}
                    {/* use this class combination "cdp-banner-wrapper animation-active"
                    to activate the banner animation */}
                    {/* use this class combination "cdp-banner-wrapper animation-reset"
                    to reset the banner animation */}
                    {
                        // TODO: UNCOMMENT ON TRAILER STORY
                        hasTrailer
                        && (
                            <div className="fit-video-container trailer-autoplay trailer-container transparent-container trailer-gradient">
                                {/* <div className="current-vdo__player">
                                    <div className="vdo-player"> */}
                                <TrailerContainer
                                    controlBar={TrailerControlBarComponent}
                                    playbackData={playbackDetails}
                                    playableId={trailerRefId}
                                    playTrailer={playTrailer}
                                    canPlayTrailer={this.playTrailerPromise}
                                    contentDetails={contentDetails}
                                    ErrorHandler={ErrorHandler}
                                    trailerRefId={trailerRefId}
                                />
                                {/* </div>
                                </div> */}
                                <div className="trailer-gradient" />
                            </div>
                        )
                    }
                    {/* <div className="image-banner-container opaque-container">
                        <div
                            className="image-banner-wrapper"
                            onClick={() => this.railCtaClicked({
                                railCtaText,
                                railCtaType,
                            })}
                        >
                            {true
                                ? (
                                    <img
                                        src={imageUrl}
                                        className="banner-image"
                                        id={id}
                                        alt={title}
                                    />
                                )
                                : (
                                    <img
                                        alt="banner-dummy"
                                        className="static-image-bg-banner"
                                        id="banner-dummy"
                                        // src="/static/bigbox-shimmer.png"
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB9AAAAfQAQMAAACg9lY6AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGUExURSIkKf///wbGUpAAAAABYktHRAH/Ai3eAAAAB3RJTUUH5woJER0LhXeqYgAAAf1JREFUeNrtwQENAAAAwqD3T20ON6AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4NqlZAAHW41tKAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTEwLTA5VDE3OjI4OjM2KzAwOjAwy2DS6AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0xMC0wOVQxNzoyODozNiswMDowMLo9alQAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMTAtMDlUMTc6Mjk6MTErMDA6MDCFaBlGAAACXHpUWHR1c2VyX2NvbW1lbnQAAFjD7VNratwwEL7KHMD4DqUPKCRtoOQAY2nWGZBkR9KYHL/feLOb0CMU/VhYLGnmez5sVTLp3ixT3NJWqWknztInCltpErp0q8RRd21By0qStM/01Sov6keHdhbKSq+mjbh0wadDamU6uKo1KhJIOknd2ky/rITzyy4pCW63VxNKWGRtorIVkstFwzmabTWZ6YsU4XJHRr1q6+rPsk7EnTL3jt1SGch+FrJOxVJikqJ5pqfPm14cNlBS3uqin2Y1bHEQDrXI+5/MSZpxZLpgNegFArDdl63SOoPQt83Z4OBEi3tBkzYFmcxvmjEnKmcSOxWNmgEFam81qKtxwjTSvEuNipVb1G2mH9aC0JIYL/rJAwDz5JguWnRxWV3JtC1bdfKg/ngyOoAL5wk7kq6G8Tdx8dYSCAcQuZqd7GRskO2RJUjhdh+/Vj4UxDNbdTYa/7HsOvxDoahr0dZc8e/dKUMTxKJJJHmDOqF7vHjV0yz/7L/rGLetQ9LquvabuMQ12MfRqaNbfsCqwrcpF4w+EZ9JnGh/4Sod8QM4SPyMwGgJGg2mc4LXmNJ4VykufLDaDDY+vXADPdcVGYwGIS3t1rljpAJBjVC0iFPXONFFbFVkD5T1AyIoL7pI8bvwa6bfsPneA+6bP99P8MstYa4auDXauSJcwOXhdJnRB0YMvApVowZzeNnRXuNxCxhydXBC6shq4enaxLsM71x35ASVlDTTH2vAADnbw+j/6P/o/+j/6P/o/+j/6P/o/+j/6P/o/+j/6P/o/3/f/79l8FYhd6650AAAAABJRU5ErkJggg=="
                                    />
                                )
                            }
                            <div className="bottom-shade" />
                        </div>
                        <div
                            className="left-shade"
                            onClick={() => this.railCtaClicked({
                                railCtaText,
                                railCtaType,
                            })}
                        />
                    </div> */}

                    {bannerActions
                        ? (
                            <>
                                {
                                    hasTrailer ? (
                                        <div
                                            className="transparent-container full-banner-image"
                                            id="full-image-container"
                                        >
                                            <img
                                                src={imageUrl}
                                                alt={title}
                                            />
                                            <div className="trailer-banner-gradient" />
                                        </div>
                                    ) : null
                                }

                                <div
                                    className="cdp-right-bottom-controls"
                                    style={{ right: ICON_POSITION.RIGHT_80 }}
                                >
                                    {this.shareButton(railCtaText)}
                                </div>
                            </>
                        )
                        : null
                    }
                </div>
            </>
        );
    }
}

DesktopBannerComponent.propTypes = {
    imageSettings: PropTypes.object.isRequired,
    deviceUtil: PropTypes.object.isRequired,
    contentDetails: PropTypes.object.isRequired,
    languageMap: PropTypes.array,
    loginTriggered: PropTypes.bool,
};

DesktopBannerComponent.defaultProps = {
    languageMap: [],
    loginTriggered: false,
};

function mapStateToProps(state, props) {
    const {
        userConfig: {
            preferredPartnerPlans = null, uid = '', subscriptionDataById, customerType = '',
        } = {},
        authConfig,
        playbackDetails,
        appConfig,
        planConfig: { sid = '', userDetails: { circle = '' } = {} } = {},
        modalConfig,
        layoutDetails,
        notify: { playingOnBanner },
    } = state;

    const { pageId } = props;
    const { layoutPackages = [], isSSRData = false } = layoutDetails?.[pageId] || {};
    const { matchTimerOffset = 1000 * 60 * 60 * 24, matchStartTimeOffset = 1000 * 60 * 60 * 24 } = appConfig;
    const { loginTriggered } = authConfig;

    return {
        isAuthenticated: authConfig.isAuthenticated,
        preferredPartnerPlans,
        playbackDetails,
        subscriptionDataById,
        cpDetailsById: appConfig.cpDetailsById,
        uid,
        sid,
        circle,
        customerType,
        modalConfig,
        layoutPackages,
        playingOnBanner,
        matchTimerOffset,
        matchStartTimeOffset,
        isSSRData,
        languageMap: appConfig.language_map_web,
        loginTriggered,
    };
}

export default withDeviceUtil(connect(
    mapStateToProps,
    {
        showModalComponentActionDispatch: showModalComponentAction,
        playbackDetailsFetchDispatch: playbackDetailsFetchAction,
        playbackWithoutLoginDetailsFetchActionDispatch: playbackWithoutLoginDetailsFetchAction,
        updatePreferredPartnerSelectionDispatch: updatePreferredPartnerSelections,
        hideBannerOnPlayDispatch: hideBannerOnPlay,
        showBannerOnPlayDispatch: showBannerOnPlay,
    },
)(withRouter(withStyles(styles)(DesktopBannerComponent))));
