import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lodashUniqBy from 'lodash/uniqBy';
import { LanguageProviderUtil, ContentImageProvider } from '@airtel-tv/utils';
import CastTileComponent from './CastTileComponent';

class CastComponent extends Component {
    constructor(props) {
        super(props);
        this.carouselElementRef = React.createRef();
        this.castArrowRef = React.createRef();
        this.state = {
            // isDown: false,
            showArrows: false,
        };
        this.startX = null;
        this.scrollLeft = null;
    }

    componentDidMount = () => {
        this.handleArrows();
    }

    // handleMouseDown = (e) => {
    //     this.setState({
    //         isDown: true,
    //     });

    //     this.carouselElementRef.current.classList.add('active');
    //     this.startX = e.pageX - this.carouselElementRef.current.offsetLeft;
    //     this.scrollLeft = this.carouselElementRef.current.scrollLeft;
    // }

    // handleMouseLeave = () => {
    //     this.setState({
    //         isDown: false,
    //     });
    //     this.carouselElementRef.current.classList.remove('active');
    // }

    // handleMouseUp = () => {
    //     this.setState({
    //         isDown: false,
    //     });
    //     this.carouselElementRef.current.classList.remove('active');
    // };

    // handleMouseMove = (e) => {
    //     const { isDown } = this.state;
    //     if (!isDown) {
    //         return;
    //     }
    //     e.preventDefault();
    //     const x = e.pageX - this.carouselElementRef.current.offsetLeft;
    //     const walk = (x - this.startX) * 3; // scroll-fast
    //     this.carouselElementRef.current.scrollLeft = this.scrollLeft - walk;
    // };

    handleArrows = () => {
        if (this.carouselElementRef.current) {
            const { clientWidth, scrollWidth } = this.carouselElementRef.current;
            if (clientWidth < scrollWidth) {
                this.setState({ showArrows: true });
            }
        }
    }

    next = () => {
        if (this.carouselElementRef.current) {
            const { clientWidth } = this.carouselElementRef.current;
            this.carouselElementRef.current.scrollLeft += clientWidth / 2;
        }
    }

    previous = () => {
        if (this.carouselElementRef.current) {
            const { clientWidth } = this.carouselElementRef.current;
            this.carouselElementRef.current.scrollLeft -= clientWidth / 2;
        }
    }


    render() {
        const { casts } = this.props;
        const { showArrows } = this.state;
        if (!casts || !casts.length) {
            return null;
        }
        const LANGUAGES = LanguageProviderUtil.getLanguage();
        const uniqCastList = lodashUniqBy(casts, 'displayTitle');
        const castData = uniqCastList
            .filter(cast => (!!ContentImageProvider({ imageMap: cast && cast.images })))
            .map(cast => (
                <CastTileComponent
                    key={cast.id}
                    cast={cast}
                />
            ));


        if (!castData || !castData.length) {
            return null;
        }

        return (
            <div className="cast-bonus-video-wrapper component-top-padding">
                <div className="carousel-wrapper">
                    <div className="title title-with-cta d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">{`${LANGUAGES.CAST_HEADING}`}</h2>

                        <div className={showArrows ? 'd-flex justify-content-between align-items-center' : 'd-none'}>
                            <div
                                className="carousel-arrows"
                                ref={this.castArrowRef}
                            >
                                <span className="button-ripple">
                                    {  /* eslint-disable */}
                                    <button type="button" className="button button-link" onClick={this.previous}>
                                        { /* eslint-enable */}
                                        <i className="icon20 icon-carousel-prev-arrow" />
                                    </button>
                                </span>
                                <span className="button-ripple">
                                    {  /* eslint-disable */}
                                    <button type="button" className="button button-link" onClick={this.next}>
                                        { /* eslint-enable */}
                                        <i className="icon20 icon-carousel-arrow icon-carousel-next-arrow" />
                                    </button>
                                </span>
                            </div>
                        </div>


                    </div>


                    {/* </div>  <div className="cards-row" ref={this.carouselElementRef} onMouseDown={this.handleMouseDown} onMouseLeave={this.handleMouseLeave} onMouseMove={this.handleMouseMove} onMouseUp={this.handleMouseUp} role="carousel">
                    */}
                    <div
                        className="cards-row"
                        ref={this.carouselElementRef}
                        // eslint-disable-next-line jsx-a11y/aria-role
                        role="carousel"
                    >
                        <div className="cards-row__inner">
                            {castData}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CastComponent.defaultProps = {
    casts: [],
};
CastComponent.propTypes = {
    casts: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

export default CastComponent;
