import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAudioLanguages } from '@airtel-tv/utils/GlobalUtil';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';

class CastDetailComponent extends Component {
    constructor(props) {
        super(props);

        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.state = {

        };
    }

    getGenres = (genres = []) => {
        if (genres.length === 1) {
            return genres[0];
        }
        const commaSeparatedGenres = genres.join(', ').split('');
        const lastCommaIndex = commaSeparatedGenres.lastIndexOf(',');
        commaSeparatedGenres[lastCommaIndex] = ' &';
        return commaSeparatedGenres;
    }

    render() {
        const { title, contentDetails: { shortDescription, seriesTvSeasons, genres, releaseYear, languages } } = this.props;
        const { SEASONS = "Seasons", SEASON = "Season", AUDIO_AND_SUBTITLES = "Audio & Subtitles", AUDIO = "Audio", RELEASE_YEAR = "Release Year", DESCRIPTION_HEADING = "Description" } = this.LANGUAGE;
        return (
            <>
                <div className="cast-detail-wrapper">

                    <p className="cast-title">
                        About
                        {' '}
                        { title }
                    </p>
                    <div className="detail-section">
                            {/* CONTENT GENRE */}
                            <div className="detail-container" id={ELEMENT_ID.CONTENT_GENRE}>
                                <p className="detail-title">Genre</p>
                                <p className="detail-desc">{ this.getGenres(genres)}</p>
                            </div>
                            {/* NO. OF SEASONS */}
                            { seriesTvSeasons
                            && (
                                <div className="detail-container" id={ELEMENT_ID.NO_OF_SEASONS}>
                                    <p className="detail-title">{SEASONS}</p>
                                    <p className="detail-desc">
                                        { seriesTvSeasons.length }
                                        {' '}
                                        { seriesTvSeasons.length > 1 ? SEASONS : SEASON }
                                    </p>
                                </div>
                            ) }
                        

                            {/* AUDIO LANGUAGES */}
                            { languages && languages.length > 0 && <div className="detail-container" id={ELEMENT_ID.AUDIO_LANGUAGES}>
                                <p className="detail-title">{AUDIO_AND_SUBTITLES}</p>
                                <p className="detail-desc">
                                    {AUDIO}:
                                    {' '}
                                    {getAudioLanguages(this.props) }
                                </p>
                                {/* SUBTITLES TO BE ADDED WHEN DATA WILL BE AVAILABLE FROM BACKEND  */}
                                {/* <p className="detail-desc">Subtitles: Hindi & English</p> */}
                            </div> }
                            {/* RELEASE YEAR */}
                            {releaseYear && <div className={`detail-container ${shortDescription ? '' : 'hide-border'}`} id={ELEMENT_ID.RELEASE_YEAR}>
                                <p className="detail-title">{RELEASE_YEAR}</p>
                                <p className="detail-desc">{ releaseYear }</p>
                            </div>}
                       


                    </div>
                    {shortDescription ? <div className="description-area">
                        <p className="desc-title">{DESCRIPTION_HEADING}</p>
                        <p className="desc-summary">
                            { shortDescription }

                        </p>
                    </div> : null}
                </div>
            </>
        );
    }
}

CastDetailComponent.propTypes = {
    title: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    contentDetails: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    genres: PropTypes.array,
};

CastDetailComponent.defaultProps = {
    genres: [],
};

export default CastDetailComponent;
