import { PROGRAM_TYPES } from '@airtel-tv/constants';
import TVShowNextEpBuilder from '../builders/TVShowNextEpBuilder';
import TVSeasonNextEpBuilder from '../builders/TVSeasonNextEpBuilder';
import TVEpisodeNextEpBuilder from '../builders/TVEpisodeNextEpBuilder';
import CatchupShowNextEpBuilder from '../builders/CatchupShowNextEpBuilder';

const NextEpisodeFactory = ({
    contentDetails, contentCollection, channel, episodesDetails, contentId
}) => {
    const { programType } = contentDetails;
    switch (programType) {
        case PROGRAM_TYPES.TVSHOW:
            return TVShowNextEpBuilder({
                contentDetails,
                contentCollection,
                episodesDetails,
                contentId,
            });

        case PROGRAM_TYPES.SEASON:
            return TVSeasonNextEpBuilder({
                contentDetails,
                contentCollection,
                episodesDetails,
                contentId,
            });

        case PROGRAM_TYPES.EPISODE:
            return TVShowNextEpBuilder({
                contentDetails,
                contentCollection,
                episodesDetails,
                contentId,
            });

        case PROGRAM_TYPES.LIVETVSHOW:
            return CatchupShowNextEpBuilder({
                contentDetails,
                episodesDetails,
                channel,
            });

        default: return null;
    }
};

export default NextEpisodeFactory;
